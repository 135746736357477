import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';
import { Helper } from 'app/common/helper';
import { DialogChangeDateLineComponent } from 'app/dialog/dialog-change-date-line/dialog-change-date-line.component';
import { DialogChangeLabelComponent } from 'app/dialog/dialog-change-label/dialog-change-label.component';
import { DialogChangeTemplateComponent } from 'app/dialog/dialog-change-template/dialog-change-template.component';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogCustomSortComponent } from 'app/dialog/dialog-custom-sort/dialog-custom-sort.component';
import { DialogDeliveryTimetableComponent } from 'app/dialog/dialog-delivery-timetable/dialog-delivery-timetable.component';
import { DialogEditHeaderComponent } from 'app/dialog/dialog-edit-header/dialog-edit-header.component';
import { DialogExportCalendarComponent } from 'app/dialog/dialog-export-calendar/dialog-export-calendar.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogPageSwitchingTimingComponent } from 'app/dialog/dialog-page-switching-timing/dialog-page-switching-timing.component';
import { DialogPlaylistRecurrenceComponent } from 'app/dialog/dialog-playlist-recurrence/dialog-playlist-recurrence.component';
import { DialogReferenceSettingComponent } from 'app/dialog/dialog-reference-setting/dialog-reference-setting.component';
import { DialogRouteLabelManagerComponent } from 'app/dialog/dialog-route-label-manager/dialog-route-label-manager.component';
import { DialogSettingSignageDisplayComponent } from 'app/dialog/dialog-setting-signage-display/dialog-setting-signage-display.component';
import { DialogTimetableSwitchTimingAreaComponent } from 'app/dialog/dialog-timetable-switch-timing-area/dialog-timetable-switch-timing-area.component';
import { DialogTimetableUpdateTimingComponent } from 'app/dialog/dialog-timetable-update-timing/dialog-timetable-update-timming.component';
import { Area } from 'app/model/entity/area';
import { Common } from 'app/model/entity/common';
import { CommonTable } from 'app/model/entity/commonTable';
import { ContentDay } from 'app/model/entity/content-day';
import { DataExternalSetting } from 'app/model/entity/data-external-setting';
import { DeviceCalendar } from 'app/model/entity/device-calendar';
import { DisplayTemplate } from 'app/model/entity/display-template';
import { FreeAreaMediaFile } from 'app/model/entity/free-area-media-file';
import { Image as ImageTimetable } from 'app/model/entity/image';
import { IndexWord } from 'app/model/entity/index-word';
import { ItemDetail } from 'app/model/entity/item-detail';
import { Media } from 'app/model/entity/media';
import { PictureArea } from 'app/model/entity/picture-area';
import { GroupDevice } from 'app/model/entity/simple/group-device';
import { SettingSignageChannel } from 'app/model/entity/simple/setting-signage-channel';
import { IHash, OptionFilter, SortFilterObject } from 'app/model/entity/sort-filter-object';
import { Template } from 'app/model/entity/template';
import { TextArea } from 'app/model/entity/text-area';
import { Timetable } from 'app/model/entity/timetable';
import { TimetableDetail } from 'app/model/entity/timetable-detail';
import { TimetableDetailURLArea } from 'app/model/entity/timetable-detail-url-area';
import { TimetableSchedule } from 'app/model/entity/timetable-schedule';
import { URLArea } from 'app/model/entity/url-area';
import { SaveSortFilterStateAction, SaveTimetableEditorStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DeviceService } from 'app/service/device.service';
import { DialogService } from 'app/service/dialog.service';
import { DrawTimetableService } from 'app/service/draw-timetable.service';
import { DrawService } from 'app/service/draw.service';
import { ExecutingService } from 'app/service/executing.service';
import { IndexWordService } from 'app/service/index-word.service';
import { MediaService } from 'app/service/media.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { PictureAreaService } from 'app/service/picture-area-service';
import { SettingSignageChannelService } from 'app/service/setting-signage-channel.service';
import { SimpleMediaService } from 'app/service/simple/simple-media.service';
import { SortFilterService } from 'app/service/sort-filter.service';
import { TemplateService } from 'app/service/template.service';
import { TimetableContentDayService } from 'app/service/timetable-content-day.service';
import { TimetableDetailURLService } from 'app/service/timetable-detail-url.service';
import { TimetableDetailService } from 'app/service/timetable-detail.service';
import { TimetableScheduleService } from 'app/service/timetable-schedule.service';
import { TimetableService } from 'app/service/timetable.service';
import { AppState } from 'app/store/app.state';
import * as fileSaver from 'file-saver';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription, forkJoin } from 'rxjs';
import {
  Constant,
  DestinationEnum,
  DisplayCanvasIdEnum,
  DisplaysEnum,
  ErrorEnum,
  FIELD_COMPONENT,
  FolderNameDropPDFEnum,
  LinkDataPictureEnum,
  LinkDataTextEnum,
  MODULE_NAME,
  PreviewToolEnum,
  ReferencePositionTimetableColumnEnum,
  RepeatModeEnum,
  ScreenCanvasIdEnum,
  ScreenFunctionId,
  ScreenNameEnum,
  SettingType,
  SortTypeEnum,
  TemplateTypeEnum,
  TemplateTypeFreeAreaEnum,
  TypeMediaFileEnum
} from '../../config/constants';

@Component({
  selector: 'timetable-editor',
  templateUrl: './timetable-editor.component.html',
  styleUrls: ['./timetable-editor.component.scss']
})
export class TimetableEditorComponent implements OnInit {
  /**
   * PATH_ANGLE_DOUBLE_RIGHT
   */
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  /**
   * constant
   */
  Constant = Constant;
  /**
   * Template type
   */
  TEMPLATE_TYPE = [
    this.translateService.instant('timetable-editor.buttons.main'),
    this.translateService.instant('timetable-editor.buttons.sub1'),
    this.translateService.instant('timetable-editor.buttons.sub2'),
    this.translateService.instant('timetable-editor.buttons.sub3'),
    this.translateService.instant('timetable-editor.buttons.sub4'),
    this.translateService.instant('timetable-editor.buttons.sub5'),
    this.translateService.instant('timetable-editor.buttons.sub6'),
    this.translateService.instant('timetable-editor.buttons.sub7'),
    this.translateService.instant('timetable-editor.buttons.sub8'),
    this.translateService.instant('timetable-editor.buttons.sub9'),
    this.translateService.instant('timetable-editor.buttons.emergency')
  ];

  TemplateTypeEnum = TemplateTypeEnum;
  /**
   * constants
   */
  readonly IS_EDITING_TIMETABLE = 'isEditingTimetable';
  readonly IS_CHOSEN_TAB_CALENDAR = 'isChosenTabCalendar';
  readonly IS_SHOW_FREE_AREA = 'isShowFreeArea';
  readonly IS_SHOW_URL_AREA = 'isShowURLArea';
  readonly IS_CLEAR_FIELD = 'isClearField';
  readonly DIV_DISPLAY_1 = 'timetable-li-display-1';
  readonly DIV_DISPLAY_2 = 'timetable-li-display-2';
  public readonly canvasDisplay1Id = `${ScreenCanvasIdEnum.TIMETABLE_EDITOR}${DisplayCanvasIdEnum.DISPLAY_1}`;
  public readonly canvasDisplay2Id = `${ScreenCanvasIdEnum.TIMETABLE_EDITOR}${DisplayCanvasIdEnum.DISPLAY_2}`;
  readonly IS_PREVIEW_ON_TT = 'isPreviewTimetable';
  readonly IS_DISABLE_BUTTON_SORT = 'isDisabledButtonSort';
  readonly TIMETABLE_INDEX = 0;
  readonly INDEX_WORD_INDEX = 1;
  readonly INDEX_TIME_ITEM = 0;
  readonly AREA_DISPLAY_1 = 'areaDisplay1';
  readonly AREA_DISPLAY_2 = 'areaDisplay2';
  readonly LAST_FILTER = 'lastFilter';
  readonly IS_FILTER = 'isFilter';
  readonly SORT_TYPE_INDEX = 1;
  readonly MAX_NUMBER_COLORS = 5;
  /**
   * start index schedule
   */
  readonly START_INDEX_SCHEDULE = 0;
  /**
   * colors original
   */
  colorsOriginal = ['#FFB8B8', '#FFEB8D', '#D5FF96', '#B8E9FF', '#D5BAFF'];
  /**
   * colors unused
   */
  unUsedColors: string[];
  /**
   * name edit
   */
  nameEdit: string;
  /**
   * no edit
   */
  noEdit: string;
  /**
   * suffix edit
   */
  suffixEdit: string;
  /**
   * true if choose display 2
   */
  isDisplay2: boolean;
  /**
   * DisplaysEnum
   */
  DisplaysEnum = DisplaysEnum;
  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;
  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;
  /**
   * timetable selected
   */
  timetableSelected: Timetable;
  /**
   * list timetables
   */
  timetables: Array<Timetable> = new Array<Timetable>();

  templateOld: Template;
  /**
   * ElementRef
   */
  @ViewChild('no') noElementRef: ElementRef;
  @ViewChild('suffix') suffixElementRef: ElementRef;
  @ViewChild('name') nameElementRef: ElementRef;
  @ViewChild('areaSelected') areaSelected: ElementRef;
  /**
   * external content of timetable
   */
  externalContentsOfTimetable: any;
  /**
   * save data success
   */
  @Output() saveDataSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * array subscription
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * true if choose tab schedule
   */
  isSchedule: boolean;
  /**
   * true if enlarge preview
   */
  isEnlargePreview: boolean;
  // TAB CALENDAR
  /**
   * content days month
   */
  contentDaysMonth: Array<ContentDay>;
  /**
   * list device groups
   */
  groupDevices: Array<GroupDevice>;
  /**
   * month selected
   */
  selectedMonth: any;
  /**
   * year selected
   */
  selectedYear: number;
  /**
   * true if > finish month
   */
  isNextMonth: boolean;
  /**
   * true if < start month
   */
  isPreviousMonth: boolean = true;
  /**
   * selected device
   */
  selectedDeviceCalendar: DeviceCalendar;
  /**
   * selected day
   */
  selectedDay: ContentDay;
  /**
   * list month
   */
  listMonth: Array<{ value: string; key: number }> = Array(
    { value: this.translateService.instant('timetable-editor.month-1'), key: 0 },
    { value: this.translateService.instant('timetable-editor.month-2'), key: 1 },
    { value: this.translateService.instant('timetable-editor.month-3'), key: 2 },
    { value: this.translateService.instant('timetable-editor.month-4'), key: 3 },
    { value: this.translateService.instant('timetable-editor.month-5'), key: 4 },
    { value: this.translateService.instant('timetable-editor.month-6'), key: 5 },
    { value: this.translateService.instant('timetable-editor.month-7'), key: 6 },
    { value: this.translateService.instant('timetable-editor.month-8'), key: 7 },
    { value: this.translateService.instant('timetable-editor.month-9'), key: 8 },
    { value: this.translateService.instant('timetable-editor.month-10'), key: 9 },
    { value: this.translateService.instant('timetable-editor.month-11'), key: 10 },
    { value: this.translateService.instant('timetable-editor.month-12'), key: 11 }
  );

  /**
   * header column original
   */
  headerColumnsOriginal: any = [
    { headerName: this.translateService.instant('timetable-editor.label'), property: 'nameLabel', isSortBy: '', isFilterBy: '' },
    { headerName: this.translateService.instant('timetable-editor.no'), property: 'no', isSortBy: '', isFilterBy: '' },
    { headerName: this.translateService.instant('timetable-editor.suffix'), property: 'suffix', isSortBy: '', isFilterBy: '' },
    { headerName: this.translateService.instant('timetable-editor.name'), property: 'name', isSortBy: '', isFilterBy: '' },
    {
      headerName: this.translateService.instant('timetable-editor.template'),
      property: 'nameTemplateMain1',
      isSortBy: '',
      isFilterBy: ''
    },
    {
      headerName: this.translateService.instant('timetable-editor.template'),
      property: 'nameTemplateMain2',
      isSortBy: '',
      isFilterBy: ''
    }
  ];

  /**
   * header column show on display
   */
  headerColumns: any = [];
  /**
   * lang key
   */
  langKey: string;
  /**
   * ReferencePositionTimetableColumnEnum
   */
  ReferencePositionTimetableColumnEnum = ReferencePositionTimetableColumnEnum;
  /**
   * areas of display 1
   */
  areasTimetableDisplay1: TextArea[];
  /**
   * areas of display 2
   */
  areasTimetableDisplay2: TextArea[];
  /**
   * areas index word of display 1
   */
  areasIndexWordDisplay1: Area[];
  /**
   * areas index word of display 2
   */
  areasIndexWordDisplay2: Area[];

  /**
   * areas of display 1
   */
  areasTimetableDisplayDrew1: TextArea[];
  /**
   * areas of display 2
   */
  areasTimetableDisplayDrew2: TextArea[];
  /**
   * areas index word of display 1
   */
  areasIndexWordDisplayDrew1: Area[];
  /**
   * areas index word of display 2
   */
  areasIndexWordDisplayDrew2: Area[];

  /**
   * reference position columns
   */
  referencePositionColumnsByTemplate: number[];
  /**
   * current index timetable schedule
   */
  currentIndexTimetableSchedule: number;
  /**
   * true if changed data
   */
  isChangedData: boolean;
  /**
   * list external content of style
   */
  externalContentOfStyle: Array<any>;
  /**
   * subscribe for get data external content to preview
   */
  subscribesGetDataExternal: Subscription[] = [];
  /**
   * abort controller request
   */
  abortControllerDisplay: AbortController = new AbortController();
  /**
   * media setting
   */
  mediaSetting: Media;
  /**
   * true if preview on
   */
  isPlay: boolean;
  /**
   * array contain timeout for display 1
   */
  timeoutsDisplay1: any[] = [];
  /**
   * array contain timeout for display 2
   */
  timeoutsDisplay2: any[] = [];
  /**
   * buttons preview display 1
   */
  buttonsPreviewDisplay1: Array<{ key: DestinationEnum; value: string }>;
  /**
   * buttons preview display 2
   */
  buttonsPreviewDisplay2: Array<{ key: DestinationEnum; value: string }>;
  /**
   * type of template display 1 on screen
   */
  templateSelectedTypeDisplay1: DestinationEnum = DestinationEnum.MAIN;
  /**
   * type of template display 2 on screen
   */
  templateSelectedTypeDisplay2: DestinationEnum = DestinationEnum.MAIN;
  /**
   * headers display
   */
  headersDisplay: Array<string>;
  /**
   * true if active zoom
   */
  public isZoom: boolean;
  /**
   * true if active pan
   */
  public isPan: boolean;
  /**
   * true if free area edit is show
   */
  public isShowFreeArea: boolean;

  /**
   * true if url area edit is show
   */
  public isShowURLArea: boolean;
  /**
   * list area display 1 (free picture/free text)
   */
  areasDisplay1: Array<Area>;
  /**
   * list area display 2 (free picture/free text)
   */
  areasDisplay2: Array<Area>;

  /**
   * list area display 1 (urlArea)
   */
  urlAreasDisplay1: Array<Area>;
  /**
   * list area display 2 (urlArea)
   */
  urlAreasDisplay2: Array<Area>;
  /**
   * panzoomDisplay1
   */
  private panzoomDisplay1: PanzoomObject;
  /**
   * panzoomDisplay2
   */
  private panzoomDisplay2: PanzoomObject;
  /**
   * PreviewToolEnum
   */
  public readonly PreviewToolEnum = PreviewToolEnum;
  /**
   * Timetable detail selected
   */
  public timetableDetailSelected: TimetableDetail;

  /**
   * Timetable url detail selected
   */
  public timetableDetailURLSelected: TimetableDetailURLArea;

  /**
   * Free area timetable media files
   */
  public freeAreaTimetableMediaFiles: Array<FreeAreaMediaFile> = new Array();
  /**
   * Files Data
   */
  public filesData = [];
  /**
   * True if template only has free text area
   */
  public isOnlyFreeTextArea: boolean;
  /**
   * True if template only has free picture area
   */
  public isOnlyFreePictureArea: boolean;
  /**
   * true if disabled button add
   */
  public isDisabledButtonAdd: boolean;

  /**
   * true if disabled button add
   */
  public isDisabledButtonAddUrl: boolean;
  /**
   * true if disabled button sort and filter
   */
  public isDisabledButtonSort: boolean;
  /**
   * Helper
   */
  public Helper = Helper;
  /**
   * Timetable details clone
   */
  private timetableDetailsClone: Array<TimetableDetail>;

  /**
   * Timetable url details clone
   */
  private timetableUrlDetailsClone: Array<TimetableDetailURLArea>;
  /**
   * Is unlimited
   */
  private isUnlimited: boolean;
  /**
   * Is unlimited data from dialog data recurrence
   */
  private isUnlimitedDataFromDialogDataRecurrence: boolean;

  //#region view child element
  @ViewChild('divContainCanvas1', { static: false })
  divContainCanvas1: ElementRef;

  @ViewChild('divContainCanvas2', { static: false })
  divContainCanvas2: ElementRef;

  @ViewChild('divPreview', { static: false })
  divPreview: ElementRef;

  @ViewChild('divPreviewDisplay1', { static: false })
  divPreviewDisplay1: ElementRef;

  @ViewChild('divPreviewDisplay2', { static: false })
  divPreviewDisplay2: ElementRef;

  /**
   * color being used
   */
  colorBeingUsed: Map<Number, string> = new Map<Number, string>();

  /**
   * sort filter variable
   */
  isSortFilter: boolean;
  isCheckAllOptionFilter: boolean;
  timetablesDisplay: Array<Timetable> = new Array<Timetable>();
  columnSortFiltering: string;
  isShowPopUpSortFilter: boolean;
  lastColumnFilter: string;
  listFilterDisplay: Array<OptionFilter>;
  listFilterDisplayOrigin: Array<OptionFilter>;
  isFilter: boolean;
  isClear: boolean;
  listCurrentFilter: IHash = {};
  listSorted: any = [];

  /**
   * area switching timing
   */
  private areaSwitchingTiming: number = 0;

  /**
   * stateOfComponent
   */
  stateOfComponent: {
    isChangeLayout: boolean;
    unUsedColors: string[];
    nameEdit: string;
    noEdit: string;
    suffixEdit: string;
    isDisplay2: boolean;
    timetableSelected: Timetable;
    timetables: Array<Timetable>;
    externalContentOfTimetable: any;
    isSchedule: boolean;
    contentDaysMonth: Array<ContentDay>;
    groupDevices: Array<GroupDevice>;
    selectedMonth: any;
    selectedYear: number;
    isNextMonth: boolean;
    isPreviousMonth: boolean;
    selectedDeviceCalendar: DeviceCalendar;
    listMonth: Array<any>;
    currentIndexTimetableSchedule: number;
    isChangedData: boolean;
    externalContentOfStyle: Array<any>;
    mediaSetting: Media;
    isPlay: boolean;
    buttonsPreviewDisplay1: Array<any>;
    buttonsPreviewDisplay2: Array<any>;
    isZoom: boolean;
    isPan: boolean;
    isEnlargePreview: boolean;
    headersDisplay: Array<string>;
    colorBeingUsed: any;
    freeAreaTimetableMediaFiles: Array<FreeAreaMediaFile>;
    filesData: [];
    isOnlyFreeTextArea: boolean;
    isOnlyFreePictureArea: boolean;
    isDisabledButtonAdd: boolean;
    isShowFreeArea: boolean;
    isShowURLArea: boolean;
    timetableDetailsClone: Array<TimetableDetail>;
    timetablesDisplay: any;
    lastColumnFilter: string;
    listFilterDisplay: Array<OptionFilter>;
    listFilterDisplayOrigin: Array<OptionFilter>;
    timetablesFilterDisplay: any;
    isDisabledButtonSort: boolean;
    referencePositionColumnsByTemplate: Array<number>;
    areasDisplay1: Array<Area>;
    areasDisplay2: Array<Area>;
    isUnlimited: boolean;
    isUnlimitedDataFromDialogDataRecurrence: boolean;
    areaSwitchingTiming: number;
  };
  /**
   * common object
   */
  commonObject: Common;
  /**
   * Sort filter object
   */
  private sortFilterObject: SortFilterObject;
  /**
   * time date line
   */
  timeDateLine: string;

  /**
   * timetable detail URL coppy
   */
  timetableDetailURLAreaClone: Array<TimetableDetailURLArea>;

  /**
   * area display2 active
   */
  areaDisplay2Active: Array<Number>;

  constructor(
    private menuActionService: MenuActionService,
    private timetableService: TimetableService,
    private timetableDetailService: TimetableDetailService,
    private timetableDetailURLService: TimetableDetailURLService,
    private dialogService: DialogService,
    private dataService: DataService,
    private templateService: TemplateService,
    private drawTimetableService: DrawTimetableService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private drawService: DrawService,
    private pictureAreaService: PictureAreaService,
    private settingSignageChannelService: SettingSignageChannelService,
    private simpleMediaService: SimpleMediaService,
    private mediaService: MediaService,
    private timetableScheduleService: TimetableScheduleService,
    private deviceService: DeviceService,
    private timetableContentDayService: TimetableContentDayService,
    private toast: ToastrService,
    private translateService: TranslateService,
    private indexWordService: IndexWordService,
    public readonly store: Store<AppState>,
    private commonService: CommonService,
    private sortFilterService: SortFilterService,
    private commonTableService: CommonTableService,
    private executingService: ExecutingService
  ) {
    // save data
    this.subscriptions.push(
      this.menuActionService.actionSave.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          if (this.isChangedData) {
            this.saveDataTabScheduleAfterLeave();
          }
        }
      })
    );

    // import data
    this.subscriptions.push(
      this.menuActionService.actionImportTimetable.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.importTimeTable();
        }
      })
    );

    // export data timetable
    this.subscriptions.push(
      this.menuActionService.actionExportTimetable.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.exportTimetable();
        }
      })
    );

    // export data calendar
    this.subscriptions.push(
      this.menuActionService.actionExportCalendar.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.exportCalendar();
        }
      })
    );

    // add new time table
    this.subscriptions.push(
      this.menuActionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.addNewTimeTable();
        }
      })
    );
    // edit new time table
    this.subscriptions.push(
      this.menuActionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.editTimeTable();
        }
      })
    );
    // delete time table
    this.subscriptions.push(
      this.menuActionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.deleteTimeTable();
        }
      })
    );
    // duplicate time table
    this.subscriptions.push(
      this.menuActionService.actionDuplicate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.duplicateTimeTable();
        }
      })
    );
    // manager label
    this.subscriptions.push(
      this.menuActionService.actionManageLabel.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.showDialogManagerLabel();
        }
      })
    );

    // subscribe for change label action
    this.subscriptions.push(
      this.menuActionService.actionChangeLabel.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.changeLabelTimeTable();
        }
      })
    );

    // subscribe for change template
    this.subscriptions.push(
      this.menuActionService.actionChangeTemplate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.changeTemplate();
        }
      })
    );

    // subscribe for set free area
    this.subscriptions.push(
      this.menuActionService.actionSetFreeArea.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.setFreeArea();
        }
      })
    );

    // subscribe for set url
    this.subscriptions.push(
      this.menuActionService.actionSetURL.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.setURL();
        }
      })
    );

    // subscribe for clear field data
    this.subscriptions.push(
      this.menuActionService.actionClearField.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.clearTimeTableDetail(this.timetableDetailSelected);
        }
      })
    );

    // subscribe for change label action
    this.subscriptions.push(
      this.menuActionService.actionChangeDisplay.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.changeDisplay();
        }
      })
    );

    // setting channel area preview
    this.subscriptions.push(
      this.menuActionService.actionChannelAreaPreview.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.signageChannelAreaPreview();
        }
      })
    );

    // setting update timing
    this.subscriptions.push(
      this.menuActionService.actionUpdateTime.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.updateTiming();
        }
      })
    );

    // change date line
    this.subscriptions.push(
      this.menuActionService.actionChangeDateLine.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.changeDateLine();
        }
      })
    );

    // change PageSwitchingTiming
    this.subscriptions.push(
      this.menuActionService.actionPageSwitchingTiming.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.changePageSwitchingTiming();
        }
      })
    );

    // switch timing area
    this.subscriptions.push(
      this.menuActionService.actionSwitchTimingArea.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.switchTimingArea();
        }
      })
    );

    // delivery
    this.subscriptions.push(
      this.menuActionService.actionDelivery.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.delivery();
        }
      })
    );

    // edit item
    this.subscriptions.push(
      this.menuActionService.actionEditItemName.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.editItemName();
        }
      })
    );

    // sort filter timetables
    this.subscriptions.push(
      this.menuActionService.actionSortAndFilter.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.sortFilter();
        }
      })
    );

    // reference setting
    this.subscriptions.push(
      this.menuActionService.actionReferenceSetting.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
          this.referenceSetting();
        }
      })
    );

    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.TIMETABLE_DEVICE_COMPLETED_IDS) {
        if (data[1].includes(this.selectedDeviceCalendar.registrationId)) {
          this.selectedDeviceCalendar.calendarDays.forEach(contentDay => {
            contentDay.isDelivered = true;
            if (contentDay.timetableId === -1) {
              contentDay.timetableId = undefined;
              contentDay.timetable = undefined;
            }
            if (contentDay.unlimitedInfo) {
              contentDay.unlimitedInfo['isDelivered'] = true;
            }
          });
        }
      }
    });

    // on-click go to
    this.subscriptions.push(
      this.drawTimetableService.toSwitchBetweenPage.subscribe((destinationDisplay: { key: string; value: DestinationEnum }) => {
        if (!this.isPlay) {
          return;
        }
        if (destinationDisplay.key == this.canvasDisplay1Id && this.timetableSelected?.templateDisplay1s) {
          if (this.timetableSelected.templateDisplay1s[this.templateSelectedTypeDisplay1]?.isAutoTransition) {
            this.clearTimeoutDisplay(this.timeoutsDisplay1);
          }
          this.handleEventClickAreaOnCanvas(
            this.divContainCanvas1,
            this.canvasDisplay1Id,
            this.timetableSelected.templateDisplay1s,
            destinationDisplay.value
          );
        } else if (destinationDisplay.key == this.canvasDisplay2Id && this.timetableSelected?.templateDisplay2s) {
          if (this.timetableSelected.templateDisplay2s[this.templateSelectedTypeDisplay2]?.isAutoTransition) {
            this.clearTimeoutDisplay(this.timeoutsDisplay2);
          }
          this.handleEventClickAreaOnCanvas(
            this.divContainCanvas2,
            this.canvasDisplay2Id,
            this.timetableSelected.templateDisplay2s,
            destinationDisplay.value
          );
        }
      })
    );

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
        // buttons preview display 1
        if (this.buttonsPreviewDisplay1) {
          this.buttonsPreviewDisplay1 = this.getButtonsPreview(this.timetableSelected?.displayTemplate1);
        }
        // buttons preview display 2
        if (this.buttonsPreviewDisplay2) {
          this.buttonsPreviewDisplay2 = this.getButtonsPreview(this.timetableSelected?.displayTemplate2);
        }
        // multi language header
        if (this.referencePositionColumnsByTemplate?.length && this.timetableSelected.timetableSchedule) {
          Helper.updateLanguageHeaders(this.timetableSelected.timetableSchedule.headers, this.translateService);
        }
        // multi language list month
        this.listMonth = Array(
          { value: this.translateService.instant('timetable-editor.month-1'), key: 0 },
          { value: this.translateService.instant('timetable-editor.month-2'), key: 1 },
          { value: this.translateService.instant('timetable-editor.month-3'), key: 2 },
          { value: this.translateService.instant('timetable-editor.month-4'), key: 3 },
          { value: this.translateService.instant('timetable-editor.month-5'), key: 4 },
          { value: this.translateService.instant('timetable-editor.month-6'), key: 5 },
          { value: this.translateService.instant('timetable-editor.month-7'), key: 6 },
          { value: this.translateService.instant('timetable-editor.month-8'), key: 7 },
          { value: this.translateService.instant('timetable-editor.month-9'), key: 8 },
          { value: this.translateService.instant('timetable-editor.month-10'), key: 9 },
          { value: this.translateService.instant('timetable-editor.month-11'), key: 10 },
          { value: this.translateService.instant('timetable-editor.month-12'), key: 11 }
        );

        // multiple language column header
        this.multiLanguageHeader();

        // multi language template type
        this.TEMPLATE_TYPE = [
          this.translateService.instant('timetable-editor.buttons.main'),
          this.translateService.instant('timetable-editor.buttons.sub1'),
          this.translateService.instant('timetable-editor.buttons.sub2'),
          this.translateService.instant('timetable-editor.buttons.sub3'),
          this.translateService.instant('timetable-editor.buttons.sub4'),
          this.translateService.instant('timetable-editor.buttons.sub5'),
          this.translateService.instant('timetable-editor.buttons.sub6'),
          this.translateService.instant('timetable-editor.buttons.sub7'),
          this.translateService.instant('timetable-editor.buttons.sub8'),
          this.translateService.instant('timetable-editor.buttons.sub9'),
          this.translateService.instant('timetable-editor.buttons.emergency')
        ];

        this.multiLanguageTooltip();
      })
    );
    // store state
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState?.timetableEditorState?.stateOfComponent.isChangeLayout,
            unUsedColors: componentState?.timetableEditorState?.stateOfComponent.unUsedColors,
            nameEdit: componentState?.timetableEditorState?.stateOfComponent.nameEdit,
            noEdit: componentState?.timetableEditorState?.stateOfComponent.noEdit,
            suffixEdit: componentState?.timetableEditorState?.stateOfComponent.suffixEdit,
            isDisplay2: componentState?.timetableEditorState?.stateOfComponent.isDisplay2,
            timetableSelected: componentState?.timetableEditorState?.stateOfComponent.timetableSelected,
            timetables: componentState?.timetableEditorState?.stateOfComponent.timetables,
            externalContentOfTimetable: componentState?.timetableEditorState?.stateOfComponent.externalContentOfTimetable,
            isSchedule: componentState?.timetableEditorState?.stateOfComponent.isSchedule,
            contentDaysMonth: componentState?.timetableEditorState?.stateOfComponent.contentDaysMonth,
            groupDevices: componentState?.timetableEditorState?.stateOfComponent.groupDevices,
            selectedMonth: componentState?.timetableEditorState?.stateOfComponent.selectedMonth,
            selectedYear: componentState?.timetableEditorState?.stateOfComponent.selectedYear,
            isNextMonth: componentState?.timetableEditorState?.stateOfComponent.isNextMonth,
            isPreviousMonth: componentState?.timetableEditorState?.stateOfComponent.isPreviousMonth,
            selectedDeviceCalendar: componentState?.timetableEditorState?.stateOfComponent.selectedDeviceCalendar,
            listMonth: componentState?.timetableEditorState?.stateOfComponent.listMonth,
            currentIndexTimetableSchedule: componentState?.timetableEditorState?.stateOfComponent.currentIndexTimetableSchedule,
            isChangedData: componentState?.timetableEditorState?.stateOfComponent.isChangedData,
            externalContentOfStyle: componentState?.timetableEditorState?.stateOfComponent.externalContentOfStyle,
            isPlay: componentState?.timetableEditorState?.stateOfComponent.isPlay,
            mediaSetting: componentState?.timetableEditorState?.stateOfComponent.mediaSetting,
            buttonsPreviewDisplay1: componentState?.timetableEditorState?.stateOfComponent.buttonsPreviewDisplay1,
            buttonsPreviewDisplay2: componentState?.timetableEditorState?.stateOfComponent.buttonsPreviewDisplay2,
            isZoom: componentState?.timetableEditorState?.stateOfComponent.isZoom,
            isPan: componentState?.timetableEditorState?.stateOfComponent.isPan,
            isEnlargePreview: componentState?.timetableEditorState?.stateOfComponent.isEnlargePreview,
            headersDisplay: componentState?.timetableEditorState?.stateOfComponent.headersDisplay,
            colorBeingUsed: componentState?.timetableEditorState?.stateOfComponent.colorBeingUsed,
            freeAreaTimetableMediaFiles: componentState?.timetableEditorState?.stateOfComponent.freeAreaTimetableMediaFiles,
            filesData: componentState?.timetableEditorState?.stateOfComponent.filesData,
            isOnlyFreeTextArea: componentState?.timetableEditorState?.stateOfComponent.isOnlyFreeTextArea,
            isOnlyFreePictureArea: componentState?.timetableEditorState?.stateOfComponent.isOnlyFreePictureArea,
            isDisabledButtonAdd: componentState?.timetableEditorState?.stateOfComponent.isDisabledButtonAdd,
            isShowFreeArea: componentState?.timetableEditorState?.stateOfComponent.isShowFreeArea,
            isShowURLArea: componentState?.timetableEditorState?.stateOfComponent.isShowURLArea,
            timetableDetailsClone: componentState?.timetableEditorState?.stateOfComponent.timetableDetailsClone,
            timetablesDisplay: componentState?.timetableEditorState?.stateOfComponent.timetablesDisplay,
            lastColumnFilter: componentState?.timetableEditorState?.stateOfComponent.lastColumnFilter,
            listFilterDisplay: componentState?.timetableEditorState?.stateOfComponent.listFilterDisplay,
            listFilterDisplayOrigin: componentState?.timetableEditorState?.stateOfComponent.listFilterDisplayOrigin,
            timetablesFilterDisplay: componentState?.timetableEditorState?.stateOfComponent.timetablesFilterDisplay,
            isDisabledButtonSort: componentState?.timetableEditorState?.stateOfComponent.isDisabledButtonSort,
            referencePositionColumnsByTemplate: componentState?.timetableEditorState?.stateOfComponent.referencePositionColumnsByTemplate,
            areasDisplay1: componentState?.timetableEditorState?.stateOfComponent.areasDisplay1,
            areasDisplay2: componentState?.timetableEditorState?.stateOfComponent.areasDisplay2,
            isUnlimited: componentState?.timetableEditorState?.stateOfComponent.isUnlimited,
            isUnlimitedDataFromDialogDataRecurrence:
              componentState?.timetableEditorState?.stateOfComponent.isUnlimitedDataFromDialogDataRecurrence,
            areaSwitchingTiming: componentState?.timetableEditorState?.stateOfComponent.areaSwitchingTiming
          };
        })
    );

    this.commonObject = commonService.getCommonObject();
    this.sortFilterObject = sortFilterService.getSortFilterObject();
  }

  async ngOnInit() {
    this.isDisplay2 = this.commonObject?.isDisplay2Timetable;
    this.dataService.sendData([Constant.IS_NOT_USER_ROOT, this.commonObject.userIdString != Constant.ROOT]);
    // get sort filter conditions
    this.getAllSortFilterConditions();
    // get information change date line
    this.getInformationChangeDateLine();
    this.sendTimezoneToBack();
    if (!this.stateOfComponent?.isChangeLayout) {
      // get information switch timing area
      this.getInformationSwitchTimingArea();
      this.isSchedule = this.commonObject?.isSchedule === undefined ? true : this.commonObject.isSchedule;
      this.dataService.sendData([this.IS_CHOSEN_TAB_CALENDAR, !this.isSchedule]);
      this.executingService.executing();
      await Helper.loadFontsToPreview(this.store, this.commonObject, this.translateService, this.dialogService);
      this.executingService.executed();
      // get all time tables
      this.getAllTimetables();
      // get all device calendar
      this.getAllDeviceCalendars();
      // get setting signage channel
      this.settingSignageChannelService.getSettingSignageChannelByType(SettingType.TIMETABLE).subscribe(
        settingSignageChannel => {
          if (!settingSignageChannel || (!settingSignageChannel.folderId && !settingSignageChannel.mediaId)) {
            return;
          }
          // get media by id
          this.simpleMediaService.getMediaById(settingSignageChannel.mediaId).subscribe(
            async data => {
              if (!data) {
                this.mediaSetting = undefined;
                return;
              }
              this.mediaSetting = Helper.convertSimpleMediaToMedia(Helper.convertDataSimpleMedia(data, false));
            },
            error => Helper.handleError(error, this.translateService, this.dialogService)
          );
        },
        error => Helper.handleError(error, this.translateService, this.dialogService)
      );
    } else {
      this.handleAfterChangeLayout();
      this.sendTimezoneToBack();
    }
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_CHANGE_TIME_ZONE) {
        if (data[1]) {
          this.sendTimezoneToBack();
        }
      }
    });
  }

  /**
   * on destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.clearAllBeforeLeave();
    this.mediaService.deleteFolderMediaFromPC(FolderNameDropPDFEnum.FREE_AREA).toPromise();
    this.store.dispatch(
      new SaveTimetableEditorStateAction({
        isChangeLayout: true,
        unUsedColors: this.unUsedColors,
        nameEdit: this.nameEdit,
        noEdit: this.noEdit,
        suffixEdit: this.suffixEdit,
        isDisplay2: this.isDisplay2,
        timetableSelected: this.timetableSelected,
        timetables: this.timetables,
        externalContentOfTimetable: this.externalContentsOfTimetable,
        isSchedule: this.isSchedule,
        contentDaysMonth: this.contentDaysMonth,
        groupDevices: this.groupDevices,
        selectedMonth: this.selectedMonth,
        selectedYear: this.selectedYear,
        isNextMonth: this.isNextMonth,
        isPreviousMonth: this.isPreviousMonth,
        selectedDeviceCalendar: this.selectedDeviceCalendar,
        listMonth: this.listMonth,
        currentIndexTimetableSchedule: this.currentIndexTimetableSchedule,
        isChangedData: this.isChangedData,
        externalContentOfStyle: this.externalContentOfStyle,
        mediaSetting: this.mediaSetting,
        isPlay: this.isPlay,
        buttonsPreviewDisplay1: this.buttonsPreviewDisplay1,
        buttonsPreviewDisplay2: this.buttonsPreviewDisplay2,
        isZoom: this.isZoom,
        isPan: this.isPan,
        isEnlargePreview: this.isEnlargePreview,
        headersDisplay: this.headersDisplay,
        colorBeingUsed: this.colorBeingUsed,
        freeAreaTimetableMediaFiles: this.freeAreaTimetableMediaFiles,
        filesData: this.filesData,
        isOnlyFreeTextArea: this.isOnlyFreeTextArea,
        isOnlyFreePictureArea: this.isOnlyFreePictureArea,
        isDisabledButtonAdd: this.isDisabledButtonAdd,
        isShowFreeArea: this.isShowFreeArea,
        isShowURLArea: this.isShowURLArea,
        timetableDetailsClone: this.timetableDetailsClone,
        timetablesDisplay: this.timetablesDisplay,
        lastColumnFilter: this.lastColumnFilter,
        listFilterDisplay: this.listFilterDisplay,
        listFilterDisplayOrigin: this.listFilterDisplayOrigin,
        isDisabledButtonSort: this.isDisabledButtonSort,
        referencePositionColumnsByTemplate: this.referencePositionColumnsByTemplate,
        areasDisplay1: this.areasDisplay1,
        areasDisplay2: this.areasDisplay2,
        isUnlimited: this.isUnlimited,
        isUnlimitedDataFromDialogDataRecurrence: this.isUnlimitedDataFromDialogDataRecurrence,
        areaSwitchingTiming: this.areaSwitchingTiming
      })
    );
    delete this.commonObject.isSchedule;
  }

  /**
   * clear all
   */
  private clearAllBeforeLeave(): void {
    this.isPlay = false;
    // clear timeout
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.clearTimeoutDisplay(this.timeoutsDisplay2);
    this.drawTimetableService.changeStatePlayPause(this.isPlay, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.drawTimetableService.changeStatePlayPause(this.isPlay, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    // clear all thread
    this.drawTimetableService.clearAllIntervalDrawsNewsDisplay1();
    this.drawTimetableService.clearAllIntervalDrawsNewsDisplay2();
    this.subscribesGetDataExternal.forEach(subscription => subscription?.unsubscribe());
    // clear all thread draw template
    if (this.timetableSelected?.templateDisplay1s) {
      this.drawTimetableService.clearAllThreadDrawTemplate(
        _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
        this.canvasDisplay1Id,
        ScreenCanvasIdEnum.TIMETABLE_EDITOR
      );
    }
    if (this.timetableSelected?.templateDisplay2s) {
      this.drawTimetableService.clearAllThreadDrawTemplate(
        _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
        this.canvasDisplay2Id,
        ScreenCanvasIdEnum.TIMETABLE_EDITOR
      );
    }
    this.drawTimetableService.clearTimeoutsStopDurationArea(true, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
  }

  /**
   * handle after change layout
   */
  public handleAfterChangeLayout(): void {
    this.unUsedColors = this.stateOfComponent?.unUsedColors;
    this.nameEdit = this.stateOfComponent?.nameEdit;
    this.noEdit = this.stateOfComponent?.noEdit;
    this.suffixEdit = this.stateOfComponent?.suffixEdit;
    this.isDisplay2 = this.stateOfComponent?.isDisplay2;
    this.timetableSelected = this.stateOfComponent?.timetableSelected;
    this.timetables = this.stateOfComponent?.timetables;
    this.externalContentsOfTimetable = this.stateOfComponent?.externalContentOfTimetable;
    this.isSchedule = this.stateOfComponent?.isSchedule;
    this.contentDaysMonth = this.stateOfComponent?.contentDaysMonth;
    this.groupDevices = this.stateOfComponent?.groupDevices;
    this.selectedMonth = this.stateOfComponent?.selectedMonth;
    this.selectedYear = this.stateOfComponent?.selectedYear;
    this.isNextMonth = this.stateOfComponent?.isNextMonth;
    this.isPreviousMonth = this.stateOfComponent?.isPreviousMonth;
    this.selectedDeviceCalendar = this.stateOfComponent?.selectedDeviceCalendar;
    this.listMonth = this.stateOfComponent?.listMonth;
    this.currentIndexTimetableSchedule = this.stateOfComponent?.currentIndexTimetableSchedule;
    this.isChangedData = this.stateOfComponent?.isChangedData;
    this.externalContentOfStyle = this.stateOfComponent?.externalContentOfStyle;
    this.mediaSetting = this.stateOfComponent?.mediaSetting;
    this.isPlay = this.stateOfComponent?.isPlay;
    this.buttonsPreviewDisplay1 = this.stateOfComponent?.buttonsPreviewDisplay1;
    this.buttonsPreviewDisplay2 = this.stateOfComponent?.buttonsPreviewDisplay2;
    this.isZoom = this.stateOfComponent?.isZoom;
    this.isPan = this.stateOfComponent?.isPan;
    this.isEnlargePreview = this.stateOfComponent?.isEnlargePreview;
    this.headersDisplay = this.stateOfComponent?.headersDisplay;
    this.colorBeingUsed = this.stateOfComponent?.colorBeingUsed;
    this.freeAreaTimetableMediaFiles = this.stateOfComponent?.freeAreaTimetableMediaFiles;
    this.filesData = this.stateOfComponent?.filesData;
    this.isOnlyFreeTextArea = this.stateOfComponent?.isOnlyFreeTextArea;
    this.isOnlyFreePictureArea = this.stateOfComponent?.isOnlyFreePictureArea;
    this.isDisabledButtonAdd = this.stateOfComponent?.isDisabledButtonAdd;
    this.isShowFreeArea = this.stateOfComponent?.isShowFreeArea;
    this.isShowURLArea = this.stateOfComponent?.isShowURLArea;
    this.timetableDetailsClone = this.stateOfComponent?.timetableDetailsClone;
    this.timetablesDisplay = this.stateOfComponent?.timetablesDisplay;
    this.lastColumnFilter = this.stateOfComponent?.lastColumnFilter;
    this.listFilterDisplay = this.stateOfComponent?.listFilterDisplay;
    this.listFilterDisplayOrigin = this.stateOfComponent?.listFilterDisplayOrigin;
    this.isDisabledButtonSort = this.stateOfComponent?.isDisabledButtonSort;
    this.referencePositionColumnsByTemplate = this.stateOfComponent?.referencePositionColumnsByTemplate;
    this.areasDisplay1 = this.stateOfComponent?.areasDisplay1;
    this.areasDisplay2 = this.stateOfComponent?.areasDisplay2;
    this.isUnlimited = this.stateOfComponent?.isUnlimited;
    this.isUnlimitedDataFromDialogDataRecurrence = this.stateOfComponent?.isUnlimitedDataFromDialogDataRecurrence;
    this.areaSwitchingTiming = this.stateOfComponent?.areaSwitchingTiming;

    this.dataService.sendData([this.IS_PREVIEW_ON_TT, this.isPlay]);
    this.dataService.sendData([this.IS_EDITING_TIMETABLE, this.timetableSelected?.isEdit]);
    this.dataService.sendData([this.IS_CHOSEN_TAB_CALENDAR, !this.isSchedule]);
    this.dataService.sendData([this.IS_SHOW_FREE_AREA, this.isShowFreeArea]);
    this.dataService.sendData([this.IS_SHOW_URL_AREA, this.isShowURLArea]);
    this.dataService.sendData([this.IS_DISABLE_BUTTON_SORT, !(this.timetables.length > 0)]);
    this.dataService.sendData([this.IS_CLEAR_FIELD, false]);
    this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
    this.commonObject.isSchedule = this.isSchedule;
    Helper.saveMainStateAction(this.store, this.commonObject);
    if (this.isSchedule) {
      this.changeDetectorRef.detectChanges();
      // preview
      this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
      this.previewTemplate(this.isPlay);
    }
  }

  /**
   * get all timetables
   *
   */
  private getAllTimetables(): void {
    this.timetableService.getAllTimetables().subscribe(
      data => {
        if (!data?.length) {
          this.isDisabledButtonSort = true;
          this.dataService.sendData([this.IS_DISABLE_BUTTON_SORT, this.isDisabledButtonSort]);
          return;
        }
        this.timetables = Helper.convertDataTimetables(data);
        this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
        this.timetablesDisplay = [...this.timetables];
        if (!this.isSortFilter) {
          this.selectTimetable(this.timetables[Constant.FIRST_ELEMENT_INDEX], null);
          return;
        }
        // case filter
        if (!_.isEmpty(this.listCurrentFilter)) {
          this.filterTimetableFirstTime();
        }
        // case sort
        if (this.listSorted[Constant.SORT_COLUMN_INDEX]) {
          this.timetablesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
        }
        this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * Get all sort filter conditions
   */
  private getAllSortFilterConditions(): void {
    // get data from store
    this.isFilter = this.sortFilterObject?.isFilter;
    this.isSortFilter = this.sortFilterObject?.isSortFilter;
    this.isCheckAllOptionFilter = this.sortFilterObject?.isCheckAllOptionFilter;
    this.columnSortFiltering = this.sortFilterObject?.columnSortFiltering;
    this.listCurrentFilter = this.sortFilterObject?.listCurrentFilter;
    this.isClear = this.sortFilterObject?.isClear;
    this.listSorted = this.sortFilterObject?.listSorted;
    if (this.isSortFilter && (!_.isEmpty(this.listCurrentFilter) || this.listSorted[Constant.SORT_COLUMN_INDEX])) {
      this.headerColumns = this.sortFilterObject?.headerColumns;
    } else {
      this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);
    }
    this.multiLanguageHeader();
  }

  /**
   * choose tab
   */
  public chooseTab(): void {
    this.isSchedule = !this.isSchedule;
    this.commonObject.isSchedule = this.isSchedule;
    Helper.saveMainStateAction(this.store, this.commonObject);
    this.dataService.sendData([this.IS_CHOSEN_TAB_CALENDAR, !this.isSchedule]);
    if (!this.isSchedule) {
      // clear all thread draw template
      if (this.timetableSelected?.templateDisplay1s) {
        this.drawTimetableService.clearAllThreadDrawTemplate(
          _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
          this.canvasDisplay1Id,
          ScreenCanvasIdEnum.TIMETABLE_EDITOR
        );
      }
      if (this.timetableSelected?.templateDisplay2s) {
        this.drawTimetableService.clearAllThreadDrawTemplate(
          _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
          this.canvasDisplay2Id,
          ScreenCanvasIdEnum.TIMETABLE_EDITOR
        );
      }
      this.isPan = false;
      this.isZoom = false;
    } else {
      this.changeDetectorRef.detectChanges();
      this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
      this.previewTemplate();
    }
  }

  /**
   * enlarge preview
   */
  public enlargePreview(): void {
    this.isEnlargePreview = !this.isEnlargePreview;
    this.calculateScaleTransformCanvas(
      _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
      this.divContainCanvas1,
      this.divPreviewDisplay1,
      false
    );
    if (this.isDisplay2) {
      this.calculateScaleTransformCanvas(
        _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
        this.divContainCanvas2,
        this.divPreviewDisplay2,
        true
      );
    }
  }

  /**
   * select timetable schedule
   * @param index
   */
  public selectTimetableSchedule(index: number, isReset?: boolean): void {
    let itemDetails = this.timetableSelected?.timetableSchedule?.itemDetails;
    if (!itemDetails?.length || index < 0 || !this.isPlay || index > itemDetails?.length || this.isShowFreeArea || this.isShowFreeArea) {
      return;
    }
    let display1 = _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined);
    const areasDisplay1 = Helper.getAllAreaTemplate(display1);
    let display2 = _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined);
    const areasDisplay2 = Helper.getAllAreaTemplate(display2);

    // setup AbortController
    this.abortControllerDisplay.abort();
    this.abortControllerDisplay = new AbortController();

    // finish timetable
    if (index == itemDetails.length) {
      this.drawTimetableService.handleUnSubscriptionForLayer(display1, this.canvasDisplay1Id);
      this.currentIndexTimetableSchedule = itemDetails.length + 1;
      // clear before drawing
      this.clearBeforeDrawFinishSchedule();
      // draw area is timing on
      this.drawTimetableService.drawPreviewFixAreaTimingOn(
        areasDisplay1?.filter(area => area.isFix && area.isTimingOn),
        this.renderer,
        this.canvasDisplay1Id,
        ScreenCanvasIdEnum.TIMETABLE_EDITOR,
        true,
        display1
      );
      if (this.isDisplay2) {
        this.drawTimetableService.handleUnSubscriptionForLayer(display2, this.canvasDisplay2Id);
        this.drawTimetableService.drawPreviewFixAreaTimingOn(
          areasDisplay2?.filter(area => area.isFix && area.isTimingOn),
          this.renderer,
          this.canvasDisplay2Id,
          ScreenCanvasIdEnum.TIMETABLE_EDITOR,
          true,
          display2
        );
      }
      return;
    }
    this.drawTimetableService.clearTimeoutsStopDurationArea(false, ScreenCanvasIdEnum.TIMETABLE_EDITOR, [
      LinkDataTextEnum.INDEX_WORD,
      LinkDataTextEnum.TIMETABLE
    ]);
    if (index === this.START_INDEX_SCHEDULE) {
      this.currentIndexTimetableSchedule = this.START_INDEX_SCHEDULE;
      // pause if reset when playing
      if (isReset && this.isPlay) {
        this.reDrawPreview(areasDisplay1, areasDisplay2);
        return;
      }
    } else {
      if (this.currentIndexTimetableSchedule === itemDetails.length + 1) {
        // clear Fix Area Timing On
        this.clearFixAreaTimingOn(areasDisplay1, areasDisplay2);
      }
      this.currentIndexTimetableSchedule = index;
    }

    // draw time table
    this.drawTimetableService.setDataTimetables(this.currentIndexTimetableSchedule, this.referencePositionColumnsByTemplate);
    this.drawTimetableService.setDataPreviewTimetableEditor(this.timetableSelected.timetableSchedule);
    this.drawTimetableService.clearAreas(this.areasTimetableDisplay1, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.drawTimetableService.clearAreas(this.areasIndexWordDisplay1, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.drawTimetableService.clearAreas(this.areasTimetableDisplayDrew1, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.drawTimetableService.clearAreas(this.areasIndexWordDisplayDrew1, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    const areaTimetableDraw1 = _.cloneDeep(this.areasTimetableDisplay1);
    this.drawAreasTimetableForDisplay(display1, index, areaTimetableDraw1, this.canvasDisplay1Id);
    this.areasTimetableDisplayDrew1 = areaTimetableDraw1;
    this.drawTimetableService.clearMediaIndexWords();
    // draw index word
    if (this.areasIndexWordDisplay1?.length) {
      // get index word from schedule
      const areasIndexWord = _.cloneDeep(this.areasIndexWordDisplay1);
      this.indexWordService.getIndexWordsByNameAndGroupId(this.getParamForGetIndexWord(areasIndexWord)).subscribe(indexWords => {
        this.drawTimetableService.setDataPreviewTimetableEditor(null);
        // draw index word
        this.drawAreasIndexWordForDisplay(display1, areasIndexWord, this.canvasDisplay1Id, indexWords);
        this.areasIndexWordDisplayDrew1 = areasIndexWord;
      });
    }
    if (this.isDisplay2) {
      // draw time table
      this.drawTimetableService.clearAreas(this.areasTimetableDisplay2, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
      this.drawTimetableService.clearAreas(this.areasIndexWordDisplay2, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
      this.drawTimetableService.clearAreas(this.areasTimetableDisplayDrew2, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
      this.drawTimetableService.clearAreas(this.areasIndexWordDisplayDrew2, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
      const areaTimetableDraw2 = _.cloneDeep(this.areasTimetableDisplay2);
      this.drawAreasTimetableForDisplay(display2, index, areaTimetableDraw2, this.canvasDisplay2Id);
      this.areasTimetableDisplayDrew2 = areaTimetableDraw2;
      // draw index word
      if (this.areasIndexWordDisplay2?.length) {
        // get index word from schedule
        const areasIndexWord = _.cloneDeep(this.areasIndexWordDisplay2);
        this.indexWordService.getIndexWordsByNameAndGroupId(this.getParamForGetIndexWord(areasIndexWord)).subscribe(indexWords => {
          this.drawTimetableService.setDataPreviewTimetableEditor(null);
          // draw index word
          this.drawAreasIndexWordForDisplay(display2, areasIndexWord, this.canvasDisplay2Id, indexWords);
          this.areasIndexWordDisplayDrew2 = areasIndexWord;
        });
      }
    }
  }

  /**
   * draw areas timetable for display
   * @param display
   * @param index
   * @param areasTimetableDisplay
   * @param canvasDisplayId
   */
  private drawAreasTimetableForDisplay(display: Template, index: number, areasTimetableDisplay: TextArea[], canvasDisplayId: string): void {
    let areasTimetableLayerOff = Helper.getAreasOfLayerOnOff(areasTimetableDisplay, display, this.areaSwitchingTiming);
    this.drawTimetableService.drawAreasTimetable(
      areasTimetableLayerOff as TextArea[],
      this.renderer,
      canvasDisplayId,
      this.timetableSelected.timetableSchedule,
      this.currentIndexTimetableSchedule,
      ScreenCanvasIdEnum.TIMETABLE_EDITOR,
      this.referencePositionColumnsByTemplate
    );
    let areasTimetableLayerOn = Helper.getAreasOfLayerOnOff(areasTimetableDisplay, display, this.areaSwitchingTiming, true);
    this.drawTimetableService.drawAreasTimetableLayerOn(
      areasTimetableLayerOn as TextArea[],
      this.renderer,
      canvasDisplayId,
      this.timetableSelected.timetableSchedule,
      this.currentIndexTimetableSchedule,
      ScreenCanvasIdEnum.TIMETABLE_EDITOR,
      this.referencePositionColumnsByTemplate
    );
  }

  /**
   * draw areas index word for display
   * @param display
   * @param areasIndexWordDisplay
   * @param canvasDisplayId
   * @param indexWords
   */
  private drawAreasIndexWordForDisplay(
    display: Template,
    areasIndexWordDisplay: Area[],
    canvasDisplayId: string,
    indexWords: IndexWord[]
  ): void {
    Helper.setDataIndexWordForAreas(areasIndexWordDisplay, indexWords);
    this.drawTimetableService.setDataPreviewTimetableEditor(null, null, null, areasIndexWordDisplay);
    let areasIndexWordLayerOff = Helper.getAreasOfLayerOnOff(areasIndexWordDisplay, display, this.areaSwitchingTiming);

    this.drawTimetableService.drawAreasIndexWord(
      areasIndexWordLayerOff,
      canvasDisplayId,
      this.timetableSelected.timetableSchedule,
      ScreenCanvasIdEnum.TIMETABLE_EDITOR,
      display
    );
    let areasIndexWordLayerOn = Helper.getAreasOfLayerOnOff(areasIndexWordDisplay, display, this.areaSwitchingTiming, true);
    this.drawTimetableService.drawAreasIndexWordLayerOn(
      areasIndexWordLayerOn,
      canvasDisplayId,
      this.timetableSelected.timetableSchedule,
      ScreenCanvasIdEnum.TIMETABLE_EDITOR,
      display
    );
  }

  /**
   * Re draw preview
   * @param areasDisplay1
   * @param areasDisplay2
   */
  private reDrawPreview(areasDisplay1: Area[], areasDisplay2: Area[]): void {
    this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.isPlay = false;
    this.dataService.sendData([this.IS_PREVIEW_ON_TT, this.isPlay]);
    this.changeDetectorRef.detectChanges();
    // clear time out
    this.drawTimetableService.clearTimeoutsStopDurationArea(true, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.clearTimeoutDisplay(this.timeoutsDisplay2);
    this.clearAllDrawThread();
    // clear Fix Area Timing On
    this.clearFixAreaTimingOn(areasDisplay1, areasDisplay2);
    this.drawDisplay(
      this.canvasDisplay1Id,
      this.divContainCanvas1,
      _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
      false
    );
    if (this.isDisplay2) {
      this.drawDisplay(
        this.canvasDisplay2Id,
        this.divContainCanvas2,
        _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
        true
      );
    }
    this.drawTimetableService.changeStatePlayPause(this.isPlay, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.drawTimetableService.changeStatePlayPause(this.isPlay, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
  }

  /**
   * Clear all draw thread
   */
  private clearAllDrawThread(): void {
    if (this.timetableSelected?.templateDisplay1s) {
      this.drawTimetableService.clearAllThreadDrawTemplate(
        _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
        this.canvasDisplay1Id,
        ScreenCanvasIdEnum.TIMETABLE_EDITOR
      );
    }
    if (this.timetableSelected?.templateDisplay2s) {
      this.drawTimetableService.clearAllThreadDrawTemplate(
        _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
        this.canvasDisplay2Id,
        ScreenCanvasIdEnum.TIMETABLE_EDITOR
      );
    }
  }

  /**
   * clear Fix Area Timing On
   * @param areasDisplay1
   * @param areasDisplay2
   */
  private clearFixAreaTimingOn(areasDisplay1: Array<Area>, areasDisplay2: Array<Area>): void {
    this.drawTimetableService.clearFixAreaTimingOn(
      areasDisplay1?.filter(area => area.isFix && area.isTimingOn),
      this.canvasDisplay1Id
    );
    if (this.isDisplay2) {
      this.drawTimetableService.clearFixAreaTimingOn(
        areasDisplay2?.filter(area => area.isFix && area.isTimingOn),
        this.canvasDisplay2Id
      );
    }
  }

  /**
   * clear before draw finish schedule
   */
  private clearBeforeDrawFinishSchedule(): void {
    this.drawTimetableService.clearAreas(this.areasTimetableDisplay1, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.drawTimetableService.clearAreas(this.areasIndexWordDisplay1, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.drawTimetableService.clearAreas(this.areasTimetableDisplayDrew1, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.drawTimetableService.clearAreas(this.areasIndexWordDisplayDrew1, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    if (this.isDisplay2) {
      this.drawTimetableService.clearAreas(this.areasTimetableDisplay2, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
      this.drawTimetableService.clearAreas(this.areasIndexWordDisplay2, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
      this.drawTimetableService.clearAreas(this.areasTimetableDisplayDrew2, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
      this.drawTimetableService.clearAreas(this.areasIndexWordDisplayDrew2, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    }
  }

  /**
   * select timetable
   *
   * @param timetable
   * @param event
   * @param isNotGetDataDetails
   * @param isChangeTemplate
   * @param isNotReload
   */
  public selectTimetable(
    timetable: Timetable,
    event,
    isNotGetDataDetails?: boolean,
    isChangeTemplate?: boolean,
    isNotReload?: boolean
  ): void {
    if (this.timetableSelected === timetable && !isNotReload) {
      return;
    }
    this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
    // confirm when select another timetable
    if (this.isChangedData && this.isShowFreeArea) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('timetable-editor.save-changes-timetable'),
            button1: this.translateService.instant('timetable-editor.yes'),
            button2: this.translateService.instant('timetable-editor.btn-no')
          }
        },
        result => {
          if (result) {
            this.saveTimetableDetail(false);
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.isShowFreeArea = true;
                this.isChangedData = false;
                this.selectTimetable(timetable, null);
              }
            });
          } else {
            this.isChangedData = false;
            this.selectTimetable(timetable, null);
          }
        }
      );
    } else {
      if (this.timetableSelected?.isEdit) {
        return;
      }

      // check click button checkbox
      if (
        event?.target?.id === 'checkBoxTimetable' ||
        event?.target?.id === 'showDialogDisplay1' ||
        event?.target?.id === 'showDialogDisplay2' ||
        (timetable?.id == this.timetableSelected?.id && !isChangeTemplate)
      ) {
        return;
      }
      this.filesData = [];
      this.freeAreaTimetableMediaFiles = new Array();
      this.timetableDetailSelected = undefined;
      this.timetableDetailURLSelected = undefined;
      this.dataService.sendData([this.IS_CLEAR_FIELD, false]);
      // clear drawing
      this.clearThreadDrawing();

      this.timetableSelected = timetable;
      if (this.isPan) {
        this.chooseTool(PreviewToolEnum.PAN);
      }
      if (this.isZoom) {
        this.chooseTool(PreviewToolEnum.ZOOM);
      }
      this.currentIndexTimetableSchedule = this.START_INDEX_SCHEDULE;

      // get data details
      if (isNotGetDataDetails) {
        return;
      }
      this.preview();
    }
  }

  /**
   * add new time table
   */
  private addNewTimeTable(): void {
    if ((this.timetableSelected && this.timetableSelected.isEdit) || this.isShowFreeArea) {
      return;
    }

    // clear all thread draw template
    this.clearThreadDrawing();

    this.nameEdit = Constant.EMPTY;
    this.noEdit = Constant.EMPTY;
    this.suffixEdit = Constant.EMPTY;
    let timetableNew = new Timetable(null, this.nameEdit, this.noEdit, this.suffixEdit);
    timetableNew.isEdit = true;
    this.timetables.push(timetableNew);
    this.timetablesDisplay.push(timetableNew);
    this.timetableSelected = timetableNew;
    this.dataService.sendData([this.IS_EDITING_TIMETABLE, this.timetableSelected.isEdit]);
    this.isChangedData = true;
  }

  /**
   * edit time table
   */
  private editTimeTable(): void {
    if (!this.timetableSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    } else if (this.timetableSelected.isEdit || this.isShowFreeArea) {
      return;
    }
    this.noEdit = this.timetableSelected.no;
    this.suffixEdit = this.timetableSelected.suffix;
    this.nameEdit = this.timetableSelected.name;
    this.timetableSelected.isEdit = true;
    let indexTimetable = this.timetables.findIndex(timetable => timetable.id == this.timetableSelected.id);
    if (indexTimetable != -1) {
      this.timetables[indexTimetable].isEdit = true;
    }
    let index = this.timetablesDisplay.findIndex(timetableDisplay => timetableDisplay.id == this.timetableSelected.id);
    if (index != -1) {
      this.timetablesDisplay[index].isEdit = true;
    }
    this.dataService.sendData([this.IS_EDITING_TIMETABLE, this.timetableSelected.isEdit]);
    this.isChangedData = true;
  }

  /**
   * validate timetable when add new/ edit
   * @param no
   * @param suffix
   * @param name
   */
  private validateTimetable(no: string, suffix: string, name: string): boolean {
    // validate timetable no
    if (no.trim().length < Constant.MIN_NO_LENGTH) {
      this.noElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.no-empty')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (no.length > Constant.MAX_NO_LENGTH) {
      this.noElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.no-length')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (!no.match(Constant.FORMAT_NO_REGEX)) {
      this.noElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.no-format')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }

    // validate suffix
    if (suffix.trim().length != 0 && suffix.length > Constant.MAX_SUFFIX_LENGTH) {
      this.suffixElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.suffix-length')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (suffix.trim().length != 0 && !suffix.match(Constant.FORMAT_SUFFIX_REGEX)) {
      this.suffixElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.suffix-format')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }

    // validate timetable name
    if (name.trim().length < Constant.MIN_NAME_LENGTH) {
      this.nameElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.name-empty')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (name.length > Constant.MAX_NAME_LENGTH) {
      this.nameElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.name-length')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (name.match(Constant.FORMAT_TIMETABLE_NAME) || name.trim().includes('\\')) {
      this.nameElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.name-special-character')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    return true;
  }

  /**
   * save timetable
   */
  public saveTimetable(): void {
    let oldTimetable = Object.assign({}, this.timetableSelected);
    let no = this.noEdit;
    let suffix = this.suffixEdit;
    let name = this.nameEdit;

    // validate timetable No, Name, suffix
    if (!this.validateTimetable(no, suffix, name)) {
      return;
    }

    // enter suffix full empty character
    if (!suffix.trim().length) {
      suffix = suffix.trim();
      this.suffixEdit = suffix;
    }
    // validate duplicate no + suffix
    this.timetableService.checkExistTimetable(no, suffix, this.timetableSelected.id ?? null).subscribe(
      data => {
        if (data) {
          this.noElementRef.nativeElement.focus();
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('timetable-editor.duplicate-timetable')
            },
            disableClose: true
          });
          this.saveDataSuccess.emit(false);
          return;
        }
        // validate duplicate name
        this.timetableService.checkExistTimetableName(name, this.timetableSelected.id ?? null).subscribe(
          data => {
            if (data) {
              this.nameElementRef.nativeElement.focus();
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('dialog-error.title'),
                  text: this.translateService.instant('timetable-editor.duplicate-name')
                },
                disableClose: true
              });
              this.saveDataSuccess.emit(false);
              return;
            }
            // assign value
            this.timetableSelected.name = name;
            this.timetableSelected.no = no;
            this.timetableSelected.suffix = suffix;
            // edit timetable
            if (this.timetableSelected.id) {
              this.timetableService.saveTimetable(Helper.convertDataTimetableBackward(this.timetableSelected)).subscribe(
                timetableData => {
                  this.handleAfterSaveTimetable();
                  if (!timetableData) {
                    return;
                  }
                  let timetableEdited = Helper.convertDataTimetable(timetableData);
                  timetableEdited.isChecked = this.timetableSelected.isChecked;
                  timetableEdited.isEdit = this.timetableSelected.isEdit;
                  let index = this.timetables.findIndex(timetable => timetable.id === timetableEdited.id);
                  if (index == -1) {
                    return;
                  }
                  this.timetables[index] = timetableEdited;
                  let indexTimetableEditOnDisplay = this.timetablesDisplay.findIndex(timetable => timetable.id === timetableEdited.id);
                  if (indexTimetableEditOnDisplay > -1) {
                    this.timetablesDisplay[indexTimetableEditOnDisplay] = this.timetables[index];
                  }
                  this.selectTimetable(this.timetables[index], null, false, true);
                  if (oldTimetable.name != timetableEdited.name) {
                    this.selectedDeviceCalendar?.calendarDays
                      .filter(contentDay => contentDay.timetable?.name == oldTimetable.name)
                      .map(contentDayData => {
                        contentDayData.timetable.name = timetableEdited.name;
                      });
                  }
                },
                error => {
                  this.handleErrorSaveTimetable(error);
                  this.saveDataSuccess.emit(false);
                }
              );
              // timetable's id null
            } else {
              if (!this.timetableSelected?.timetableSchedule) {
                // Add timetable
                this.timetableService.saveTimetable(Helper.convertDataTimetableBackward(this.timetableSelected)).subscribe(
                  timetableData => {
                    this.handleAfterSaveTimetable();
                    if (!timetableData) {
                      return;
                    }
                    let timetableAdd = Helper.convertDataTimetable(timetableData);
                    timetableAdd.isEdit = this.timetableSelected.isEdit;
                    this.timetables[this.timetables.length - 1] = timetableAdd;
                    this.timetablesDisplay[this.timetablesDisplay.length - 1] = timetableAdd;
                    this.selectTimetable(this.timetables[this.timetables.length - 1], null, true);
                  },
                  error => {
                    this.handleErrorSaveTimetable(error);
                    this.saveDataSuccess.emit(false);
                  }
                );
              } else {
                // Duplicate timetable
                const duplicatedTimetable = this.timetableSelected;
                this.timetableService
                  .duplicateTimetable(Helper.convertDataTimetableBackward(duplicatedTimetable), duplicatedTimetable['oldId'])
                  .subscribe(
                    timetableData => {
                      this.handleAfterSaveTimetable();
                      if (!timetableData) {
                        return;
                      }
                      let timetableDuplicate = Helper.convertDataTimetable(timetableData);
                      timetableDuplicate.isEdit = this.timetableSelected.isEdit;
                      this.timetables[this.timetables.length - 1] = timetableDuplicate;
                      this.timetablesDisplay[this.timetablesDisplay.length - 1] = timetableDuplicate;
                      this.selectTimetable(this.timetables[this.timetables.length - 1], null);
                    },
                    error => {
                      this.handleErrorSaveTimetable(error);
                      this.saveDataSuccess.emit(false);
                    }
                  );
              }
            }
          },
          error => Helper.handleError(error, this.translateService, this.dialogService)
        );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * handleAfterSaveTimetable
   */
  private handleAfterSaveTimetable(): void {
    this.timetableSelected.isEdit = false;
    this.dataService.sendData([this.IS_EDITING_TIMETABLE, this.timetableSelected.isEdit]);
    this.isDisabledButtonSort = false;
    this.dataService.sendData([this.IS_DISABLE_BUTTON_SORT, this.isDisabledButtonSort]);
    this.isChangedData = false;
    this.saveDataSuccess.emit(true);
  }

  /**
   * check or uncheck a timetable
   * @param id
   * @param e
   */
  public changeChecked(id: number, e): void {
    e.stopPropagation();
    let index = this.timetables.findIndex(timetable => timetable.id === id);
    this.timetables[index].isChecked = !this.timetables[index].isChecked;
    let indexDisplay = this.timetablesDisplay.findIndex(e => e.id === this.timetables[index].id);
    this.timetablesDisplay[indexDisplay].isChecked = this.timetables[index].isChecked;
    if (this.timetables[index].id == this.timetableSelected?.id) {
      this.timetableSelected.isChecked = this.timetables[index].isChecked;
    }
  }

  /**
   * cancel save timetable
   */
  public cancelSaveTimetable(): void {
    this.isChangedData = false;
    this.timetableSelected.isEdit = false;
    let indexTimetable = this.timetables.findIndex(timetable => timetable.id == this.timetableSelected.id);
    if (indexTimetable != -1) {
      this.timetables[indexTimetable].isEdit = false;
    }
    let index = this.timetablesDisplay.findIndex(timetableDisplay => timetableDisplay.id == this.timetableSelected.id);
    if (index != -1) {
      this.timetablesDisplay[index].isEdit = false;
    }
    this.dataService.sendData([this.IS_EDITING_TIMETABLE, this.timetableSelected.isEdit]);
    if (!this.timetableSelected.id) {
      this.timetables.pop();
      this.timetablesDisplay.pop();
      if (this.timetablesDisplay?.length) {
        this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
      } else {
        this.timetableSelected = undefined;
      }
    } else {
      this.selectTimetable(this.timetableSelected, null);
    }
  }

  /**
   * import timetable
   */
  public importTimeTable(): void {
    if (!this.isSchedule || this.isShowFreeArea) {
      return;
    }
    let element = document.getElementById('importedFileTimetable') as HTMLInputElement;
    element.setAttribute('accept', '.xlsx');
    element.click();
  }

  /**
   * upload excel
   * @param event
   */
  public uploadExcel(event): Promise<void> {
    let selectedFiles: File[] = event.target.files;
    const typeFiles = ['xlsx'];

    for (const file of selectedFiles) {
      let typeName = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      if (!typeFiles.includes(typeName)) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('timetable-editor.invalid-file')
          }
        });
        return;
      }
    }

    this.timetableService.readExcelsFromClient(selectedFiles, this.getRegexTime()).subscribe(
      data => {
        let timetablesData = this.getDataTimetablesInExcelFile(data);
        let isExistTimetableDuplicate = timetablesData.some(timetable =>
          this.timetables.some(({ no, suffix }) => timetable.no === no && timetable.suffix === suffix)
        );
        if (isExistTimetableDuplicate) {
          this.openPopupConfirm(timetablesData);
        } else {
          // validate duplicate name
          if (!this.validateDuplicateNameWhenImport(this.timetables.concat(timetablesData))) {
            this.handleErrorNameDuplicated();
            return;
          }
          this.saveTimetablesImport(this.convertListTimetableBackward(timetablesData));
        }
      },
      error => {
        this.handleShowErrorMessageFromServerWhenImport(error);
      }
    );
    // reset input file value
    let element = document.getElementById('importedFileTimetable') as HTMLInputElement;
    element.value = null;
  }

  /**
   * Handle show error message from server when import
   *
   * @param error
   */
  private handleShowErrorMessageFromServerWhenImport(error: any): void {
    switch (error.error?.detail) {
      case Constant.ERROR_MULTIPLE_TIME_FORMATS_VALUE_EXCEL:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('timetable-editor.multiple-time-formats-value')
          }
        });
        break;
      case Constant.ERROR_INVALID_DATA_TIMETABLE_EXCEL:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('timetable-editor.contains-files-with-invalid-data')
          }
        });
        break;
      case Constant.ERROR_NO_DATA_TIMETABLE_IN_EXCEL:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('timetable-editor.no-data-timetable')
          }
        });
        break;
      case Constant.ERROR_NO_TEMPLATE:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('timetable-editor.timetable-selected-no-template')
          }
        });
        break;
      case Constant.ERROR_NO_AREA_TIMETABLE_OF_TEMPLATE:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('timetable-editor.no-area-timetable-selected')
          }
        });
        break;
      case Constant.ERROR_HEADER_IS_EMPTY:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('timetable-editor.header-is-empty')
          }
        });
        break;
      default:
        Helper.handleError(error, this.translateService, this.dialogService);
        break;
    }
  }

  /**
   * get regex time
   */
  private getRegexTime(): RegexTime {
    const regexTimeMinute1 = Helper.formatTimeRegex(Constant.FORMAT_TIME_MINUTE1_REGEX, this.timeDateLine);
    const regexTimeMinute2 = Helper.formatTimeRegex(Constant.FORMAT_TIME_MINUTE2_REGEX, this.timeDateLine);
    const regexTimeSecond = Helper.formatTimeRegex(Constant.FORMAT_TIME_SECOND_REGEX, this.timeDateLine);
    return new RegexTime(regexTimeMinute1, regexTimeMinute2, regexTimeSecond);
  }

  /**
   * export time table
   */
  async exportTimetable(): Promise<void> {
    if (!this.isSchedule || this.isShowFreeArea) {
      return;
    }

    let timetablesChecked = this.timetables.filter(timetable => timetable.isChecked);
    if (!timetablesChecked?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    }
    // case timetables checked > NUMBER_OF_FILES_ALLOWED_TO_EXPORT
    if (timetablesChecked.length > Constant.NUMBER_OF_FILES_ALLOWED_TO_EXPORT) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: Helper.formatString(
            this.translateService.instant('timetable-editor.maximum-files'),
            `${Constant.NUMBER_OF_FILES_ALLOWED_TO_EXPORT}`
          )
        }
      });
      return;
    }

    if (timetablesChecked.some(data => data.name.match(Constant.FORMAT_TIMETABLE_NAME) || data.name.includes('\\'))) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.name-special-character')
        }
      });
      return;
    }

    timetablesChecked = timetablesChecked.map(timetableCheck => Helper.convertTimetableDataBackWardWhenExport(timetableCheck));
    for (const timetable of timetablesChecked) {
      // call api to write to file excel
      this.timetableService.writeExcel(timetable).subscribe(
        response => {
          this.uncheckAllTimetable();
          const fileNameResponse = decodeURIComponent(response.headers.get('content-disposition'));
          const file = new File([response.body], fileNameResponse, {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          fileSaver.saveAs(file);
          console.info('File downloaded successfully');
        },
        error => {
          console.log('Error downloading the file');
          this.handleErrorSaveTimetable(error);
        }
      );
    }
  }

  /**
   * Uncheck all timetable
   */
  private uncheckAllTimetable(): void {
    this.timetables.forEach(timetable => (timetable.isChecked = false));
    this.timetablesDisplay.forEach(timetableDisplay => (timetableDisplay.isChecked = false));
  }

  /**
   * show dialog label manager
   */
  public showDialogManagerLabel(): void {
    if (this.timetableSelected?.isEdit) {
      return;
    }
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogRouteLabelManagerComponent,
      {
        autoFocus: false,
        data: {
          title: this.translateService.instant('dialog-route-label-manager.label-setting'),
          screen: ScreenNameEnum.TIME_TABLE,
          functionId: ScreenFunctionId.TIME_TABLE
        }
      },
      result => {
        this.isChangedData = false;
        if (result) {
          // set label for timetables original
          this.setLabelForTimetable(this.timetables, result);
          // set label for timetable display
          this.setLabelForTimetable(this.timetablesDisplay, result);
        }
      }
    );
  }

  /**
   * Set label for timetable
   *
   * @param timetables
   * @param result
   */
  private setLabelForTimetable(timetables: Timetable[], result: any): void {
    timetables.forEach(timetable => {
      timetable.isChecked = false;
      let index = [...result].findIndex(data => data.id == timetable?.label?.id);
      if (index != -1) {
        timetable.label = result[index];
      }
    });
  }

  /**
   * show dialog change label
   */
  public changeLabelTimeTable(): void {
    let checkedTimetables = this.timetables.filter(timetable => timetable.isChecked);
    if (!checkedTimetables?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    }
    if (this.timetableSelected?.isEdit) {
      return;
    }
    let timetableCheckedFirst = checkedTimetables[0];
    let labelCommon =
      checkedTimetables.length == 1
        ? timetableCheckedFirst.label
        : checkedTimetables.every(timetable => timetable?.label?.id == timetableCheckedFirst?.label?.id)
        ? timetableCheckedFirst.label
        : null;
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogChangeLabelComponent,
      {
        data: {
          labelCommon: labelCommon,
          title: this.translateService.instant('dialog-change-label.select-label'),
          screen: ScreenNameEnum.TIME_TABLE,
          functionId: ScreenFunctionId.TIME_TABLE
        }
      },
      result => {
        this.isChangedData = false;
        if (result != '') {
          checkedTimetables.forEach(timetable => (timetable.isChecked = false));
          let timetablesSaved = checkedTimetables.map(timetableData => {
            timetableData.label = result;
            return Helper.convertDataTimetableBackward(timetableData);
          });
          this.timetableService.saveLabelForTimetables(timetablesSaved).subscribe(
            data => {
              let timetablesOutput = data.map(timetableData => Helper.convertDataTimetable(timetableData));
              this.timetables.forEach(timetableData => {
                let index = timetablesOutput.findIndex(timetable => timetable.id == timetableData.id);
                if (index != -1) {
                  timetableData.label = timetablesOutput[index].label;
                  timetableData['nameLabel'] = timetablesOutput[index].label?.name;
                }
              });
              this.timetablesDisplay.forEach(timetableData => {
                let index = timetablesOutput.findIndex(timetable => timetable.id == timetableData.id);
                if (index != -1) {
                  timetableData.label = timetablesOutput[index].label;
                  timetableData['nameLabel'] = timetablesOutput[index].label?.name;
                  timetableData.isChecked = false;
                }
              });
              this.selectTimetable(this.timetableSelected, null);
            },
            error => Helper.handleError(error, this.translateService, this.dialogService)
          );
        }
      }
    );
  }

  /**
   * change template
   * @param timeTable
   * @param displayType
   * @returns
   */
  public changeTemplate(timeTable?: Timetable, displayType?: DisplaysEnum) {
    let timeTablesSelected: Timetable[];
    if (timeTable) {
      timeTablesSelected = [timeTable];
    } else {
      timeTablesSelected = this.timetables.filter(timetable => timetable.isChecked);
    }
    if (!timeTablesSelected?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
    } else {
      const idMainPageDisplay1 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_1, DestinationEnum.MAIN);
      const idSubPage1Display1 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_1, DestinationEnum.SUB_PAGE_1);
      const idSubPage2Display1 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_1, DestinationEnum.SUB_PAGE_2);
      const idSubPage3Display1 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_1, DestinationEnum.SUB_PAGE_3);
      const idSubPage4Display1 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_1, DestinationEnum.SUB_PAGE_4);
      const idSubPage5Display1 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_1, DestinationEnum.SUB_PAGE_5);
      const idSubPage6Display1 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_1, DestinationEnum.SUB_PAGE_6);
      const idSubPage7Display1 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_1, DestinationEnum.SUB_PAGE_7);
      const idSubPage8Display1 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_1, DestinationEnum.SUB_PAGE_8);
      const idSubPage9Display1 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_1, DestinationEnum.SUB_PAGE_9);
      const idEmergencyDisplay1 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_1, DestinationEnum.EMERGENCY);

      const displayTemplate1 = idMainPageDisplay1
        ? new DisplayTemplate(
            idMainPageDisplay1,
            idSubPage1Display1,
            idSubPage2Display1,
            idSubPage3Display1,
            idSubPage4Display1,
            idSubPage5Display1,
            idSubPage6Display1,
            idSubPage7Display1,
            idSubPage8Display1,
            idSubPage9Display1,
            idEmergencyDisplay1
          )
        : new DisplayTemplate();

      const idMainPageDisplay2 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_2, DestinationEnum.MAIN);
      const idSubPage1Display2 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_2, DestinationEnum.SUB_PAGE_1);
      const idSubPage2Display2 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_2, DestinationEnum.SUB_PAGE_2);
      const idSubPage3Display2 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_2, DestinationEnum.SUB_PAGE_3);
      const idSubPage4Display2 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_2, DestinationEnum.SUB_PAGE_4);
      const idSubPage5Display2 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_2, DestinationEnum.SUB_PAGE_5);
      const idSubPage6Display2 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_2, DestinationEnum.SUB_PAGE_6);
      const idSubPage7Display2 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_2, DestinationEnum.SUB_PAGE_7);
      const idSubPage8Display2 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_2, DestinationEnum.SUB_PAGE_8);
      const idSubPage9Display2 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_2, DestinationEnum.SUB_PAGE_9);
      const idEmergencyDisplay2 = Helper.getIdTemplateByType(timeTablesSelected, Constant.DISPLAY_2, DestinationEnum.EMERGENCY);

      const displayTemplate2 = idMainPageDisplay2
        ? new DisplayTemplate(
            idMainPageDisplay2,
            idSubPage1Display2,
            idSubPage2Display2,
            idSubPage3Display2,
            idSubPage4Display2,
            idSubPage5Display2,
            idSubPage6Display2,
            idSubPage7Display2,
            idSubPage8Display2,
            idSubPage9Display2,
            idEmergencyDisplay2
          )
        : new DisplayTemplate();
      let idsTimetableChecked = timeTablesSelected.map(timetable => timetable.id);
      this.externalContentsOfTimetable = [];
      // get same external content
      this.templateService.getSameExternalContent(idsTimetableChecked, this.isDisplay2, true, displayType).subscribe(data => {
        if (data) {
          this.externalContentsOfTimetable = data;
        }
        this.isChangedData = true;
        if (!timeTable) {
          // show dialog
          this.dialogService.showDialog(
            DialogChangeTemplateComponent,
            {
              data: this.isDisplay2
                ? {
                    screen: ScreenNameEnum.TIME_TABLE,
                    displayTemplate1: displayTemplate1,
                    displayTemplate2: displayTemplate2,
                    isDisplay2: this.isDisplay2,
                    dataExternalContent: this.externalContentsOfTimetable
                  }
                : {
                    screen: ScreenNameEnum.TIME_TABLE,
                    displayTemplate1: displayTemplate1,
                    isDisplay2: this.isDisplay2,
                    dataExternalContent: this.externalContentsOfTimetable
                  }
            },
            result => {
              this.isChangedData = false;
              if (result) {
                // if one display
                if (!this.isDisplay2) {
                  timeTablesSelected.forEach(timetable => {
                    if (
                      timetable.displayTemplate1?.idMainPage != result?.idMainPage ||
                      timetable.displayTemplate1?.idSubPage1 != result?.idSubPage1 ||
                      timetable.displayTemplate1?.idSubPage2 != result?.idSubPage2 ||
                      timetable.displayTemplate1?.idSubPage3 != result?.idSubPage3 ||
                      timetable.displayTemplate1?.idSubPage4 != result?.idSubPage4 ||
                      timetable.displayTemplate1?.idSubPage5 != result?.idSubPage5 ||
                      timetable.displayTemplate1?.idSubPage6 != result?.idSubPage6 ||
                      timetable.displayTemplate1?.idSubPage7 != result?.idSubPage7 ||
                      timetable.displayTemplate1?.idSubPage8 != result?.idSubPage8 ||
                      timetable.displayTemplate1?.idSubPage9 != result?.idSubPage9 ||
                      timetable.displayTemplate1?.idEmergencyPage != result?.idEmergencyPage
                    ) {
                      timetable.timetableSchedule = null;
                    }
                    timetable.displayTemplate1 = result;
                  });
                  // if two display
                } else {
                  timeTablesSelected.forEach(timetable => {
                    if (
                      timetable.displayTemplate1?.idMainPage != result[0]?.idMainPage ||
                      timetable.displayTemplate1?.idSubPage1 != result[0]?.idSubPage1 ||
                      timetable.displayTemplate1?.idSubPage2 != result[0]?.idSubPage2 ||
                      timetable.displayTemplate1?.idSubPage3 != result[0]?.idSubPage3 ||
                      timetable.displayTemplate1?.idSubPage4 != result[0]?.idSubPage4 ||
                      timetable.displayTemplate1?.idSubPage5 != result[0]?.idSubPage5 ||
                      timetable.displayTemplate1?.idSubPage6 != result[0]?.idSubPage6 ||
                      timetable.displayTemplate1?.idSubPage7 != result[0]?.idSubPage7 ||
                      timetable.displayTemplate1?.idSubPage8 != result[0]?.idSubPage8 ||
                      timetable.displayTemplate1?.idSubPage9 != result[0]?.idSubPage9 ||
                      timetable.displayTemplate1?.idEmergencyPage != result[0]?.idEmergencyPage
                    ) {
                      timetable.timetableSchedule = null;
                    }
                    timetable.displayTemplate1 = result[0];
                    timetable.displayTemplate2 = result[1];
                  });
                }
                this.saveChangeTemplate(timeTablesSelected);
              }
            }
          );
          // if change template by click icon on row
        } else {
          let isDisplay2 = displayType == DisplaysEnum.DISPLAY_2;
          let display = isDisplay2 ? displayTemplate2 : displayTemplate1;
          let externalContent = _.cloneDeep(this.externalContentsOfTimetable);
          if (isDisplay2) {
            externalContent['display1'] = externalContent['display2'];
          }
          this.changeTemplateForDisplay(display, timeTable, isDisplay2, externalContent);
        }
      });
    }
  }

  /**
   * change template for display
   * @param displayTemplate
   * @param timetable
   * @param isDisplay2
   * @param externalContentOfStyle
   */
  private changeTemplateForDisplay(
    displayTemplate: DisplayTemplate,
    timetable: Timetable,
    isDisplay2: boolean,
    externalContentOfStyle: any
  ) {
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogChangeTemplateComponent,
      {
        data: {
          screen: ScreenNameEnum.TIME_TABLE,
          displayTemplate1: displayTemplate,
          isDisplay2: false,
          dataExternalContent: externalContentOfStyle,
          isChangeInlineDisplay2: isDisplay2
        }
      },
      result => {
        this.isChangedData = false;
        if (result) {
          // if display 1
          if (!isDisplay2) {
            if (
              timetable.displayTemplate1?.idMainPage != result?.idMainPage ||
              timetable.displayTemplate1?.idSubPage1 != result?.idSubPage1 ||
              timetable.displayTemplate1?.idSubPage2 != result?.idSubPage2 ||
              timetable.displayTemplate1?.idSubPage3 != result?.idSubPage3 ||
              timetable.displayTemplate1?.idSubPage4 != result?.idSubPage4 ||
              timetable.displayTemplate1?.idSubPage5 != result?.idSubPage5 ||
              timetable.displayTemplate1?.idSubPage6 != result?.idSubPage6 ||
              timetable.displayTemplate1?.idSubPage7 != result?.idSubPage7 ||
              timetable.displayTemplate1?.idSubPage8 != result?.idSubPage8 ||
              timetable.displayTemplate1?.idSubPage9 != result?.idSubPage9 ||
              timetable.displayTemplate1?.idEmergencyPage != result?.idEmergencyPage
            ) {
              timetable.timetableSchedule = null;
            }
            timetable.displayTemplate1 = result;
            // if display 2
          } else {
            timetable.displayTemplate2 = result;
          }
          this.saveChangeTemplate([...[], timetable]);
        }
      }
    );
  }

  /**
   * save change template for timetables
   * @param timetables
   */
  private saveChangeTemplate(timetables: Array<Timetable>): void {
    // get list timetable selected
    let timetablesSelected = timetables.map(timetable => Helper.convertDataTimetableBackward(timetable));
    // save change template
    this.timetableService.changeTemplates(timetablesSelected).subscribe(
      timetableResponse => {
        this.uncheckAllTimetable();
        let timetables = Helper.convertDataTimetables(timetableResponse);
        timetables.forEach(timetable => {
          let index = this.timetables.findIndex(timetableData => timetableData.id == timetable.id);
          let indexDisplay = this.timetablesDisplay.findIndex(timetableData => timetableData.id == timetable.id);
          if (index == -1) {
            return;
          }
          if (this.timetableSelected?.id == this.timetables[index]?.id) {
            this.drawTimetableService.clearTimeoutsStopDurationArea(true, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
            this.clearThreadDrawing();
          }
          this.timetables[index].templateDisplay1s = timetable.templateDisplay1s;
          this.timetables[index]['nameTemplateMain1'] = timetable.templateDisplay1s[0].name;
          this.timetables[index].displayTemplate1 = timetable.displayTemplate1;
          this.timetablesDisplay[indexDisplay].templateDisplay1s = timetable.templateDisplay1s;
          this.timetablesDisplay[indexDisplay]['nameTemplateMain1'] = timetable.templateDisplay1s[0].name;
          this.timetablesDisplay[indexDisplay].displayTemplate1 = timetable.displayTemplate1;
          if (timetable.templateDisplay2s) {
            this.timetables[index].templateDisplay2s = timetable.templateDisplay2s;
            this.timetables[index]['nameTemplateMain2'] = timetable.templateDisplay2s[0].name;
            this.timetables[index].displayTemplate2 = timetable.displayTemplate2;
            this.timetablesDisplay[indexDisplay].templateDisplay2s = timetable.templateDisplay2s;
            this.timetablesDisplay[indexDisplay]['nameTemplateMain2'] = timetable.templateDisplay2s[0].name;
            this.timetablesDisplay[indexDisplay].displayTemplate2 = timetable.displayTemplate2;
          }
          if (this.timetableSelected?.id != this.timetables[index]?.id) {
            return;
          }
          this.timetableSelected = undefined;
          this.selectTimetable(this.timetables[index], null, false, true);
        });
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * delete timetable
   *
   * @returns
   */
  private deleteTimeTable(): void {
    let checkedTimetables = this.timetables.filter(timetable => timetable.isChecked);
    if (!checkedTimetables?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    }
    if (this.timetableSelected?.isEdit || this.isShowFreeArea) {
      return;
    }

    this.timetableService.checkTimetablesSettingCalendar(checkedTimetables.map(timetable => timetable.id)).subscribe(
      data => {
        let key = data ? 'delete-timetable-delivered' : 'delete-checked-timetables';
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant(`timetable-editor.${key}`),
              button1: this.translateService.instant('timetable-editor.yes'),
              button2: this.translateService.instant('timetable-editor.btn-no')
            }
          },
          result => {
            if (!result) {
              return;
            }
            this.handleDeleteTimetables(checkedTimetables);
          }
        );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * handle delete timetables
   * @param checkedTimetables
   */
  private handleDeleteTimetables(checkedTimetables: Array<Timetable>): void {
    this.timetableService.deleteTimetables(checkedTimetables.map(timetable => timetable.id)).subscribe(
      () => {
        this.timetables = this.timetables.filter(timetable => !timetable.isChecked);
        this.timetablesDisplay = this.timetablesDisplay.filter(timetable => !timetable.isChecked);
        if (!this.timetablesDisplay.length) {
          this.isDisabledButtonSort = true;
          this.dataService.sendData([this.IS_DISABLE_BUTTON_SORT, this.isDisabledButtonSort]);
        }
        this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
        if (this.timetablesDisplay?.length) {
          if (this.timetableSelected?.isChecked) {
            this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
          }
        } else {
          this.clearAllBeforeLeave();
          this.timetableSelected = undefined;
        }
        // change status nashi of device
        let timetableInfo = this.selectedDeviceCalendar?.contentDays.filter(contentDayShowing => contentDayShowing.unlimitedInfo)[0];
        if (timetableInfo && checkedTimetables.find(timetable => timetable.id == timetableInfo?.unlimitedInfo['timetableId'])) {
          this.isUnlimited = false;
        }
        // set data after delete timetable
        checkedTimetables.forEach(timetable => {
          this.colorBeingUsed?.delete(timetable.id);
          this.selectedDeviceCalendar?.calendarDays.forEach(contentDay => {
            if (contentDay?.timetable?.id != timetable.id) {
              return;
            }
            contentDay.timetable = undefined;
            contentDay.color = undefined;
            contentDay.isDelivered = true;
            contentDay.timetableId = undefined;
          });
          if (this.selectedDeviceCalendar) {
            this.selectedDeviceCalendar.contentDays = this.selectedDeviceCalendar.contentDays.filter(
              contentDay => contentDay.timetableId != timetable.id
            );
            if (!this.isUnlimited) {
              this.selectedDeviceCalendar?.contentDays.forEach(data => {
                data.unlimitedInfo = undefined;
              });
            }
          }
        });
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * Clear timetable detail
   *
   * @param timetableDetail
   * @returns
   */
  private clearTimeTableDetail(timetableDetail: TimetableDetail): void {
    if (!this.isShowFreeArea || !timetableDetail) {
      return;
    }
    this.freeAreaTimetableMediaFiles = this.freeAreaTimetableMediaFiles.filter(
      data => data?.mediaFromPC?.url != timetableDetail.media?.url
    );
    this.filesData = this.filesData.filter(data => data?.url != timetableDetail.media?.url);
    timetableDetail.mediaId = undefined;
    timetableDetail.media = undefined;
    timetableDetail.text = undefined;
    timetableDetail.hasText = false;
    this.isChangedData = true;
    this.redrawTimetableDetail();
    // check active area
    this.checkActiveArea();
  }

  /**
   * duplicate timetable
   *
   * @returns
   */
  private duplicateTimeTable(): void {
    if (!this.timetableSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    } else if (this.timetableSelected.isEdit || this.isShowFreeArea) {
      return;
    }

    // clear all thread draw template
    this.clearThreadDrawing();
    this.isChangedData = true;
    this.drawTimetableService.clearTimeoutsStopDurationArea(true, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.noEdit = this.timetableSelected.no;
    this.suffixEdit = this.timetableSelected.suffix;
    this.nameEdit = this.timetableSelected.name;
    let duplicatedTimetable = new Timetable(null, this.nameEdit, this.noEdit, this.suffixEdit);
    duplicatedTimetable.label = this.timetableSelected?.label;
    duplicatedTimetable.timetableSchedule = this.timetableSelected?.timetableSchedule;
    duplicatedTimetable['oldId'] = this.timetableSelected.id;
    duplicatedTimetable.isEdit = true;
    this.timetableSelected = duplicatedTimetable;
    this.timetables.push(duplicatedTimetable);
    this.timetablesDisplay.push(duplicatedTimetable);
    this.dataService.sendData([this.IS_EDITING_TIMETABLE, this.timetableSelected.isEdit]);
  }

  // ==================== Handle tab Calendar ===============================

  /**
   * get all devices
   */
  private getAllDeviceCalendars(): void {
    this.deviceService.getAllDeviceForTimetableEditor().subscribe((data: Array<GroupDevice>) => {
      if (!data || !data?.length) {
        return;
      }

      this.groupDevices = data.filter(group => group?.devices.length);
      this.groupDevices.forEach(group => {
        group.deviceCalendars = Helper.convertDevicesToDeviceCalendars(group.devices, this.commonObject);
      });
      this.selectDeviceCalendar(this.groupDevices[0].deviceCalendars[0]);
    });
  }

  /**
   * select deviceCalendar
   * @param {DeviceCalendar} deviceCalendar selected deviceCalendar
   */
  public selectDeviceCalendar(deviceCalendar: DeviceCalendar): void {
    if (!deviceCalendar) {
      return;
    }
    if (this.selectedDay) {
      this.selectedDay = undefined;
    }
    this.selectedDeviceCalendar = deviceCalendar;
    this.unUsedColors = _.cloneDeep(this.colorsOriginal);
    this.colorBeingUsed = new Map<Number, string>();
    this.timetableContentDayService.getContentDaysByDeviceId(this.selectedDeviceCalendar.id).subscribe(
      contentDaysData => {
        this.selectedDeviceCalendar.contentDays = contentDaysData.map(contentDayData => {
          return Helper.convertDataContentDayForTimetable(contentDayData);
        });
        this.isUnlimited = this.selectedDeviceCalendar.contentDays
          .filter(contentDay => contentDay.fullDate >= new Date())
          .some(day => day.unlimitedInfo);
        this.selectedDeviceCalendar.calendarDays = Helper.getCalendars(this.selectedDeviceCalendar);
        let indexRemove = [];
        const currentDate = Helper.getCurrentByTimezoneSetting(this.commonObject, true);
        const endDate = Helper.getDateByDay(currentDate.getFullYear() + 2, currentDate.getMonth() + 1, currentDate.getDate() - 1);
        this.selectedDeviceCalendar.contentDays.forEach((contentDay, i) => {
          let date = Helper.getDateByDay(
            contentDay.fullDate.getFullYear(),
            contentDay.fullDate.getMonth() + 1,
            contentDay.fullDate.getDate()
          );
          let checkContentDay = false;
          let index = this.selectedDeviceCalendar.calendarDays.findIndex(content => content.fullDate.getTime() == date.getTime());
          if (contentDay.fullDate.getTime() > endDate.getTime()) {
            indexRemove.push(i);
            checkContentDay = true;
          }
          if (index != -1 && !checkContentDay) {
            contentDay.day = this.selectedDeviceCalendar.calendarDays[index].day;
            this.selectedDeviceCalendar.calendarDays[index] = contentDay;
            this.colorBeingUsed.set(contentDay.timetable?.id, contentDay.color);
          }
        });
        if (indexRemove.length > 0) {
          for (let i = indexRemove.length - 1; i >= 0; i--) {
            this.selectedDeviceCalendar.contentDays.splice(indexRemove[i], 1);
          }
        }
        this.selectedMonth = new Date().getMonth();
        this.selectedYear = this.selectedDeviceCalendar.startDate.getFullYear();
        // get contentDays by month selected and year selected
        this.contentDaysMonth = Helper.getCalendarsByMonthYear(
          this.selectedDeviceCalendar,
          this.selectedMonth,
          this.selectedDeviceCalendar.startDate.getFullYear(),
          this.commonObject
        );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * show previous month
   */
  public showPrevMonth(): void {
    // return if < start date of device
    if (this.selectedYear == this.selectedDeviceCalendar.startDate.getFullYear()) {
      if (this.selectedMonth <= this.selectedDeviceCalendar.startDate.getMonth()) {
        this.isPreviousMonth = true;
        return;
      }
    }
    this.isPreviousMonth = false;
    this.isNextMonth = false;
    this.selectedMonth = this.selectedMonth <= 0 ? 11 : this.selectedMonth - 1;
    this.selectedYear = this.selectedMonth == 11 ? this.selectedYear - 1 : this.selectedYear;
    // get calendar by selected month and selected year
    this.contentDaysMonth = Helper.getCalendarsByMonthYear(
      this.selectedDeviceCalendar,
      this.selectedMonth,
      this.selectedYear,
      this.commonObject
    );
  }

  /**
   * select month
   * @param month any
   */
  public selectMonth(month: any): void {
    this.selectedMonth = +month;
    // get calendar by selected month and selected year
    this.contentDaysMonth = Helper.getCalendarsByMonthYear(
      this.selectedDeviceCalendar,
      this.selectedMonth,
      this.selectedYear,
      this.commonObject
    );
  }

  /**
   * show next month
   */
  public showNextMonth(): void {
    // return if > finish date of device
    let dateEnd = _.cloneDeep(this.selectedDeviceCalendar.startDate);
    dateEnd.setFullYear(dateEnd.getFullYear() + Constant.MAX_YEAR);
    dateEnd.setDate(dateEnd.getDate() - 1);
    if (this.selectedYear == this.selectedDeviceCalendar.startDate.getFullYear() + Constant.MAX_YEAR) {
      if (this.selectedMonth >= dateEnd.getMonth()) {
        this.isNextMonth = true;
        return;
      }
    }
    this.isNextMonth = false;
    this.isPreviousMonth = false;
    this.selectedMonth = this.selectedMonth >= 11 ? 0 : this.selectedMonth + 1;
    this.selectedYear = this.selectedMonth == 0 ? this.selectedYear + 1 : this.selectedYear;
    // get calendar by selected month and selected year
    this.contentDaysMonth = Helper.getCalendarsByMonthYear(
      this.selectedDeviceCalendar,
      this.selectedMonth,
      this.selectedYear,
      this.commonObject
    );
  }

  /**
   * select day
   * @param {ContentDay} day selected day
   */
  public selectDay(day: ContentDay): void {
    if (day.isOtherMonth || day.inactive) {
      return;
    }
    this.selectedDay = day;
    if (this.selectedDay.timetable) {
      this.selectedDay.timetable = this.timetables.find(timetable => timetable.id == this.selectedDay.timetable.id);
    }
  }

  /**
   * set data content day
   * @param contentDay ContentDay
   * @param date Date
   */
  public setDataContentDay(contentDay: ContentDay, date: Date): void {
    let indexRepeat = this.selectedDeviceCalendar.calendarDays.findIndex(calendarDay => calendarDay.fullDate.getTime() === date.getTime());
    if (!this.selectedDeviceCalendar.calendarDays[indexRepeat].timetable && !contentDay.timetable) {
      return;
    }
    this.selectedDeviceCalendar.calendarDays[indexRepeat].isDelivered = false;
    if (!contentDay.timetable) {
      this.selectedDeviceCalendar.calendarDays[indexRepeat].color = '';
      this.selectedDeviceCalendar.calendarDays[indexRepeat].timetable = new Timetable(-1);
      this.selectedDeviceCalendar.calendarDays[indexRepeat].timetableId = -1;
      this.handleDataOfUnlimitedDataInfo(indexRepeat, -1, contentDay);
      return;
    }

    this.selectedDeviceCalendar.calendarDays[indexRepeat].timetable = Helper.convertDataTimetable(contentDay.timetable);
    this.selectedDeviceCalendar.calendarDays[indexRepeat].timetableId = contentDay.timetableId;
    const id = this.selectedDeviceCalendar.calendarDays[indexRepeat].timetable.id;

    if (this.colorBeingUsed?.has(id)) {
      this.selectedDeviceCalendar.calendarDays[indexRepeat].color = this.colorBeingUsed?.get(id);
      this.selectedDeviceCalendar.calendarDays[indexRepeat].timetableId = id;
      this.handleDataOfUnlimitedDataInfo(indexRepeat, id, contentDay);
      return;
    }
    const color = this.unUsedColors.shift();
    this.selectedDeviceCalendar.calendarDays[indexRepeat].color = color;
    this.colorBeingUsed.set(id, color);
    this.selectedDeviceCalendar.calendarDays[indexRepeat].timetableId = id;
    this.handleDataOfUnlimitedDataInfo(indexRepeat, id, contentDay);
  }

  /**
   * get used colors
   * @param currentDate
   * @returns
   */
  private getUsedColors(currentDate: Date): void {
    // get color being used
    this.colorBeingUsed = new Map<Number, string>();
    this.selectedDeviceCalendar.contentDays?.forEach(contentDay => {
      if (contentDay.fullDate >= currentDate && contentDay.timetable && !this.colorBeingUsed?.has(contentDay.timetable.id)) {
        this.colorBeingUsed.set(contentDay.timetable.id, contentDay.color);
      }
    });
  }

  /**
   * get unused colors
   * @param currentDate
   * @returns
   */
  private getUnUsedColors(currentDate: Date): void {
    let colors = new Array<string>();
    let timetableIds = new Set(
      this.selectedDeviceCalendar?.contentDays?.filter(contentDay => contentDay.fullDate >= currentDate)?.map(day => day?.timetable?.id)
    );
    [...timetableIds].forEach(id => {
      const contentDay = this.selectedDeviceCalendar?.contentDays?.find(
        day => day.fullDate >= currentDate && day?.timetable?.id == id && day?.color
      );
      if (contentDay) {
        colors.push(contentDay.color);
      }
    });

    let colorsOriginal: string[] = _.cloneDeep(this.colorsOriginal);
    while (colors.length >= this.MAX_NUMBER_COLORS) {
      if (!colorsOriginal.length) {
        colorsOriginal = _.cloneDeep(this.colorsOriginal);
      }
      let unUsedColorsOriginal: string[] = _.cloneDeep(colorsOriginal);
      unUsedColorsOriginal.forEach(color => {
        const index = colors.findIndex(c => c == color);
        if (index != -1) {
          colors.splice(index, 1);
          const indexColor = colorsOriginal.findIndex(c => c == color);
          if (indexColor != -1) {
            colorsOriginal.splice(indexColor, 1);
          }
        }
      });
    }
    // if there is an unused original colors
    if (colorsOriginal.length && colorsOriginal.length != this.colorsOriginal.length) {
      this.unUsedColors = colorsOriginal;
      // if using all colors
    } else if (!colors.length) {
      this.unUsedColors = _.cloneDeep(this.colorsOriginal);
    } else {
      let colorsOriginal: string[] = _.cloneDeep(this.colorsOriginal);
      colors.forEach(color => {
        const index = colorsOriginal.findIndex(c => c == color);
        if (index == -1) {
          return;
        }
        colorsOriginal.splice(index, 1);
      });
      this.unUsedColors = colorsOriginal;
    }
  }

  /**
   * Handle data of unlimited data information
   *
   * @param indexRepeat
   * @param timetableId
   * @param contentDay
   */
  private handleDataOfUnlimitedDataInfo(indexRepeat: number, timetableId: Number, contentDay: ContentDay): void {
    if (!this.isUnlimited) {
      this.selectedDeviceCalendar.calendarDays[indexRepeat].unlimitedInfo = null;
      return;
    }
    if (this.isUnlimitedDataFromDialogDataRecurrence) {
      let unlimitedInfoObject = {};
      unlimitedInfoObject['timetableId'] = timetableId;
      unlimitedInfoObject['color'] = this.colorBeingUsed?.get(timetableId);
      unlimitedInfoObject['deadlineDate'] = Helper.convertDateToFormatDateSave(contentDay.finishDate, this.commonObject.setting);
      unlimitedInfoObject['isDelivered'] = false;
      this.selectedDeviceCalendar.calendarDays[indexRepeat].unlimitedInfo = unlimitedInfoObject;
    }
  }

  /**
   * set recurrence of the timetable in selected day
   * @param {ContentDay} day selected day
   */
  public setRepeat(day: ContentDay): void {
    if (day.isOtherMonth || day.inactive) {
      return;
    }
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogPlaylistRecurrenceComponent,
      {
        data: {
          contentDay: Object.assign({}, day),
          startDate: this.selectedDeviceCalendar.startDate,
          device: this.selectedDeviceCalendar,
          calendars: this.selectedDeviceCalendar.calendarDays,
          screen: ScreenNameEnum.TIME_TABLE,
          timeDateLine: this.timeDateLine
        }
      },
      result => {
        this.isChangedData = false;
        if (!result) {
          return;
        }
        let contentDay = result[Constant.CONTENT_DAY_ELEMENT];
        this.isUnlimitedDataFromDialogDataRecurrence = result[Constant.IS_UNLIMITED_ELEMENT];
        if (this.isUnlimitedDataFromDialogDataRecurrence) {
          this.isUnlimited = contentDay.timetable ? true : false;
        }
        const date = new Date();
        const currentDate = Helper.getDateByDay(date.getFullYear(), date.getMonth() + 1, date.getDate());
        this.getUsedColors(currentDate);
        this.getUnUsedColors(currentDate);
        // One day
        if (!contentDay.isRepeated) {
          this.setDataContentDay(contentDay, contentDay.fullDate);
          // Repeat
        } else {
          // repeat mode: Every Day
          if (contentDay.repeatMode == RepeatModeEnum.EVERY_DAY) {
            this.handleEveryDayMode(contentDay);
            if (this.isUnlimitedDataFromDialogDataRecurrence) {
              this.updateOldContentDayData(contentDay.startDate);
            }
            // repeat mode: Every Week
          } else if (contentDay.repeatMode == RepeatModeEnum.EVERY_WEEK) {
            let listDay = [];
            // get day selected
            result[Constant.LIST_DAY_ELEMENT].forEach((day, index) => {
              if (day[1]) {
                listDay.push(index);
              }
            });
            this.handleEveryWeekMode(contentDay, listDay);
          }
        }
        this.selectedDay = undefined;
        this.selectedDeviceCalendar.contentDays = this.selectedDeviceCalendar.calendarDays.filter(
          contentDay => contentDay.timetable || contentDay.timetableId
        );
        this.contentDaysMonth.forEach(contentDay => {
          let index = this.selectedDeviceCalendar.contentDays.findIndex(
            content => content.fullDate.getTime() == contentDay.fullDate.getTime()
          );
          if (index != -1) {
            contentDay.timetable = this.selectedDeviceCalendar.contentDays[index].timetable;
          }
        });
        this.saveContentDays();
      }
    );
  }

  /**
   * Update old content day Data
   *
   * @param startDate
   * @returns
   */
  private updateOldContentDayData(startDate: Date): void {
    let startContentDay = this.selectedDeviceCalendar.contentDays.find(
      content => content.unlimitedInfo && content.fullDate.getTime() == startDate.getTime()
    );
    if (!startContentDay) {
      return;
    }
    this.selectedDeviceCalendar.contentDays.forEach(contentDay => {
      if (contentDay.fullDate.getTime() < startDate.getTime()) {
        contentDay.unlimitedInfo = startContentDay.unlimitedInfo;
      }
    });
  }

  /**
   * handle repeat mode: Every Week
   * @param contentDay content of day
   * @param listDay
   */
  private handleEveryWeekMode(contentDay: any, listDay: any): void {
    const endYear = contentDay.finishDate.getFullYear();
    const startYear = contentDay.startDate.getFullYear();
    if (endYear == startYear) {
      // if finish date same month
      if (contentDay.finishDate.getMonth() == contentDay.startDate.getMonth()) {
        for (let day = contentDay.startDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
          let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            this.setDataContentDay(contentDay, date);
          }
        }
        // if finish date > start date (dif month)
      } else if (contentDay.finishDate.getTime() > contentDay.startDate.getTime()) {
        // get data repeat current month
        let daysInMonth = Helper.daysInMonth(contentDay.startDate);
        for (let day = contentDay.startDate.getDate(); day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            this.setDataContentDay(contentDay, date);
          }
        }
        // get data repeat next month
        for (let month = contentDay.startDate.getMonth() + 1; month < contentDay.finishDate.getMonth(); month++) {
          let date = Helper.getDateByMonth(startYear, month + 1);
          let daysInMonth = Helper.daysInMonth(date);
          for (let day = 1; day <= daysInMonth; day++) {
            let date = Helper.getDateByDay(startYear, month + 1, day);
            if (listDay.findIndex(index => date.getDay() == index) != -1) {
              this.setDataContentDay(contentDay, date);
            }
          }
        }
        // get data repeat end month
        let monthEndDate = Helper.getDateByMonth(startYear, contentDay.finishDate.getMonth() + 1);
        for (let day = monthEndDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
          let date = Helper.getDateByDay(monthEndDate.getFullYear(), monthEndDate.getMonth() + 1, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            this.setDataContentDay(contentDay, date);
          }
        }
      }
    } else if (endYear > startYear) {
      // get data repeat current month
      let daysInMonth = Helper.daysInMonth(contentDay.startDate);
      for (let day = contentDay.startDate.getDate(); day <= daysInMonth; day++) {
        let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
        if (listDay.findIndex(index => date.getDay() == index) != -1) {
          this.setDataContentDay(contentDay, date);
        }
      }
      // get data repeat month of start date -> 12
      for (let month = contentDay.startDate.getMonth() + 1; month < 12; month++) {
        let date = Helper.getDateByMonth(startYear, month + 1);
        let daysInMonth = Helper.daysInMonth(date);
        for (let day = 1; day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(startYear, month + 1, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            this.setDataContentDay(contentDay, date);
          }
        }
      }
      if (endYear == startYear + Constant.MAX_YEAR) {
        for (let i = 1; i < Constant.MAX_YEAR; i++) {
          // get data repeat month of start date 1 -> 12 next year
          for (let month = 0; month < 12; month++) {
            let date = Helper.getDateByMonth(startYear + i, month + 1);
            let daysInMonth = Helper.daysInMonth(date);
            for (let day = 1; day <= daysInMonth; day++) {
              let date = Helper.getDateByDay(startYear + i, month + 1, day);
              if (listDay.findIndex(index => date.getDay() == index) != -1) {
                this.setDataContentDay(contentDay, date);
              }
            }
          }
        }
      }
      // get data repeat month of end date previous end month
      for (let month = 1; month < contentDay.finishDate.getMonth() + 1; month++) {
        let date = Helper.getDateByMonth(endYear, month);
        let daysInMonth = Helper.daysInMonth(date);
        for (let day = 1; day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(endYear, month, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            this.setDataContentDay(contentDay, date);
          }
        }
      }
      // get data repeat end month
      let monthEndDate = Helper.getDateByMonth(endYear, contentDay.finishDate.getMonth() + 1);
      for (let day = monthEndDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
        let date = Helper.getDateByDay(monthEndDate.getFullYear(), monthEndDate.getMonth() + 1, day);
        if (listDay.findIndex(index => date.getDay() == index) != -1) {
          this.setDataContentDay(contentDay, date);
        }
      }
    }
  }

  /**
   * handle repeat mode: Every Day
   * @param contentDay content of day
   */
  private handleEveryDayMode(contentDay: any): void {
    const endYear = contentDay.finishDate.getFullYear();
    const startYear = contentDay.startDate.getFullYear();
    if (endYear == startYear) {
      // if finish date same month
      if (contentDay.finishDate.getMonth() == contentDay.startDate.getMonth()) {
        for (let day = contentDay.startDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
          let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
          this.setDataContentDay(contentDay, date);
        }
        // if finish date > start date (dif month)
      } else if (contentDay.finishDate.getTime() > contentDay.startDate.getTime()) {
        // get data repeat current month
        let daysInMonth = Helper.daysInMonth(contentDay.startDate);
        for (let day = contentDay.startDate.getDate(); day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
          this.setDataContentDay(contentDay, date);
        }
        // get data repeat next month
        for (let month = contentDay.startDate.getMonth() + 1; month < contentDay.finishDate.getMonth(); month++) {
          let date = Helper.getDateByMonth(startYear, month + 1);
          let daysInMonth = Helper.daysInMonth(date);
          for (let day = 1; day <= daysInMonth; day++) {
            let date = Helper.getDateByDay(startYear, month + 1, day);
            this.setDataContentDay(contentDay, date);
          }
        }
        // get data repeat end month
        let monthEndDate = Helper.getDateByMonth(startYear, contentDay.finishDate.getMonth() + 1);
        for (let day = monthEndDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
          let date = Helper.getDateByDay(monthEndDate.getFullYear(), monthEndDate.getMonth() + 1, day);
          this.setDataContentDay(contentDay, date);
        }
      }
    } else if (endYear > startYear) {
      // get data repeat current month
      let daysInMonth = Helper.daysInMonth(contentDay.startDate);
      for (let day = contentDay.startDate.getDate(); day <= daysInMonth; day++) {
        let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
        this.setDataContentDay(contentDay, date);
      }
      // get data repeat month of start date -> 12
      for (let month = contentDay.startDate.getMonth() + 1; month < 12; month++) {
        let date = Helper.getDateByMonth(startYear, month + 1);
        let daysInMonth = Helper.daysInMonth(date);
        for (let day = 1; day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(startYear, month + 1, day);
          this.setDataContentDay(contentDay, date);
        }
      }
      if (endYear == startYear + Constant.MAX_YEAR) {
        for (let i = 1; i < Constant.MAX_YEAR; i++) {
          // get data repeat month of start date 1 -> 12 next year
          for (let month = 0; month < 12; month++) {
            let date = Helper.getDateByMonth(startYear + i, month + 1);
            let daysInMonth = Helper.daysInMonth(date);
            for (let day = 1; day <= daysInMonth; day++) {
              let date = Helper.getDateByDay(startYear + i, month + 1, day);
              this.setDataContentDay(contentDay, date);
            }
          }
        }
      }
      // get data repeat month of end date previous end month
      for (let month = 1; month < contentDay.finishDate.getMonth() + 1; month++) {
        let date = Helper.getDateByMonth(endYear, month);
        let daysInMonth = Helper.daysInMonth(date);
        for (let day = 1; day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(endYear, month, day);
          this.setDataContentDay(contentDay, date);
        }
      }
      // get data repeat end month
      let monthEndDate = Helper.getDateByMonth(endYear, contentDay.finishDate.getMonth() + 1);
      for (let day = monthEndDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
        let date = Helper.getDateByDay(monthEndDate.getFullYear(), monthEndDate.getMonth() + 1, day);
        this.setDataContentDay(contentDay, date);
      }
    }
  }

  /**
   * preview
   */
  private preview(): void {
    // clear all thread draw template
    this.clearAllDrawThread();
    this.templateSelectedTypeDisplay1 = DestinationEnum.MAIN;
    this.templateSelectedTypeDisplay2 = DestinationEnum.MAIN;
    // get data timetable is selected
    this.getTimetableWithFullData();
  }

  /**
   * get timetable with full data
   */
  private getTimetableWithFullData(): void {
    // get fill data template main - sub by timetable id
    this.timetableService.getTimetableWithFullDataTemplateById(this.timetableSelected.id).subscribe(
      data => {
        if (!data) {
          return;
        }
        // assign value timetableS
        const timetableOutput = Helper.convertDataTimetable(data);

        // get buttons preview
        this.buttonsPreviewDisplay1 = this.getButtonsPreview(timetableOutput?.displayTemplate1);
        this.buttonsPreviewDisplay2 = this.getButtonsPreview(timetableOutput?.displayTemplate2);

        // set newest display
        this.timetableSelected.displayTemplate1 = timetableOutput?.displayTemplate1;
        this.timetableSelected.displayTemplate2 = timetableOutput?.displayTemplate2;
        this.timetableSelected.templateDisplay1s = timetableOutput.templateDisplay1s;
        this.timetableSelected.templateDisplay2s = timetableOutput.templateDisplay2s;
        // get all areas display
        this.getAllAreasDisplay();
        forkJoin({
          freeArea: this.timetableDetailService.getTimetableDetailInformation(this.timetableSelected.id),
          urlArea: this.timetableDetailURLService.getTimetableDetailURLInformation(this.timetableSelected.id)
        }).subscribe(data => {
          this.timetableSelected.timetableDetails = Helper.convertTimetableDetails(data.freeArea);

          this.timetableSelected.timetableUrlDetails = Helper.convertTimetableUrlDetails(data.urlArea);
          this.timetableDetailURLAreaClone = _.cloneDeep(this.timetableSelected.timetableUrlDetails);
          // set template type for area display 1
          this.setTemplateTypeForArea(this.areasDisplay1, this.AREA_DISPLAY_1);

          // set template type for url area display 1
          this.setTemplateTypeForUrlArea(this.urlAreasDisplay1, this.AREA_DISPLAY_1);

          // set template type for area display 2
          this.setTemplateTypeForArea(this.areasDisplay2, this.AREA_DISPLAY_2);

          // set template type for url area display 2
          this.setTemplateTypeForUrlArea(this.urlAreasDisplay2, this.AREA_DISPLAY_2);

          // handle show hide column text and media
          this.handleShowColumnTextAndMedia();
          // sort by id area and template type display 1
          const timetableDetailsDisplay1 = this.timetableSelected.timetableDetails
            .filter(timetableDetail => timetableDetail?.areaDisplay1Id)
            .sort((timetableDetail1, timetableDetail2) => {
              return (
                timetableDetail1?.areaDisplay1?.templateType - timetableDetail2?.areaDisplay1?.templateType ||
                (timetableDetail1?.areaDisplay1Id as number) - (timetableDetail2?.areaDisplay1Id as number)
              );
            });
          // sort by id area and template type display 2
          const timetableDetailsDisplay2 = this.timetableSelected.timetableDetails
            .filter(timetableDetail => !timetableDetail?.areaDisplay1Id)
            .sort((timetableDetail1, timetableDetail2) => {
              return (
                timetableDetail1?.areaDisplay2?.templateType - timetableDetail2?.areaDisplay2?.templateType ||
                (timetableDetail1?.areaDisplay2Id as number) - (timetableDetail2?.areaDisplay2Id as number)
              );
            });

          const timetableUrlDetailsDisplay1 = this.timetableSelected.timetableUrlDetails
            .filter(timetableDetail => timetableDetail?.areaDisplay1Id)
            .sort((timetableDetail1, timetableDetail2) => {
              return (
                timetableDetail1?.areaDisplay1?.templateType - timetableDetail2?.areaDisplay1?.templateType ||
                (timetableDetail1?.areaDisplay1Id as number) - (timetableDetail2?.areaDisplay1Id as number)
              );
            });
          // sort by id area and template type display 2
          const timetableUrlDetailsDisplay2 = this.timetableSelected.timetableUrlDetails
            .filter(timetableDetail => !timetableDetail?.areaDisplay1Id)
            .sort((timetableDetail1, timetableDetail2) => {
              return (
                timetableDetail1?.areaDisplay2?.templateType - timetableDetail2?.areaDisplay2?.templateType ||
                (timetableDetail1?.areaDisplay2Id as number) - (timetableDetail2?.areaDisplay2Id as number)
              );
            });
          this.timetableSelected.timetableDetails = timetableDetailsDisplay1.concat(timetableDetailsDisplay2);
          this.timetableSelected.timetableUrlDetails = timetableUrlDetailsDisplay1.concat(timetableUrlDetailsDisplay2);
          if (this.timetableSelected?.timetableDetails?.length == 0 && this.areasDisplay2?.length > 0) {
            this.timetableSelected?.timetableDetails.push(new TimetableDetail(this.timetableSelected.id));
          }

          if (this.timetableSelected?.timetableUrlDetails?.length == 0 && this.urlAreasDisplay2?.length > 0) {
            this.timetableSelected?.timetableUrlDetails.push(new TimetableDetailURLArea(this.timetableSelected.id));
          }

          // check active area
          this.checkActiveArea();
          // check active area
          this.checkActiveUrlArea();
          if (this.timetableSelected.displayTemplate1) {
            this.referencePositionColumnsByTemplate = Helper.getReferencePositionColumnsByTemplates(
              this.timetableSelected?.templateDisplay1s
            );
          } else {
            this.referencePositionColumnsByTemplate = [];
          }
          this.referencePositionColumnsByTemplate = this.fillDataForReferencePositionColumn();
          this.timetableDetailsClone = _.cloneDeep(this.timetableSelected.timetableDetails);
          this.timetableUrlDetailsClone = _.cloneDeep(this.timetableSelected.timetableUrlDetails);
          this.getInformationSchedule();
        });
      },
      error => {
        Helper.handleError(error, this.translateService, this.dialogService);
        this.saveDataSuccess.emit(false);
      }
    );
  }

  /**
   * Fill data for reference position column
   *
   * @returns
   */
  private fillDataForReferencePositionColumn(): number[] {
    let newReferencePositionColumnsByTemplate = [];
    const lastReferencePositionColumn = this.referencePositionColumnsByTemplate[this.referencePositionColumnsByTemplate.length - 1];
    for (let index = 0; index <= lastReferencePositionColumn; index++) {
      newReferencePositionColumnsByTemplate.push(index);
    }
    return _.sortBy(newReferencePositionColumnsByTemplate);
  }

  /**
   * get information schedule
   */
  private getInformationSchedule(): void {
    this.timetableScheduleService.getTimetableScheduleByTimetableId(this.timetableSelected.id).subscribe(data => {
      if (data) {
        this.timetableSelected.timetableSchedule = Helper.convertTimetableSchedule(
          data,
          false,
          this.timeDateLine,
          this.referencePositionColumnsByTemplate.length
        );
        this.timetableSelected.timetableSchedule.headers[0] = this.translateService.instant('timetable-editor.time');
      } else {
        this.timetableSelected.timetableSchedule = new TimetableSchedule();
      }
      this.multiLanguageTooltip();
      this.validateIndexWordOfSchedule();
      this.setTimetableScheduleHeader();
      this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
      this.previewTemplate();
    });
  }

  /**
   * validate index word of schedule
   */
  private validateIndexWordOfSchedule(): void {
    // get areas index word
    let areasIndexWordAndGroupIds = Helper.getAreasIndexWordAndGroupIds(
      this.timetableSelected.templateDisplay1s,
      this.timetableSelected.displayTemplate1
    );
    let areasIndexWord: Area[] = areasIndexWordAndGroupIds[Constant.AREAS_INDEX_WORD_INDEX];
    let groupIds = areasIndexWordAndGroupIds[Constant.GROUP_IDS_INDEX];

    if (areasIndexWord.length && groupIds.length) {
      // get index words by group
      this.indexWordService.getIndexWordsByGroupIds(groupIds).subscribe(indexWords => {
        if (!indexWords.length) {
          return;
        }
        if (this.timetableSelected.timetableSchedule?.itemDetails) {
          this.timetableSelected.timetableSchedule.itemDetails.forEach((item, index) => {
            item.indexColumnNames = new Array<number>();
            areasIndexWord.forEach(area => {
              if (area.referencePositionRow <= index) {
                let indexColumn = area.referencePositionColumn + 1;
                let nameIndexWord = Helper.getNameIndexWordFromScheduleToValidate(this.timetableSelected, index, indexColumn);
                if (!nameIndexWord || !_.cloneDeep(nameIndexWord).trim()) {
                  return;
                }
                let indexWord = indexWords.find(
                  itemIndexWord => itemIndexWord.groupId == area.indexWordGroupId && itemIndexWord.name == nameIndexWord
                );
                if (
                  !indexWord ||
                  (area.checkTypeTextArea() && (!indexWord.text || indexWord.text == Constant.EMPTY)) ||
                  (!area.checkTypeTextArea() && !indexWord.media)
                ) {
                  item.isInvalidIndexWord = true;
                  if (!item.indexColumnNames.includes(indexColumn)) {
                    item.indexColumnNames.push(indexColumn);
                  }
                }
              }
            });
          });
        }
        this.multiLanguageTooltip();
      });
    }
  }

  /**
   * multi language message error
   * @returns
   */
  public multiLanguageTooltip(): void {
    if (!this.timetableSelected?.timetableSchedule?.itemDetails?.length) {
      return;
    }
    this.timetableSelected.timetableSchedule.itemDetails.forEach(item => {
      let timeInvalid = Helper.formatString(
        this.translateService.instant('timetable-editor.time-invalid'),
        `${this.timetableSelected.timetableSchedule.headers[0]}`
      );
      if (item.isInValidFormat && !item.isInvalidIndexWord) {
        item.titleTooltip = timeInvalid;
      } else if (!item.isInValidFormat && item.isInvalidIndexWord) {
        item.titleTooltip = this.getMsgInvalidIndexWord(item);
      } else if (item.isInValidFormat && item.isInvalidIndexWord) {
        item.titleTooltip = `${timeInvalid}\n${this.getMsgInvalidIndexWord(item)}`;
      }
    });
  }

  /**
   * get msg invalid index word
   * @param item
   * @returns
   */
  private getMsgInvalidIndexWord(item: ItemDetail): string {
    let msg: string;
    item.indexColumnNames.sort((item1, item2) => item1 - item2);
    item.indexColumnNames.forEach((data, index) => {
      const indexWordInvalid = Helper.formatString(
        this.translateService.instant('timetable-editor.index-word-invalid'),
        `${this.timetableSelected.timetableSchedule.headers[data]}`
      );
      msg = index < 1 ? indexWordInvalid : `${msg}\n${indexWordInvalid}`;
    });
    return msg;
  }

  /**
   * Handle show hide column text and media
   */
  private handleShowColumnTextAndMedia(): void {
    this.isOnlyFreeTextArea =
      this.areasDisplay1?.every(area => area.getArea().linkReferenceData != null) &&
      this.areasDisplay2?.every(area => area.getArea().linkReferenceData != null);
    this.isOnlyFreePictureArea =
      this.areasDisplay1?.every(area => area.getArea().attribute != null) &&
      this.areasDisplay2?.every(area => area.getArea().attribute != null);
  }

  /**
   * set template type for area
   * @param areas
   * @param areaDisplayType
   */
  private setTemplateTypeForArea(areas: Array<Area>, areaDisplayType: string): void {
    areas.forEach(area => {
      const index =
        areaDisplayType == this.AREA_DISPLAY_1
          ? this.timetableSelected?.timetableDetails?.findIndex(timetableDetail => timetableDetail?.areaDisplay1Id == area.id)
          : this.timetableSelected?.timetableDetails?.findIndex(timetableDetail => timetableDetail?.areaDisplay2Id == area.id);
      if (index == -1) {
        return;
      }
      this.timetableSelected.timetableDetails[index][areaDisplayType].templateType = area.templateType;
    });
  }

  /**
   * set template type for area
   * @param areas
   * @param areaDisplayType
   */
  private setTemplateTypeForUrlArea(areas: Array<Area>, areaDisplayType: string): void {
    areas.forEach(area => {
      const index =
        areaDisplayType == this.AREA_DISPLAY_1
          ? this.timetableSelected?.timetableUrlDetails?.findIndex(timetableDetail => timetableDetail?.areaDisplay1Id == area.id)
          : this.timetableSelected?.timetableUrlDetails?.findIndex(timetableDetail => timetableDetail?.areaDisplay2Id == area.id);
      if (index == -1) {
        return;
      }
      this.timetableSelected.timetableUrlDetails[index][areaDisplayType].templateType = area.templateType;
    });
  }

  /**
   * get all areas display
   */
  private getAllAreasDisplay(): void {
    this.areasDisplay1 = new Array<Area>();
    this.areasDisplay2 = new Array<Area>();

    this.urlAreasDisplay1 = new Array<Area>();
    this.urlAreasDisplay2 = new Array<Area>();

    // get list area (free picture/free text) display 1
    if (this.timetableSelected.templateDisplay1s) {
      this.timetableSelected.templateDisplay1s
        .filter(template => template)
        .forEach(data => {
          this.getAllAreaTemplateForTimetable(data).forEach(area => {
            this.areasDisplay1.push(area);
          });
          this.getAllUrlAreaTemplateForTimetable(data).forEach(area => {
            this.urlAreasDisplay1.push(area);
          });
        });
    }

    // get list area (free picture/free text) display 2
    if (this.timetableSelected.templateDisplay2s) {
      this.timetableSelected.templateDisplay2s
        .filter(template => template)
        .forEach(data => {
          this.getAllAreaTemplateForTimetable(data).forEach(area => {
            this.areasDisplay2.push(area);
          });
          this.getAllUrlAreaTemplateForTimetable(data).forEach(area => {
            this.urlAreasDisplay2.push(area);
          });
        });
    }
  }

  /**
   * get all area template for timetable
   * @param template Template object
   */
  private getAllAreaTemplateForTimetable(template: Template): Array<Area> {
    return Helper.getAllAreaTemplate(template).filter(
      area =>
        area &&
        !area.isFix &&
        (area.getArea().attribute == LinkDataPictureEnum.FREE_PICTURE || area.getArea().linkReferenceData == LinkDataTextEnum.FREE_TEXT)
    );
  }

  /**
   * get all url area template for timetable
   * @param template Template object
   * @returns list url area
   */
  private getAllUrlAreaTemplateForTimetable(template: Template): Array<Area> {
    return Helper.getAllAreaTemplate(template).filter(area => area && area.isURL);
  }

  /**
   * set timetable schedule header
   */
  private setTimetableScheduleHeader(): void {
    let headerCopy: Array<string> = _.cloneDeep(this.timetableSelected.timetableSchedule.headers);
    // if not edited items
    if (!this.timetableSelected.timetableSchedule?.isChangeScheduleHeader) {
      this.timetableSelected.timetableSchedule.headers = new Array(this.referencePositionColumnsByTemplate.length).fill(null);
      // set item name default from template
      this.referencePositionColumnsByTemplate?.forEach(referencePositionColumn => {
        if (referencePositionColumn == 0) {
          this.timetableSelected.timetableSchedule.headers[referencePositionColumn] = this.translateService.instant(
            'timetable-editor.time'
          );
        } else {
          this.timetableSelected.timetableSchedule.headers[referencePositionColumn] = Helper.formatString(
            this.translateService.instant('timetable-editor.item'),
            `${referencePositionColumn}`
          );
        }
      });
      Helper.updateLanguageHeaders(this.timetableSelected.timetableSchedule.headers, this.translateService);
      this.timetableSelected.timetableSchedule.isChangeScheduleHeader = false;
      // if edited item
    } else {
      this.timetableSelected.timetableSchedule.hasItemNames = true;
      this.timetableSelected.timetableSchedule.headers = new Array(this.referencePositionColumnsByTemplate.length).fill(null);
      for (const reference of this.referencePositionColumnsByTemplate) {
        // if add new some area set default name
        if (headerCopy[reference] == null) {
          if (reference == 0) {
            this.timetableSelected.timetableSchedule.headers[reference] = this.translateService.instant('timetable-editor.time');
          } else {
            this.timetableSelected.timetableSchedule.headers[reference] = Helper.formatString(
              this.translateService.instant('timetable-editor.item'),
              `${reference}`
            );
          }
        } else {
          this.timetableSelected.timetableSchedule.headers[reference] = headerCopy[reference];
        }
      }
      Helper.updateLanguageHeaders(this.timetableSelected.timetableSchedule.headers, this.translateService);
    }
    let headers = this.timetableSelected?.timetableSchedule?.headers;
    this.headersDisplay = headers?.filter(header => header != null);

    // delete schedule if change area in LCD Layout Editor (edit, delete) => no areas is set TIMETABLE
    if (!this.headersDisplay.length && this.timetableSelected?.timetableSchedule?.itemDetails) {
      this.timetableScheduleService.deleteScheduleByTimetableId(this.timetableSelected.id).subscribe(() => {
        this.timetableSelected.timetableSchedule = null;
      });
    } else {
      // if headers in GUI !== headers in DB => update data
      const headersInDB = headerCopy.filter(header => header != null);
      if (headersInDB.length !== this.headersDisplay.length) {
        let items = new Array<string>();
        this.timetableSelected?.timetableSchedule?.itemDetails?.forEach(data => {
          items.push(JSON.stringify(data.items));
        });
        this.timetableScheduleService
          .updateItemNames(this.timetableSelected.id, headers, items, this.timetableSelected.timetableSchedule.isChangeScheduleHeader)
          .subscribe();
      }
    }
  }

  /**
   * get buttons preview
   * @param displayTemplate
   */
  private getButtonsPreview(displayTemplate: DisplayTemplate): Array<{ key: DestinationEnum; value: string }> {
    if (!displayTemplate) {
      return;
    }
    let buttonsPreview = new Array<{ key: DestinationEnum; value: string }>();
    if (displayTemplate.idMainPage) {
      buttonsPreview.push({ key: DestinationEnum.MAIN, value: this.translateService.instant('timetable-editor.buttons.main') });
    }
    if (displayTemplate.idSubPage1) {
      buttonsPreview.push({ key: DestinationEnum.SUB_PAGE_1, value: this.translateService.instant('timetable-editor.buttons.sub1') });
    }
    if (displayTemplate.idSubPage2) {
      buttonsPreview.push({ key: DestinationEnum.SUB_PAGE_2, value: this.translateService.instant('timetable-editor.buttons.sub2') });
    }
    if (displayTemplate.idSubPage3) {
      buttonsPreview.push({ key: DestinationEnum.SUB_PAGE_3, value: this.translateService.instant('timetable-editor.buttons.sub3') });
    }
    if (displayTemplate.idSubPage4) {
      buttonsPreview.push({ key: DestinationEnum.SUB_PAGE_4, value: this.translateService.instant('timetable-editor.buttons.sub4') });
    }
    if (displayTemplate.idSubPage5) {
      buttonsPreview.push({ key: DestinationEnum.SUB_PAGE_5, value: this.translateService.instant('timetable-editor.buttons.sub5') });
    }
    if (displayTemplate.idSubPage6) {
      buttonsPreview.push({ key: DestinationEnum.SUB_PAGE_6, value: this.translateService.instant('timetable-editor.buttons.sub6') });
    }
    if (displayTemplate.idSubPage7) {
      buttonsPreview.push({ key: DestinationEnum.SUB_PAGE_7, value: this.translateService.instant('timetable-editor.buttons.sub7') });
    }
    if (displayTemplate.idSubPage8) {
      buttonsPreview.push({ key: DestinationEnum.SUB_PAGE_8, value: this.translateService.instant('timetable-editor.buttons.sub8') });
    }
    if (displayTemplate.idSubPage9) {
      buttonsPreview.push({ key: DestinationEnum.SUB_PAGE_9, value: this.translateService.instant('timetable-editor.buttons.sub9') });
    }
    if (displayTemplate.idEmergencyPage) {
      buttonsPreview.push({ key: DestinationEnum.EMERGENCY, value: this.translateService.instant('timetable-editor.buttons.emergency') });
    }
    return buttonsPreview;
  }

  /**
   * clear all time out
   * @param timeouts
   */
  private clearTimeoutDisplay(timeouts: any[]): void {
    for (let i = 0; i < timeouts.length; i++) {
      clearTimeout(timeouts[i]);
    }
    timeouts = [];
  }

  /**
   * preview display template
   * @param isOn
   */
  private previewTemplate(isOn?: boolean): void {
    if (!isOn) {
      this.isPlay = false;
      this.dataService.sendData([this.IS_PREVIEW_ON_TT, this.isPlay]);
    }
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.clearTimeoutDisplay(this.timeoutsDisplay2);
    this.drawTimetableService.clearTimeoutsStopDurationArea(true, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.panzoomDisplay1 = undefined;
    this.panzoomDisplay2 = undefined;

    // preview template
    this.drawDisplay(
      this.canvasDisplay1Id,
      this.divContainCanvas1,
      _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
      false
    );

    if (this.isDisplay2) {
      this.drawDisplay(
        this.canvasDisplay2Id,
        this.divContainCanvas2,
        _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
        true
      );
    }

    //
    this.drawTimetableService.changeStatePlayPause(this.isPlay, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.drawTimetableService.changeStatePlayPause(this.isPlay, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
  }

  /**
   * draw display template
   * @param canvasDisplayId string (canvasDisplay1 or canvasDisplay2)
   * @param display Template
   * @param canvasDisplay
   * @param isDisplay2
   */
  private drawDisplay(canvasDisplayId: string, canvasDisplay, display: Template, isDisplay2: boolean): void {
    this.changeDetectorRef.detectChanges();
    const canvasDisplayNode = isDisplay2 ? this.divContainCanvas2?.nativeElement : this.divContainCanvas1?.nativeElement;
    // clear node child
    Helper.clearNodeChild(canvasDisplayNode);
    // get area timetable and index word, first is
    let areaTimetableAndIndexWord = Helper.getAllAreaReferenceTimetableAndIndexWord(display);
    this.drawTimetableService.clearIntervalsClock(canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    // clear all thread draw
    if (!isDisplay2) {
      this.subscribesGetDataExternal[DisplaysEnum.DISPLAY_1]?.unsubscribe();
      this.drawTimetableService.clearAllIntervalDrawsNewsDisplay1();
      this.drawTimetableService.clearAllThreadDrawTemplate(
        _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
        this.canvasDisplay1Id,
        ScreenCanvasIdEnum.TIMETABLE_EDITOR
      );
      this.areasTimetableDisplay1 = areaTimetableAndIndexWord[this.TIMETABLE_INDEX];
      this.areasIndexWordDisplay1 = areaTimetableAndIndexWord[this.INDEX_WORD_INDEX];
    } else {
      this.subscribesGetDataExternal[DisplaysEnum.DISPLAY_2]?.unsubscribe();
      this.drawTimetableService.clearAllIntervalDrawsNewsDisplay2();
      this.drawTimetableService.clearAllThreadDrawTemplate(
        _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
        this.canvasDisplay2Id,
        ScreenCanvasIdEnum.TIMETABLE_EDITOR
      );
      this.areasTimetableDisplay2 = areaTimetableAndIndexWord[this.TIMETABLE_INDEX];
      this.areasIndexWordDisplay2 = areaTimetableAndIndexWord[this.INDEX_WORD_INDEX];
    }
    if (!display) {
      return;
    }
    if (canvasDisplayNode) {
      // draw
      this.drawTimetableService.createCanvasTemplate(display, canvasDisplay, this.renderer, isDisplay2);
      this.drawTimetableService.createAllCanvasAreaTemplate(
        display,
        canvasDisplay,
        this.renderer,
        isDisplay2,
        ScreenCanvasIdEnum.TIMETABLE_EDITOR
      );
      let divContainCanvas;
      let divPreviewDisplay;
      if (isDisplay2) {
        divContainCanvas = this.divContainCanvas2;
        divPreviewDisplay = this.divPreviewDisplay2;
      } else {
        divContainCanvas = this.divContainCanvas1;
        divPreviewDisplay = this.divPreviewDisplay1;
      }
      this.calculateScaleTransformCanvas(display, divContainCanvas, divPreviewDisplay, isDisplay2);
      if (this.isPan) {
        this.renderer.setStyle(divContainCanvas.nativeElement, 'cursor', 'move');
      } else {
        this.renderer.setStyle(divContainCanvas.nativeElement, 'cursor', 'default');
      }

      let timetableSelectedClone = _.cloneDeep(this.timetableSelected);
      timetableSelectedClone.timetableUrlDetails = this.timetableDetailURLAreaClone;
      this.drawTimetableService.setupPreview(timetableSelectedClone, this.mediaSetting ?? undefined);
      let timetableSchedule = this.timetableSelected?.timetableSchedule;
      this.drawTimetableService.setAreaSwitchingTiming(this.areaSwitchingTiming);
      this.drawTimetableService.resetData(this.templateOld, canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
      this.drawTimetableService.setDataTimetables(this.currentIndexTimetableSchedule, this.referencePositionColumnsByTemplate);
      this.templateOld = undefined;
      if (timetableSchedule?.itemDetails) {
        let areasTimetable = !isDisplay2 ? _.cloneDeep(this.areasTimetableDisplay1) : _.cloneDeep(this.areasTimetableDisplay2);
        if (areasTimetable?.length) {
          // draw time table
          this.drawTimetableService.setDataTimetables(this.currentIndexTimetableSchedule, this.referencePositionColumnsByTemplate);
          this.drawTimetableService.setDataPreviewTimetableEditor(timetableSchedule);
        }
        let areasIndexWord = !isDisplay2 ? this.areasIndexWordDisplay1 : this.areasIndexWordDisplay2;
        if (areasIndexWord?.length) {
          // draw index word
          this.indexWordService.getIndexWordsByNameAndGroupId(this.getParamForGetIndexWord(areasIndexWord)).subscribe(indexWords => {
            Helper.setDataIndexWordForAreas(areasIndexWord, indexWords);
            this.drawTimetableService.setDataPreviewTimetableEditor(timetableSchedule, null, null, areasIndexWord);
            this.drawTimetableService.drawPreview(display, this.renderer, canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
            this.drawTimetableService.changeStatePlayPause(this.isPlay, canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
          });
        } else {
          this.drawTimetableService.drawPreview(display, this.renderer, canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
        }
      } else {
        this.drawTimetableService.drawPreview(display, this.renderer, canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
      }
    }
    if (this.isPlay) {
      this.drawDisplayDestinationIndex(display, canvasDisplayId);
    }
    let destination = (display.templateType as unknown) as DestinationEnum;
    this.getFullDataExternalContentToPreview(canvasDisplayId, this.getDataExternalSetting(destination, canvasDisplayId));
  }

  /**
   * get list name index word and list group index word
   * @param areas
   * @returns list name index word and list group index word
   */
  private getParamForGetIndexWord(areas: Area[]): any {
    let listNameIndexWord = [];
    let listGroupId = [];
    if (!this.referencePositionColumnsByTemplate?.length) {
      return [listGroupId, listNameIndexWord];
    }
    areas.forEach(area => {
      let nameIndexWord = Helper.getNameIndexWordFromSchedule(
        this.timetableSelected,
        area,
        this.currentIndexTimetableSchedule,
        this.referencePositionColumnsByTemplate.findIndex(reference => area.referencePositionColumn + 1 === reference)
      );
      listGroupId.push(area.indexWordGroupId);
      listNameIndexWord.push(nameIndexWord);
    });
    return [listGroupId, listNameIndexWord];
  }

  /**
   * get data external content of timetable
   * @param destinationEnum type of template
   * @param canvasDisplayId id area draw
   * @returns data external content of timetable
   */
  private getDataExternalSetting(destinationEnum: DestinationEnum, canvasDisplayId: string): DataExternalSetting[] {
    if (canvasDisplayId == this.canvasDisplay1Id) {
      switch (destinationEnum) {
        case DestinationEnum.MAIN:
          return this.timetableSelected.displayTemplate1.externalContentMainPage;
        case DestinationEnum.SUB_PAGE_1:
          return this.timetableSelected.displayTemplate1.externalContentPage1;
        case DestinationEnum.SUB_PAGE_2:
          return this.timetableSelected.displayTemplate1.externalContentPage2;
        case DestinationEnum.SUB_PAGE_3:
          return this.timetableSelected.displayTemplate1.externalContentPage3;
        case DestinationEnum.SUB_PAGE_4:
          return this.timetableSelected.displayTemplate1.externalContentPage4;
        case DestinationEnum.SUB_PAGE_5:
          return this.timetableSelected.displayTemplate1.externalContentPage5;
        case DestinationEnum.SUB_PAGE_6:
          return this.timetableSelected.displayTemplate1.externalContentPage6;
        case DestinationEnum.SUB_PAGE_7:
          return this.timetableSelected.displayTemplate1.externalContentPage7;
        case DestinationEnum.SUB_PAGE_8:
          return this.timetableSelected.displayTemplate1.externalContentPage8;
        case DestinationEnum.SUB_PAGE_9:
          return this.timetableSelected.displayTemplate1.externalContentPage9;
        case DestinationEnum.EMERGENCY:
          return this.timetableSelected.displayTemplate1.externalContentEmergencyPage;
        default:
          return null;
      }
    } else {
      switch (destinationEnum) {
        case DestinationEnum.MAIN:
          return this.timetableSelected.displayTemplate2.externalContentMainPage;
        case DestinationEnum.SUB_PAGE_1:
          return this.timetableSelected.displayTemplate2.externalContentPage1;
        case DestinationEnum.SUB_PAGE_2:
          return this.timetableSelected.displayTemplate2.externalContentPage2;
        case DestinationEnum.SUB_PAGE_3:
          return this.timetableSelected.displayTemplate2.externalContentPage3;
        case DestinationEnum.SUB_PAGE_4:
          return this.timetableSelected.displayTemplate2.externalContentPage4;
        case DestinationEnum.SUB_PAGE_5:
          return this.timetableSelected.displayTemplate2.externalContentPage5;
        case DestinationEnum.SUB_PAGE_6:
          return this.timetableSelected.displayTemplate2.externalContentPage6;
        case DestinationEnum.SUB_PAGE_7:
          return this.timetableSelected.displayTemplate2.externalContentPage7;
        case DestinationEnum.SUB_PAGE_8:
          return this.timetableSelected.displayTemplate2.externalContentPage8;
        case DestinationEnum.SUB_PAGE_9:
          return this.timetableSelected.displayTemplate2.externalContentPage9;
        case DestinationEnum.EMERGENCY:
          return this.timetableSelected.displayTemplate2.externalContentEmergencyPage;
        default:
          return null;
      }
    }
  }

  /**
   * calculate scale transform canvas
   *
   * @param template
   * @param divContainCanvas
   * @param divPreview
   * @param isDisplay2
   */
  private calculateScaleTransformCanvas(template: Template, divContainCanvas: any, divPreview: ElementRef, isDisplay2: boolean): void {
    if (!template) {
      return;
    }
    this.changeDetectorRef.detectChanges();
    const maxHeight = divPreview.nativeElement.clientHeight - 125;
    const maxWidth = divPreview.nativeElement.clientWidth - 24;
    let scaleTransform = { scaleX: 1, scaleY: 1 };
    if (template.width > maxWidth) {
      scaleTransform.scaleX = maxWidth / template.width;
    }
    if (template.height > maxHeight) {
      scaleTransform.scaleY = maxHeight / template.height;
    }
    const scale = Math.min(scaleTransform.scaleX, scaleTransform.scaleY);
    this.renderer.setStyle(divContainCanvas.nativeElement, 'transform', 'scale(' + scale + ')');
    const realHeightTemplate = template.height * scale;
    const realWidthTemplate = template.width * scale;
    const height = (maxHeight - realHeightTemplate) / 2;
    const width = (maxWidth - realWidthTemplate) / 2;
    divContainCanvas.nativeElement.style.marginTop = height + 'px';
    divContainCanvas.nativeElement.style.marginLeft = width + 'px';
    if (!isDisplay2) {
      if (this.panzoomDisplay1) {
        this.panzoomDisplay1.reset({
          startScale: scale,
          minScale: 0.1,
          maxScale: 2,
          startX: 0,
          startY: 0
        });
      } else {
        this.panzoomDisplay1 = Panzoom(this.divContainCanvas1?.nativeElement, { startScale: scale, minScale: 0.1, maxScale: 2 });
      }
      this.panzoomDisplay1.setOptions({ disablePan: !this.isPan, disableZoom: !this.isZoom, force: true });
    } else if (this.isDisplay2) {
      if (this.panzoomDisplay2) {
        this.panzoomDisplay2.reset({
          startScale: scale,
          minScale: 0.1,
          maxScale: 2,
          startX: 0,
          startY: 0
        });
      } else {
        this.panzoomDisplay2 = Panzoom(this.divContainCanvas2?.nativeElement, { startScale: scale, minScale: 0.1, maxScale: 2 });
      }
      this.panzoomDisplay2.setOptions({ disablePan: !this.isPan, disableZoom: !this.isZoom, force: true });
    }
  }

  /**
   * change state preview
   */
  public changeStatePreview(): void {
    if (this.timetableSelected?.isEdit) {
      return;
    }
    this.isPlay = !this.isPlay;

    this.drawTimetableService.changeStatePlayPause(this.isPlay, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    if (this.isDisplay2) {
      this.drawTimetableService.changeStatePlayPause(this.isPlay, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    }
    this.drawTimetableService.changeStartState(false, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.dataService.sendData([this.IS_PREVIEW_ON_TT, this.isPlay]);
    this.changeDetectorRef.detectChanges();
    if (this.isPlay) {
      if (this.isChangedData) {
        this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
        this.previewTemplate(true);
      } else {
        this.drawDisplayDestinationIndex(
          _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
          this.canvasDisplay1Id
        );
        if (this.isDisplay2) {
          this.drawDisplayDestinationIndex(
            _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
            this.canvasDisplay2Id
          );
        }
      }
    } else {
      // clear time out
      this.clearTimeoutDisplay(this.timeoutsDisplay1);
      this.clearTimeoutDisplay(this.timeoutsDisplay2);
    }
  }

  /**
   * draw display destination index (isAutoTransition)
   * @param display Template object
   * @param canvasDisplayId
   */
  private drawDisplayDestinationIndex(display: Template, canvasDisplayId): void {
    if (!display?.isAutoTransition) {
      return;
    }
    let isCanvasDisplay1: boolean = canvasDisplayId == this.canvasDisplay1Id;
    let destinationIndex = display.destination;
    let timeout = setTimeout(() => {
      let display1Template = _.get(this.timetableSelected?.templateDisplay1s, `[${destinationIndex}]`, undefined);
      let display2Template = _.get(this.timetableSelected?.templateDisplay2s, `[${destinationIndex}]`, undefined);
      if ((isCanvasDisplay1 && display1Template) || (!isCanvasDisplay1 && display2Template)) {
        if (isCanvasDisplay1) {
          // clear interval and time out list
          this.drawTimetableService.clearAllThreadDrawTemplate(
            _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
            this.canvasDisplay1Id,
            ScreenCanvasIdEnum.TIMETABLE_EDITOR
          );
        } else {
          this.drawTimetableService.clearAllThreadDrawTemplate(
            _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
            this.canvasDisplay2Id,
            ScreenCanvasIdEnum.TIMETABLE_EDITOR
          );
        }
        this.drawTimetableService.clearTimeoutsStopDurationArea(true, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
        this.drawTimetableService.changeStartState(false, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
        // draw display
        this.drawDisplay(
          isCanvasDisplay1 ? this.canvasDisplay1Id : this.canvasDisplay2Id,
          isCanvasDisplay1 ? this.divContainCanvas1 : this.divContainCanvas2,
          isCanvasDisplay1 ? display1Template : display2Template,
          !isCanvasDisplay1
        );
        this.templateSelectedTypeDisplay1 = isCanvasDisplay1 ? destinationIndex : this.templateSelectedTypeDisplay1;
        this.templateSelectedTypeDisplay2 = !isCanvasDisplay1 ? destinationIndex : this.templateSelectedTypeDisplay2;
        this.drawTimetableService.changeStatePlayPause(this.isPlay, canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
      }
    }, display.transitionTime * 1000);
    if (isCanvasDisplay1) {
      this.timeoutsDisplay1.push(timeout);
    } else {
      this.timeoutsDisplay2.push(timeout);
    }
  }

  /**
   * get full data picture to draw
   * @param canvasDisplayId id area draw
   * @param dataExternalSettings list data external setting(id area, id external content)
   * @returns
   */
  private async getFullDataExternalContentToPreview(canvasDisplayId: string, dataExternalSettings: DataExternalSetting[]) {
    if (!dataExternalSettings) {
      return;
    }
    // get full data to draw
    let display =
      canvasDisplayId == this.canvasDisplay1Id
        ? _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined)
        : _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined);

    let subscription = this.pictureAreaService.getFullDataPictureAreaExternalContent(dataExternalSettings).subscribe(async dataResponse => {
      this.drawTimetableService.setDataPreviewTimetableEditor(null, dataResponse, this.pictureAreaService);
      let areaExternalContent = Helper.getAllAreaReferenceExternalContent(display);
      let areaExternalContentOn = Helper.getAreasDisplayOnPreview(areaExternalContent, display, this.areaSwitchingTiming);
      this.drawTimetableService.drawExternalContent(
        canvasDisplayId,
        dataResponse,
        display,
        this.pictureAreaService,
        ScreenCanvasIdEnum.TIMETABLE_EDITOR,
        areaExternalContentOn
      );
    });
    // set intervals and subscription for display drawing
    if (canvasDisplayId !== this.canvasDisplay1Id) {
      this.subscribesGetDataExternal[DisplaysEnum.DISPLAY_2] = subscription;
    } else {
      this.subscribesGetDataExternal[DisplaysEnum.DISPLAY_1] = subscription;
    }
  }

  /**
   * change display
   */
  public changeDisplay(): void {
    this.isDisplay2 = !this.isDisplay2;
    this.commonObject.isDisplay2Timetable = this.isDisplay2;
    Helper.saveMainStateAction(this.store, this.commonObject);
    this.panzoomDisplay1 = undefined;
    this.panzoomDisplay2 = undefined;
    // check active area
    this.checkActiveArea();
    this.drawTimetableService.clearAllThreadDrawTemplate(
      _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
      this.canvasDisplay1Id,
      ScreenCanvasIdEnum.TIMETABLE_EDITOR
    );
    this.drawTimetableService.clearAllThreadDrawTemplate(
      _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
      this.canvasDisplay2Id,
      ScreenCanvasIdEnum.TIMETABLE_EDITOR
    );
    // clear thread draw external content
    this.drawTimetableService.clearAllIntervalDrawsNewsDisplay1();
    this.drawTimetableService.clearAllIntervalDrawsNewsDisplay2();
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.clearTimeoutDisplay(this.timeoutsDisplay2);
    this.subscribesGetDataExternal.forEach(subscription => subscription?.unsubscribe());
    this.pausePreview();
    // preview
    this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.previewTemplate(this.isPlay);
  }

  /**
   * pause preview
   */
  private pausePreview(): void {
    this.isPlay = false;
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.drawTimetableService.pausePreview(this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    if (this.isDisplay2) {
      this.clearTimeoutDisplay(this.timeoutsDisplay2);
      this.drawTimetableService.pausePreview(this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    }
  }

  /**
   * Set free area
   */
  public setFreeArea(): void {
    if (this.isShowFreeArea) {
      return;
    }
    if (!this.timetableSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    } else if (!this.timetableSelected.templateDisplay1s && !this.timetableSelected.templateDisplay2s) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.no-template')
        }
      });
      return;
    }
    this.isShowFreeArea = true;
    this.dataService.sendData([this.IS_SHOW_FREE_AREA, true]);
  }

  /**
   * Set url area
   */
  public setURL(): void {
    if (this.isShowURLArea) {
      return;
    }
    if (!this.timetableSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    } else if (!this.timetableSelected.templateDisplay1s && !this.timetableSelected.templateDisplay2s) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.no-template')
        }
      });
      return;
    }
    this.isShowURLArea = true;
    this.dataService.sendData([this.IS_SHOW_URL_AREA, true]);
  }

  /**
   * save content days
   */
  private saveContentDays(): void {
    if (!this.selectedDeviceCalendar) {
      return;
    }
    this.timetableContentDayService
      .updateContentDaysOfDevice(
        Helper.convertDataTimetableContentDayBackward(this.selectedDeviceCalendar.contentDays, this.commonObject.setting),
        this.selectedDeviceCalendar.id,
        this.isUnlimited
      )
      .subscribe(
        () => {
          this.toast.success(this.translateService.instant('common.save-success'), '');
          this.saveDataSuccess.emit(true);
        },
        error => {
          Helper.handleError(error, this.translateService, this.dialogService);
          this.saveDataSuccess.emit(false);
        }
      );
  }

  /**
   * preview display 1
   * @param item
   */
  public previewDisplay1(item: any): void {
    if (this.isPlay || this.timetableSelected?.isEdit) {
      return;
    }
    this.templateOld = _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined);
    this.templateSelectedTypeDisplay1 = item.key;
    this.drawTimetableService.clearTimeoutsStopDurationDisplay1(ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    // clear all thread template display 1
    this.drawTimetableService.clearAllThreadDrawTemplate(
      _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
      this.canvasDisplay1Id,
      ScreenCanvasIdEnum.TIMETABLE_EDITOR
    );
    this.changeDetectorRef.detectChanges();
    // preview template
    this.drawDisplay(
      this.canvasDisplay1Id,
      this.divContainCanvas1,
      _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
      false
    );
  }

  /**
   * preview display 2
   * @param item
   * @returns
   */
  public previewDisplay2(item: any): void {
    if (this.isPlay || this.timetableSelected?.isEdit) {
      return;
    }
    this.templateOld = _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined);
    this.templateSelectedTypeDisplay2 = item.key;
    this.drawTimetableService.clearTimeoutsStopDurationDisplay2(ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.clearTimeoutDisplay(this.timeoutsDisplay2);
    // clear all thread template display 2
    this.drawTimetableService.clearAllThreadDrawTemplate(
      _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
      this.canvasDisplay2Id,
      ScreenCanvasIdEnum.TIMETABLE_EDITOR
    );
    this.changeDetectorRef.detectChanges();
    // preview template
    this.drawDisplay(
      this.canvasDisplay2Id,
      this.divContainCanvas2,
      _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
      true
    );
  }

  /**
   * handle event click area on canvas
   * @param canvasDisplay
   * @param canvasDisplayId
   * @param displayTemplates
   * @param destinationDisplayData
   */
  private handleEventClickAreaOnCanvas(
    canvasDisplay: any,
    canvasDisplayId: string,
    displayTemplates: Template[],
    destinationDisplayData: DestinationEnum
  ): void {
    if (displayTemplates && !displayTemplates[destinationDisplayData]) {
      return;
    }
    this.drawTimetableService.clearTimeoutsStopDurationArea(true, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.drawTimetableService.changeStartState(false, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    this.drawDisplay(canvasDisplayId, canvasDisplay, displayTemplates[destinationDisplayData], canvasDisplayId !== this.canvasDisplay1Id);
    if (canvasDisplayId == this.canvasDisplay1Id) {
      this.templateSelectedTypeDisplay1 = destinationDisplayData;
    } else {
      this.templateSelectedTypeDisplay2 = destinationDisplayData;
    }
    this.drawTimetableService.changeStatePlayPause(this.isPlay, canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
  }

  /**
   * setting channel area preview
   */
  public signageChannelAreaPreview(): void {
    if (this.isPlay || this.timetableSelected?.isEdit || !this.isSchedule) {
      return;
    }
    // get setting signage channel by project's id
    this.settingSignageChannelService.getSettingSignageChannelByType(SettingType.TIMETABLE).subscribe(
      settingSignageChannel => {
        this.isChangedData = true;
        this.dialogService.showDialog(
          DialogSettingSignageDisplayComponent,
          {
            data: {
              settingSignageChannel: <SettingSignageChannel>settingSignageChannel ?? new SettingSignageChannel(),
              type: SettingType.TIMETABLE
            }
          },
          result => {
            this.isChangedData = false;
            if (result) {
              let data = result;
              if (!data || (!data.folderId && !data.mediaId)) {
                this.mediaSetting = undefined;
                // clear canvas display 1
                this.drawTimetableService.clearCanvasAreas(
                  _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
                  this.canvasDisplay1Id
                );
                // clear canvas display 2
                if (this.isDisplay2) {
                  this.drawTimetableService.clearCanvasAreas(
                    _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
                    this.canvasDisplay2Id
                  );
                }
                return;
              }
              // get media by id
              this.simpleMediaService.getMediaById(data.mediaId).subscribe(
                async mediaData => {
                  if (!mediaData) {
                    this.mediaSetting = undefined;
                    return;
                  }
                  this.mediaSetting = Helper.convertSimpleMediaToMedia(Helper.convertDataSimpleMedia(mediaData, false));
                  let timetableSelectedClone = _.cloneDeep(this.timetableSelected);
                  timetableSelectedClone.timetableUrlDetails = this.timetableDetailURLAreaClone;
                  this.drawTimetableService.setupPreview(timetableSelectedClone, this.mediaSetting ?? undefined);
                  // draw area is set(signage channel) display 1
                  this.drawTimetableService.drawAreasSignageChannel(
                    _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
                    this.renderer,
                    this.canvasDisplay1Id,
                    ScreenCanvasIdEnum.TIMETABLE_EDITOR
                  );
                  // draw area is set(signage channel) display 2
                  if (this.isDisplay2) {
                    this.drawTimetableService.drawAreasSignageChannel(
                      _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
                      this.renderer,
                      this.canvasDisplay2Id,
                      ScreenCanvasIdEnum.TIMETABLE_EDITOR
                    );
                  }
                },
                error => Helper.handleError(error, this.translateService, this.dialogService)
              );
            }
          }
        );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * edit item name
   *
   * @returns
   */
  private editItemName(): void {
    if (!this.timetableSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    } else if (!this.timetableSelected.displayTemplate1) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.please-set-template')
        }
      });
      return;
    } else if (this.referencePositionColumnsByTemplate.length < 2) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.no-area-timetable')
        }
      });
      return;
    } else if (this.isShowFreeArea) {
      return;
    }
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogEditHeaderComponent,
      { data: [_.cloneDeep(this.timetableSelected.timetableSchedule), this.referencePositionColumnsByTemplate, ScreenNameEnum.TIME_TABLE] },
      result => {
        this.isChangedData = false;
        if (result) {
          let saveData = new TimetableSchedule();
          saveData.timetableId = this.timetableSelected.id;
          saveData.itemNames = JSON.stringify(result);
          saveData.isChangeScheduleHeader = true;
          this.timetableScheduleService.save(saveData).subscribe(data => {
            if (data) {
              this.timetableSelected.timetableSchedule.headers = JSON.parse(data.itemNames);
              Helper.updateLanguageHeaders(this.timetableSelected.timetableSchedule.headers, this.translateService);
              this.multiLanguageTooltip();
            }
          });
        }
      }
    );
  }

  /**
   * choose tool
   *
   * @param tool
   */
  public chooseTool(tool: PreviewToolEnum): void {
    if (!this.timetableSelected.displayTemplate1) {
      return;
    }
    if (tool == PreviewToolEnum.PAN) {
      this.isPan = !this.isPan;
      this.renderer.setStyle(this.divContainCanvas1?.nativeElement, 'cursor', this.isPan ? 'move' : 'default');
      if (this.isDisplay2) {
        this.renderer.setStyle(this.divContainCanvas2?.nativeElement, 'cursor', this.isPan ? 'move' : 'default');
      }
    } else {
      this.isZoom = !this.isZoom;
      this.renderer.setStyle(this.divContainCanvas1?.nativeElement, 'cursor', this.isPan ? 'move' : 'default');
      if (this.isDisplay2) {
        this.renderer.setStyle(this.divContainCanvas2?.nativeElement, 'cursor', this.isPan ? 'move' : 'default');
      }
    }
    if (this.isZoom && this.isPan) {
      this.renderer.setStyle(this.divContainCanvas1?.nativeElement, 'cursor', 'move');
      if (this.isDisplay2) {
        this.renderer.setStyle(this.divContainCanvas2?.nativeElement, 'cursor', 'move');
      }
    }
    this.panzoomDisplay1.setOptions({ disablePan: !this.isPan, disableZoom: !this.isZoom, force: true });
    if (this.isDisplay2) {
      this.panzoomDisplay2.setOptions({ disablePan: !this.isPan, disableZoom: !this.isZoom, force: true });
    }
  }

  /**
   * subscribe event mouse wheel
   *
   * @param e
   * @returns
   */
  @HostListener('mousewheel', ['$event'])
  mouseWheel(e) {
    if (!this.isZoom) {
      return;
    }
    if (e.target.id.includes('timetable-previewCanvas1')) {
      this.panzoomDisplay1.zoomWithWheel(e);
    }
    if (e.target.id.includes('timetable-previewCanvas2')) {
      this.panzoomDisplay2.zoomWithWheel(e);
    }
  }

  /**
   * delivery
   * @returns
   */
  private delivery(): void {
    // return if choose tab schedule
    if (this.isSchedule) {
      return;
    }
    // open popup
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogDeliveryTimetableComponent,
      {
        data: {
          groupDevices: _.cloneDeep(this.groupDevices)
        }
      },
      result => {
        this.isChangedData = false;
      }
    );
  }

  /**
   * clear all thread drawing when change timetable
   */
  private clearThreadDrawing(): void {
    // clear all thread draw template
    if (this.timetableSelected?.templateDisplay1s) {
      Helper.clearNodeChild(this.divContainCanvas1.nativeElement);
      this.subscribesGetDataExternal[DisplaysEnum.DISPLAY_1]?.unsubscribe();
      this.drawTimetableService.clearAllIntervalDrawsNewsDisplay1();
      this.drawTimetableService.clearAllThreadDrawTemplate(
        _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
        this.canvasDisplay1Id,
        ScreenCanvasIdEnum.TIMETABLE_EDITOR
      );
    }
    if (this.isDisplay2 && this.timetableSelected?.templateDisplay2s) {
      Helper.clearNodeChild(this.divContainCanvas2.nativeElement);
      this.subscribesGetDataExternal[DisplaysEnum.DISPLAY_2]?.unsubscribe();
      this.drawTimetableService.clearAllIntervalDrawsNewsDisplay2();
      this.drawTimetableService.clearAllThreadDrawTemplate(
        _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
        this.canvasDisplay2Id,
        ScreenCanvasIdEnum.TIMETABLE_EDITOR
      );
    }
  }

  /**
   * handle Error Save Timetable
   * @param error
   */
  private handleErrorSaveTimetable(error: any): void {
    let msg = this.translateService.instant('dialog-error.msg');
    if (error.error?.detail == Constant.ERROR_LIMIT_RECORD) {
      msg = this.translateService.instant('timetable-editor.limit-record-timetable');
    } else if (error.error?.detail == Constant.ERROR_EXISTS_NAME_TIMETABLE) {
      msg = this.translateService.instant('timetable-editor.duplicate-name');
    } else if (error.error?.detail == Constant.ERROR_EXISTS_SUFFIX_NO) {
      msg = this.translateService.instant('timetable-editor.duplicate-timetable');
    } else if (error.status == Constant.NETWORK_ERROR_CODE) {
      msg = this.translateService.instant('timetable-editor.error-network');
    }
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: msg
      }
    });
  }

  /**
   * Save timetable Detail
   * @param isPreview
   * @returns
   */
  public saveTimetableDetail(isPreview: boolean): Promise<void> {
    if (this.isPlay) {
      return;
    }
    const MAX_LENGTH_VALUE = 256;
    if (this.timetableSelected.timetableDetails.some(timetableDetail => timetableDetail?.text?.length > MAX_LENGTH_VALUE)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-message.timetable-editor.text-max-length')
        }
      });
      this.saveDataSuccess.emit(false);
      return;
    }
    // filter list style details exists areaDisplay 1 or areaDisplay 2
    let timetableDetails = this.timetableSelected?.timetableDetails?.filter(
      timetableDetail => timetableDetail?.areaDisplay1 || timetableDetail?.areaDisplay2
    );
    this.mediaService.uploadMediaFileForTimetableDetail(this.filesData, this.freeAreaTimetableMediaFiles).subscribe(
      freeAreas => {
        if (freeAreas) {
          timetableDetails.forEach(timetableDetail => {
            let index = freeAreas.findIndex(item => item.timetableDetailId == timetableDetail.id);
            if (index != -1) {
              timetableDetail.mediaId = freeAreas[index].media.id;
            }
          });
        }
        timetableDetails = timetableDetails.map(timetableDetail => Helper.convertDataDetailTimetableBackward(timetableDetail));
        this.timetableDetailService.saveTimetableDetails(this.timetableSelected?.id, timetableDetails).subscribe(
          () => {
            this.saveDataSuccess.emit(true);
            this.timetableDetailsClone = _.cloneDeep(this.timetableSelected.timetableDetails);
            // handle after close free area
            this.closeFreeAreaEdit(isPreview);
          },
          error => this.handleErrorSaveTimetable(error)
        );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * Reset data timetable detail
   */
  private resetDataTimetableDetail(): void {
    this.isChangedData = false;
    this.isShowFreeArea = false;
    this.filesData = [];
    this.freeAreaTimetableMediaFiles = new Array();
  }

  /**
   * Reset data timetable url detail
   */
  private resetDataTimetableUrlDetail(): void {
    this.isChangedData = false;
    this.isShowURLArea = false;
    this.filesData = [];
  }

  /**
   * Save timetable url Detail
   * @param isPreview
   * @returns
   */
  public saveTimetableUrlDetail(isPreview: boolean): Promise<void> {
    if (!this.validateActionPreview()) {
      return;
    }
    // filter list style details exists areaDisplay 1 or areaDisplay 2
    let timetableDetails = this.timetableSelected?.timetableUrlDetails?.filter(
      timetableDetail => timetableDetail?.areaDisplay1 || timetableDetail?.areaDisplay2
    );
    timetableDetails = timetableDetails.map(timetableDetail => Helper.convertDataUrlDetailTimetableBackward(timetableDetail));
    this.timetableDetailURLService.saveTimetableDetailURLs(this.timetableSelected?.id, timetableDetails).subscribe(
      data => {
        this.saveDataSuccess.emit(true);
        this.timetableSelected.timetableUrlDetails = Helper.convertTimetableUrlDetails(data);
        this.timetableDetailURLAreaClone = _.cloneDeep(this.timetableSelected.timetableUrlDetails);
        // set template type for url area display 1
        this.setTemplateTypeForUrlArea(this.urlAreasDisplay1, this.AREA_DISPLAY_1);
        // set template type for url area display 12
        this.setTemplateTypeForUrlArea(this.urlAreasDisplay2, this.AREA_DISPLAY_2);

        const timetableUrlDetailsDisplay1 = this.timetableSelected.timetableUrlDetails
          .filter(timetableDetail => timetableDetail?.areaDisplay1Id)
          .sort((timetableDetail1, timetableDetail2) => {
            return (
              timetableDetail1?.areaDisplay1?.templateType - timetableDetail2?.areaDisplay1?.templateType ||
              (timetableDetail1?.areaDisplay1Id as number) - (timetableDetail2?.areaDisplay1Id as number)
            );
          });
        // sort by id area and template type display 2
        const timetableUrlDetailsDisplay2 = this.timetableSelected.timetableUrlDetails
          .filter(timetableDetail => !timetableDetail?.areaDisplay1Id)
          .sort((timetableDetail1, timetableDetail2) => {
            return (
              timetableDetail1?.areaDisplay2?.templateType - timetableDetail2?.areaDisplay2?.templateType ||
              (timetableDetail1?.areaDisplay2Id as number) - (timetableDetail2?.areaDisplay2Id as number)
            );
          });

        this.timetableSelected.timetableUrlDetails = timetableUrlDetailsDisplay1.concat(timetableUrlDetailsDisplay2);
        if (this.timetableSelected?.timetableUrlDetails?.length == 0 && this.urlAreasDisplay2?.length > 0) {
          this.timetableSelected?.timetableUrlDetails.push(new TimetableDetailURLArea(this.timetableSelected.id));
        }

        this.timetableUrlDetailsClone = _.cloneDeep(this.timetableSelected.timetableUrlDetails);
        this.setNewTextUrl();
        this.redrawTimetableUrlDetail();
        // handle after close url area
        this.closeUrlAreaEdit(isPreview);
      },
      error => this.handleErrorSaveTimetable(error)
    );
  }

  /**
   * set Text Url after save
   */
  private setNewTextUrl() {
    this.timetableSelected.timetableUrlDetails?.forEach(e => {
      this.timetableSelected.templateDisplay1s[e.areaDisplay1?.templateType]?.layers.forEach(layer => {
        layer.areas.forEach(area => {
          if (area.id == e.areaDisplay1Id && area instanceof URLArea) {
            area.textURL = e.textUrl;
          }
        });
      });
      if (this.timetableSelected.templateDisplay2s) {
        this.timetableSelected.templateDisplay2s[e.areaDisplay2?.templateType]?.layers.forEach(layer => {
          layer.areas.forEach(area => {
            if (area.id == e.areaDisplay2Id && area instanceof URLArea) {
              area.textURL = e.textUrl;
            }
          });
        });
      }
    });
  }

  /**
   * close free area edit
   * @param isPreview
   */
  public closeFreeAreaEdit(isPreview: boolean): void {
    if (this.isPlay) {
      return;
    }
    this.resetDataTimetableDetail();
    this.timetableDetailSelected = undefined;
    this.timetableSelected.timetableDetails = _.cloneDeep(this.timetableDetailsClone);
    this.dataService.sendData([this.IS_SHOW_FREE_AREA, false]);
    this.dataService.sendData([this.IS_CLEAR_FIELD, false]);
    if (isPreview) {
      this.redrawTimetableDetail();
    }
  }

  /**
   * close url area edit
   * @param isPreview
   */
  public closeUrlAreaEdit(isPreview: boolean): void {
    if (!this.validateActionPreview()) {
      return;
    }
    this.resetDataTimetableUrlDetail();
    this.timetableDetailURLSelected = undefined;
    this.timetableSelected.timetableUrlDetails = _.cloneDeep(this.timetableUrlDetailsClone);
    this.dataService.sendData([this.IS_SHOW_URL_AREA, false]);
    this.dataService.sendData([this.IS_CLEAR_FIELD, false]);
    this.checkActiveUrlArea();
  }

  /**
   * Redraw timetable detail
   */
  public redrawTimetableDetail(): void {
    if (this.isPlay) {
      return;
    }
    let timetableSelectedClone = _.cloneDeep(this.timetableSelected);
    timetableSelectedClone.timetableUrlDetails = this.timetableDetailURLAreaClone;
    this.drawTimetableService.setupPreview(timetableSelectedClone, this.mediaSetting ?? undefined);
    this.drawTimetableService.clearTimeoutsStopDurationArea(false, ScreenCanvasIdEnum.TIMETABLE_EDITOR, [LinkDataTextEnum.FREE_TEXT]);
    this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    const areaDisplay1 = this.getAllAreasTimetableDetail(DisplaysEnum.DISPLAY_1);
    this.drawTimetableService.clearAreas(areaDisplay1, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    let display1 = _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined);
    this.drawFreeAreasForDisplay(display1, areaDisplay1, this.canvasDisplay1Id);
    if (this.isDisplay2) {
      const areaDisplay2 = this.getAllAreasTimetableDetail(DisplaysEnum.DISPLAY_2);
      this.drawTimetableService.clearAreas(areaDisplay2, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
      let display2 = _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined);
      this.drawFreeAreasForDisplay(display2, areaDisplay2, this.canvasDisplay2Id);
    }
  }

  /**
   * Redraw timetable url detail
   */
  public redrawTimetableUrlDetail(): void {
    if (this.isPlay) {
      return;
    }
    let timetableSelectedClone = _.cloneDeep(this.timetableSelected);
    timetableSelectedClone.timetableUrlDetails = this.timetableDetailURLAreaClone;
    this.drawTimetableService.setupPreview(timetableSelectedClone, this.mediaSetting ?? undefined);
    this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    const areaDisplay1 = this.getAllAreasTimetableUrlDetail(DisplaysEnum.DISPLAY_1);
    this.drawTimetableService.clearAreas(areaDisplay1, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
    let display1 = _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined);
    this.drawUrlAreasForDisplay(display1, areaDisplay1, this.canvasDisplay1Id);
    if (this.isDisplay2) {
      const areaDisplay2 = this.getAllAreasTimetableUrlDetail(DisplaysEnum.DISPLAY_2);
      this.drawTimetableService.clearAreas(areaDisplay2, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_EDITOR);
      let display2 = _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined);
      this.drawUrlAreasForDisplay(display2, areaDisplay2, this.canvasDisplay2Id);
    }
  }

  /**
   * draw areas free area for display
   * @param display
   * @param areasFreeArea
   * @param canvasDisplayId
   */
  private drawFreeAreasForDisplay(display: Template, areasFreeArea: Area[], canvasDisplayId: string): void {
    let areasFreeAreaOff = Helper.getAreasOfLayerOnOff(areasFreeArea, display, this.areaSwitchingTiming);
    this.drawTimetableService.drawFreeArea(areasFreeAreaOff, this.renderer, canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_EDITOR, display);
    let areasFreeAreaOn = Helper.getAreasOfLayerOnOff(areasFreeArea, display, this.areaSwitchingTiming, true);
    this.drawTimetableService.drawFreeAreaOn(areasFreeAreaOn, this.renderer, canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_EDITOR, display);
  }

  /**
   * draw areas url area for display
   * @param display
   * @param areasFreeArea
   * @param canvasDisplayId
   */
  private drawUrlAreasForDisplay(display: Template, areasFreeArea: Area[], canvasDisplayId: string): void {
    let areasUrlAreaOff = Helper.getAreasOfLayerOnOff(areasFreeArea, display, this.areaSwitchingTiming);
    this.drawTimetableService.drawUrlArea(areasUrlAreaOff, this.renderer, canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_EDITOR, display);
    let areasUrlAreaOn = Helper.getAreasOfLayerOnOff(areasFreeArea, display, this.areaSwitchingTiming, true);
    this.drawTimetableService.drawUrlAreaOn(areasUrlAreaOn, this.renderer, canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_EDITOR, display);
  }

  /**
   * Get all area timetable detail
   *
   * @param displayEnum
   * @returns
   */
  private getAllAreasTimetableDetail(displayEnum: DisplaysEnum): Area[] {
    let areasDisplay1 = Helper.getAllAreaTemplate(this.timetableSelected.templateDisplay1s[this.templateSelectedTypeDisplay1]);
    let areasDisplay2;
    if (this.timetableSelected.templateDisplay2s) {
      areasDisplay2 = Helper.getAllAreaTemplate(this.timetableSelected.templateDisplay2s[this.templateSelectedTypeDisplay2]);
    }
    let freeArea = displayEnum == DisplaysEnum.DISPLAY_1 ? areasDisplay1 : areasDisplay2;
    if (!freeArea) {
      return [];
    }
    return freeArea.filter(
      area =>
        (area as TextArea).linkReferenceData == LinkDataTextEnum.FREE_TEXT ||
        (area as PictureArea).attribute == LinkDataPictureEnum.FREE_PICTURE
    );
  }

  /**
   * Get all area timetable detail
   *
   * @param displayEnum
   * @returns
   */
  private getAllAreasTimetableUrlDetail(displayEnum: DisplaysEnum): Area[] {
    let areasDisplay1 = Helper.getAllAreaTemplate(this.timetableSelected.templateDisplay1s[this.templateSelectedTypeDisplay1]);
    let areasDisplay2;
    if (this.timetableSelected.templateDisplay2s) {
      areasDisplay2 = Helper.getAllAreaTemplate(this.timetableSelected.templateDisplay2s[this.templateSelectedTypeDisplay2]);
    }
    let urlArea = displayEnum == DisplaysEnum.DISPLAY_1 ? areasDisplay1 : areasDisplay2;
    if (!urlArea) {
      return [];
    }
    return urlArea.filter(area => area.isURL);
  }

  /**
   * Select timetable detail
   *
   * @param timetableDetail
   */
  public selectTimetableDetail(timetableDetail: TimetableDetail): void {
    this.timetableDetailSelected = timetableDetail;
    this.dataService.sendData([this.IS_CLEAR_FIELD, true]);
  }

  /**
   * Select timetable url detail
   *
   * @param timetableDetail
   */
  public selectTimetableUrlDetail(timetableDetail: TimetableDetailURLArea): void {
    this.timetableDetailURLSelected = timetableDetail;
    this.dataService.sendData([this.IS_CLEAR_FIELD, true]);
  }

  /**
   * allow drop
   *
   * @param event
   */
  public allowDrop(event: DragEvent): void {
    if (this.isPlay || this.timetableSelected?.isEdit) {
      event.dataTransfer.effectAllowed = 'none';
      event.dataTransfer.dropEffect = 'none';
      return;
    }
    event.preventDefault();
  }

  /**
   *Drop media from pc
   *
   * @param file
   * @param timetableDetail
   */
  public async dropMediaFromPC(files: any, timetableDetail: TimetableDetail): Promise<void> {
    if (this.isPlay || this.timetableSelected?.isEdit) {
      return;
    }
    this.timetableDetailSelected = timetableDetail;
    this.dataService.sendData([this.IS_CLEAR_FIELD, true]);
    if (
      this.timetableDetailSelected?.areaDisplay1?.checkTypeTextArea() ||
      this.timetableDetailSelected?.areaDisplay2?.checkTypeTextArea() ||
      (!this.timetableDetailSelected?.areaDisplay1 && !this.timetableDetailSelected?.areaDisplay2)
    ) {
      return;
    }
    if (!Helper.isImageFile(files[Constant.FILE_MEDIA_OBJECT])) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('timetable-editor.invalid-file')
        }
      });
      return;
    }
    // handle file drop
    let fileDrop = files[0][0];
    const type = files[Constant.FILE_MEDIA_OBJECT][Constant.TYPE_ATTRIBUTE];
    const mediaName = this.getMediaFileName();
    // file is pdf
    if (type.toLowerCase() == TypeMediaFileEnum.PDF) {
      const numberOfPage = await this.mediaService
        .getNumberOfPagePdf(new File([fileDrop], mediaName))
        .toPromise()
        .catch(error => {
          Helper.handleError(error, this.translateService, this.dialogService);
          return 0;
        });
      if (numberOfPage > 1) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('timetable-editor.confirm-convert-file-pdf'),
              button1: this.translateService.instant('timetable-editor.yes'),
              button2: this.translateService.instant('timetable-editor.btn-no')
            }
          },
          result => {
            if (!result) {
              return;
            }
            this.handleAfterDropMedia(type, fileDrop, mediaName, files);
          }
        );
      } else if (numberOfPage == 1) {
        this.handleAfterDropMedia(type, fileDrop, mediaName, files);
      }
    } else {
      this.handleAfterDropMedia(type, fileDrop, mediaName, files);
    }
  }

  /**
   * handle After Drop Media
   * @param type
   * @param fileDrop
   * @param mediaName
   * @param files
   * @returns
   */
  private async handleAfterDropMedia(type: any, fileDrop: any, mediaName: any, files: any): Promise<void> {
    let fileFromPC: Media = null;
    // file is pdf
    if (type.toLowerCase() == TypeMediaFileEnum.PDF) {
      let isErrorFonts = await this.mediaService
        .checkFontsConvert(new File([fileDrop], `${mediaName}.${type}`))
        .toPromise()
        .catch(error => {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('index-word-editor.msg.title-error'),
              text: this.translateService.instant('lcd-layout-editor.msg.common-error')
            }
          });
          return null;
        });
      if (isErrorFonts) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('lcd-layout-editor.msg.fonts-error-convert-pdf'),
              button1: this.translateService.instant('lcd-layout-editor.yes'),
              button2: this.translateService.instant('lcd-layout-editor.no')
            },
            autoFocus: false
          },
          async result => {
            if (!result) {
              return;
            }
            fileFromPC = await this.mediaService
              .convertPDFToImage(new File([fileDrop], `${mediaName}.${type}`), FolderNameDropPDFEnum.FREE_AREA)
              .toPromise()
              .catch(error => {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: this.translateService.instant('dialog-error.title'),
                    text: this.translateService.instant('timetable-operation-manager.pdf-cannot-display')
                  }
                });
                return null;
              });

            if (fileFromPC == null) {
              return;
            }
            this.showImgFreeAreaAfterDrop(fileDrop, fileFromPC, files, type, mediaName);
          }
        );
      } else {
        fileFromPC = await this.mediaService
          .convertPDFToImage(new File([fileDrop], `${mediaName}.${type}`), FolderNameDropPDFEnum.FREE_AREA)
          .toPromise()
          .catch(error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: this.translateService.instant('timetable-operation-manager.pdf-cannot-display')
              }
            });
            return null;
          });

        if (fileFromPC == null) {
          return;
        }
        this.showImgFreeAreaAfterDrop(fileDrop, fileFromPC, files, type, mediaName);
      }
    } else {
      this.showImgFreeAreaAfterDrop(fileDrop, fileFromPC, files, type, mediaName, true);
    }
  }

  private async showImgFreeAreaAfterDrop(fileDrop, fileFromPC, files, type, mediaName, isCheck?) {
    let imageInfo = await this.getImageInformation(fileDrop);
    // validate unsupported file format
    if (isCheck && imageInfo[Constant.ERROR_ELEMENT]) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-error.unsupported-file-format')
        }
      });
      return;
    }
    // get url media to display in GUI
    let mediaFromPC: Media = new ImageTimetable();
    mediaFromPC.url = fileFromPC ? fileFromPC.url : files[Constant.FILE_MEDIA_OBJECT][Constant.URL_ATTRIBUTE];
    mediaFromPC.type = type;
    const pcFileName = `${mediaName}.${mediaFromPC.type}`;
    // new object media file
    let freeAreaTimetableMediaFile = new FreeAreaMediaFile(pcFileName, mediaName, this.timetableDetailSelected.id, mediaFromPC);
    // rename file
    const fileRenamed = new File([fileDrop], pcFileName);
    // put object to list
    let index = this.freeAreaTimetableMediaFiles?.findIndex(mediaFile => mediaFile.mediaName == freeAreaTimetableMediaFile.mediaName);
    if (index != -1) {
      this.freeAreaTimetableMediaFiles[index] = freeAreaTimetableMediaFile;
      this.filesData[index] = fileRenamed;
    } else {
      this.freeAreaTimetableMediaFiles.push(freeAreaTimetableMediaFile);
      this.filesData.push(fileRenamed);
    }
    mediaFromPC.name = freeAreaTimetableMediaFile.mediaName;
    this.timetableDetailSelected.media = mediaFromPC;
    this.isChangedData = true;
    this.redrawTimetableDetail();
  }
  /**
   * Get image information
   *
   * @param media
   * @returns
   */
  private getImageInformation(media: any): any {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const img = new Image();
          img.onload = () => {
            resolve({ width: img.width, height: img.height });
          };
          img.onerror = () => {
            resolve({ error: Constant.ERROR_ELEMENT });
          };
          img.src = fileReader.result as string;
        };
        fileReader.readAsDataURL(media);
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Get media file name
   *
   * @returns
   */
  private getMediaFileName(): string {
    const timetableId = `${this.timetableSelected.no}-${this.timetableSelected.suffix}`;
    const typeDisplay2 = this.getTemplateTypeForMediaName(this.timetableDetailSelected.areaDisplay2?.templateType);
    const typeDisplay1 = this.getTemplateTypeForMediaName(this.timetableDetailSelected.areaDisplay1?.templateType);
    return this.timetableDetailSelected.areaDisplay2 && !this.timetableDetailSelected.areaDisplay1
      ? `${timetableId}-D2-${typeDisplay2}-${this.timetableDetailSelected.areaDisplay2?.name}`
      : `${timetableId}-D1-${typeDisplay1}-${this.timetableDetailSelected.areaDisplay1?.name}`;
  }

  /**
   * Get template type for media name
   *
   * @param templateType
   * @returns
   */
  private getTemplateTypeForMediaName(templateType: TemplateTypeEnum): string {
    let type: string;
    switch (templateType) {
      case TemplateTypeEnum.MAIN:
        type = TemplateTypeFreeAreaEnum.MAIN;
        break;
      case TemplateTypeEnum.SUB_PAGE_1:
        type = TemplateTypeFreeAreaEnum.SUB1;
        break;
      case TemplateTypeEnum.SUB_PAGE_2:
        type = TemplateTypeFreeAreaEnum.SUB2;
        break;
      case TemplateTypeEnum.SUB_PAGE_3:
        type = TemplateTypeFreeAreaEnum.SUB3;
        break;
      case TemplateTypeEnum.SUB_PAGE_4:
        type = TemplateTypeFreeAreaEnum.SUB4;
        break;
      case TemplateTypeEnum.SUB_PAGE_5:
        type = TemplateTypeFreeAreaEnum.SUB5;
        break;
      case TemplateTypeEnum.SUB_PAGE_6:
        type = TemplateTypeFreeAreaEnum.SUB6;
        break;
      case TemplateTypeEnum.SUB_PAGE_7:
        type = TemplateTypeFreeAreaEnum.SUB7;
        break;
      case TemplateTypeEnum.SUB_PAGE_8:
        type = TemplateTypeFreeAreaEnum.SUB8;
        break;
      case TemplateTypeEnum.SUB_PAGE_9:
        type = TemplateTypeFreeAreaEnum.SUB9;
        break;
      case TemplateTypeEnum.EMERGENCY:
        type = TemplateTypeFreeAreaEnum.EMERGENCY;
        break;
      default:
        break;
    }
    return type;
  }

  /**
   * change area display 2
   *
   * @param areaId area's id
   * @param timetableDetail timetableDetail object
   */
  public changeAreaDisplay2(areaId: Number, timetableDetail: TimetableDetail): void {
    const area = this.areasDisplay2.find(area => area.id == +areaId);
    if (area) {
      timetableDetail.areaDisplay2 = area;
      timetableDetail.areaDisplay2Id = area.id;
      // case 1: area display1 exists
      if (timetableDetail?.areaDisplay1) {
        if (timetableDetail.areaDisplay2.checkTypeTextArea()) {
          timetableDetail.hasText = true;
          timetableDetail.text = timetableDetail.text ?? '';
        }
        // case 2: area display1 not exists
      } else {
        // case 1: area is TextArea
        if (area.checkTypeTextArea()) {
          timetableDetail.hasText = true;
          timetableDetail.text = timetableDetail.text ?? '';
          _.set(timetableDetail, 'media', undefined);
          // case 2: area is PictureArea
        } else {
          timetableDetail.hasText = false;
          timetableDetail.text = undefined;
          _.set(timetableDetail, 'media', undefined);
        }
      }
      // clear data
    } else {
      timetableDetail.areaDisplay2 = undefined;
      timetableDetail.areaDisplay2Id = undefined;
      if (!timetableDetail?.areaDisplay1) {
        _.set(timetableDetail, 'media', undefined);
        timetableDetail.hasText = false;
        timetableDetail.text = undefined;
      }
    }
    this.isChangedData = true;
    this.redrawTimetableDetail();
    // check active area
    this.checkActiveArea();
  }

  /**
   * change url area display 2
   *
   * @param areaId area's id
   * @param timetableDetail timetableUrlDetail object
   */
  public changeUrlAreaDisplay2(areaId: Number, timetableDetail: TimetableDetailURLArea): void {
    const area = this.urlAreasDisplay2.find(area => area.id == +areaId);
    if (area) {
      timetableDetail.areaDisplay2 = area;
      timetableDetail.areaDisplay2Id = area.id;
    } else if (areaId == -1) {
      timetableDetail.areaDisplay2 = null;
      timetableDetail.areaDisplay2Id = null;
    }
    this.isChangedData = true;
    // check active area
    this.checkActiveUrlArea();
  }

  /**
   * check active area
   */
  private checkActiveArea(): void {
    const areas = this.timetableSelected?.timetableDetails?.map(timetableDetail => timetableDetail?.areaDisplay2);
    this.areasDisplay2?.forEach(area => {
      area.isActive = areas?.findIndex(areaData => areaData?.id == area?.id) == -1;
    });
    this.isDisabledButtonAdd = this.areasDisplay2?.every(area => !area.isActive);
  }

  /**
   * checkActiveUrlArea
   */
  private checkActiveUrlArea(): void {
    const areas = this.timetableSelected?.timetableUrlDetails?.map(timetableDetail => timetableDetail?.areaDisplay2);
    this.urlAreasDisplay2?.forEach(area => {
      area.isActive = areas?.findIndex(areaData => areaData?.id == area?.id) == -1;
    });

    const urlAreaIdDisplay1 = this.urlAreasDisplay1?.map(area => area.id);
    const listCheckDisable = this.urlAreasDisplay2?.filter(area => !urlAreaIdDisplay1?.includes(area.id));
    this.isDisabledButtonAddUrl = listCheckDisable?.every(area => !area.isActive);
  }

  /**
   * Add row for display 2
   * @param isURl
   */
  public addRow(isUrl?: boolean): void {
    if (!isUrl) {
      this.timetableSelected?.timetableDetails?.push(new TimetableDetail(this.timetableSelected.id));
    } else {
      this.timetableSelected?.timetableUrlDetails?.push(new TimetableDetailURLArea(this.timetableSelected.id));
    }
  }

  /**
   * save data tab schedule after leave
   */
  private saveDataTabScheduleAfterLeave(): void {
    if (this.isShowFreeArea) {
      this.isPlay = false;
      this.saveTimetableDetail(false);
    } else {
      this.saveTimetable();
    }
  }

  /**
   * show icon arrow sort filter
   */
  private sortFilter(): void {
    if (this.isShowFreeArea || this.timetableSelected?.isEdit) {
      return;
    }
    this.isSortFilter = !this.isSortFilter;
    this.sortFilterObject.isSortFilter = this.isSortFilter;
    this.saveSortFilterStateAction();
    if (!this.isSortFilter) {
      this.resetSortFilter();
    }
  }

  /**
   * show pop up sort filter
   * @param column name column
   * @param event
   */
  public showPopupSortFilter(column: string, event): void {
    if (this.isShowFreeArea || this.timetableSelected?.isEdit) {
      return;
    }
    event.stopPropagation();
    this.isShowPopUpSortFilter = !this.isShowPopUpSortFilter;
    // if is show
    if (this.isShowPopUpSortFilter) {
      this.columnSortFiltering = column;
      this.sortFilterObject.columnSortFiltering = this.columnSortFiltering;
      this.fetchFilterData(column);
    }
    this.saveSortFilterStateAction();
  }
  /**
   * sort basic
   * @param property property sorted
   * @param type type sort
   */
  public sortProperty(property: string, type: string): void {
    this.listSorted = [[property], [type]];
    this.timetablesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
    this.isShowPopUpSortFilter = false;
    // store data sort filter
    this.sortFilterObject.listSorted = this.listSorted;
    // remove all sort of all column
    this.resetColumnsSort();
    // set columns is sorting
    let indexColumnSort = this.headerColumns.findIndex(data => data.property === property);
    this.headerColumns[indexColumnSort].isSortBy = type;
    this.headerColumns[indexColumnSort][Constant.IS_CHOSEN] = true;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.saveSortFilterStateAction();
  }

  /**
   * check select all option
   */
  public checkAllOptionFilter(): void {
    this.isCheckAllOptionFilter = !this.isCheckAllOptionFilter;
    this.sortFilterObject.isCheckAllOptionFilter = this.isCheckAllOptionFilter;
    this.listFilterDisplayOrigin.forEach(option => {
      option.isChecked = this.isCheckAllOptionFilter;
    });
    this.listFilterDisplay = [...this.listFilterDisplayOrigin];
    this.saveSortFilterStateAction();
  }

  /**
   * get array not duplicate value
   * @param array
   * @param property
   */
  public getUniqueOption = (array, property): any => {
    return _.uniqBy(array, property);
  };

  /**
   * fetch data filter to pop up
   * @param property column show popup
   */
  public fetchFilterData(property: string): void {
    // let listDataTableGetOptionFilter = this.dataTablesDisplay.filter(
    //   data => !data[this.LAST_FILTER] || data[this.LAST_FILTER] === property
    // );
    let isFiltered = false;
    let listFilterTmp = _.cloneDeep(this.timetables);
    let listFilterInProperty = [];
    for (let filterTmp in this.listCurrentFilter) {
      if (filterTmp == property) {
        isFiltered = true;
        listFilterInProperty = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
        continue;
      }

      let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      // filter = filter.map(e=> e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    let listDataTableGetOptionFilter = _.cloneDeep(listFilterTmp);
    let listDataTableOptionFilter: any[] = this.getUniqueOption(listDataTableGetOptionFilter, property);
    // if not last column filter
    if (this.lastColumnFilter !== property) {
      this.listFilterDisplay = [];
      for (let i = 0; i < listDataTableOptionFilter.length; i++) {
        //get list option filter
        this.listFilterDisplay[i] = {
          isChecked: listFilterInProperty.length == 0 ? true : listFilterInProperty.includes(listDataTableOptionFilter[i][property]),
          // isChecked: !listDataTableOptionFilter[i][this.IS_FILTER],
          name: listDataTableOptionFilter[i][property]
        };
      }
      // if is last column filter
    } else {
      this.listFilterDisplay = this.listCurrentFilter[property];
      // update if add
      listDataTableOptionFilter.forEach(dataTable => {
        if (!this.listFilterDisplay.find(optionFilter => optionFilter.name === dataTable[property])) {
          this.listFilterDisplay.push({
            isChecked: !isFiltered,
            name: dataTable[property]
          });
        }
      });
      // remove old value
      this.listFilterDisplay?.forEach(option => {
        if (option.isChecked && !listDataTableOptionFilter.find(dataTable => dataTable[property] === option.name)) {
          this.listFilterDisplay = this.listFilterDisplay.filter(data => data.name !== option.name);
        }
      });
    }
    this.listFilterDisplay = _.sortBy(this.listFilterDisplay, ['name']);
    // get list memorize checked
    this.listFilterDisplayOrigin = _.cloneDeep(this.listFilterDisplay);
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * change checked
   * @param index index of option filter
   */
  public checkOptionFilter(index: number): void {
    this.listFilterDisplayOrigin[index].isChecked = !this.listFilterDisplayOrigin[index].isChecked;
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * set lastFilter for Timetable to filter or un filter
   * @param currentFilter list option filter property
   * @param property column filtering
   */
  public getCurrentFilter(currentFilter: OptionFilter[], property: string): void {
    for (let i = 0; i < currentFilter.length; i++) {
      if (!currentFilter[i].isChecked) {
        let arr = this.timetables?.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          element[this.IS_FILTER] = true;
          if (!element[this.LAST_FILTER]) {
            element[this.LAST_FILTER] = property;
          }
        });
      } else {
        let arr = this.timetables?.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          if (element[this.LAST_FILTER] == property) {
            element[this.IS_FILTER] = false;
            element[this.LAST_FILTER] = undefined;
          }
        });
      }
    }
  }

  /**
   * filter timetable
   *
   * @param property
   * @param isFilterFirstTime
   */
  public filterTimetable(property: string, isFilterFirstTime: boolean): void {
    // do not filter all
    if (this.listFilterDisplayOrigin.every(data => !data.isChecked)) {
      this.isShowPopUpSortFilter = false;
      this.saveSortFilterStateAction();
      return;
    }
    this.isFilter = true;
    this.sortFilterObject.isFilter = this.isFilter;
    this.headerColumns.find(data => data.property === property).isFilterBy = property;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.lastColumnFilter = property;
    let columnsFiltered = Object.keys(this.listCurrentFilter);
    // if is not clear last column filter
    if (!this.isClear) {
      this.listFilterDisplay = [...this.listFilterDisplayOrigin];
    }
    // if list option filter checked all or clear last column filter
    if (this.listFilterDisplay.findIndex(data => !data.isChecked) === -1) {
      delete this.listCurrentFilter[property];
      this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
      columnsFiltered = Object.keys(this.listCurrentFilter);
      this.lastColumnFilter = columnsFiltered[columnsFiltered.length - 1];
      this.isClear = false;
      this.isFilter = false;
      this.sortFilterObject.isFilter = this.isFilter;
      this.sortFilterObject.isClear = this.isClear;
      this.headerColumns.find(data => data.property === property).isFilterBy = Constant.EMPTY;
      this.sortFilterObject.headerColumns = this.headerColumns;
      // if filter a column was filtered
    } else {
      if (this.listCurrentFilter[property] && this.lastColumnFilter !== columnsFiltered[columnsFiltered.length - 1]) {
        let nextProperTyFilter = columnsFiltered[columnsFiltered.indexOf(property) + 1];
        this.timetables?.forEach(element => {
          if (element[this.LAST_FILTER] === property) {
            element[this.LAST_FILTER] = nextProperTyFilter;
          }
        });
        let listTimetableGetOptionFilter = this.timetables?.filter(
          data => data[this.LAST_FILTER] === nextProperTyFilter || !data[this.LAST_FILTER]
        );
        let listTimetableOptionFilter = this.getUniqueOption(listTimetableGetOptionFilter, nextProperTyFilter);
        let listOptionFilterNew: Array<OptionFilter> = new Array<OptionFilter>();
        for (let i = 0; i < listTimetableOptionFilter.length; i++) {
          listOptionFilterNew[i] = {
            isChecked: !listTimetableOptionFilter[i].lastFilter,
            name: listTimetableOptionFilter[i][nextProperTyFilter]
          };
        }
        this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
        delete this.listCurrentFilter[property];
        this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
      }
      // set list option filter property
      this.listCurrentFilter[property] = this.listFilterDisplay;
      this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
    }
    this.getCurrentFilter(this.listFilterDisplay, property);
    // get list Timetable show up on screen
    // this.timetables?.filter(data => data[this.LAST_FILTER]).map(data => (data[Constant.IS_SELECTED] = false));
    let listFilterTmp = _.cloneDeep(this.timetables);
    for (let filterTmp in this.listCurrentFilter) {
      let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      // filter = filter.map(e=> e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    this.timetablesDisplay = listFilterTmp;
    this.timetablesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    this.saveSortFilterStateAction();
    this.controlCheckBoxCheckAllFilter();
    if (!isFilterFirstTime) {
      this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
    }
  }

  /**
   * Filter timetable when open timetable editor first time
   *
   * @returns
   */
  private filterTimetableFirstTime(): void {
    if (!this.listCurrentFilter) {
      this.selectTimetable(this.timetables[Constant.FIRST_ELEMENT_INDEX], null);
      return;
    }
    let listCurrentFilter = _.cloneDeep(this.listCurrentFilter);
    let columnSortFilters = Object.keys(listCurrentFilter);
    columnSortFilters.forEach(columnSortFilter => {
      this.listFilterDisplay = _.cloneDeep(listCurrentFilter)[columnSortFilter];
      this.listFilterDisplayOrigin = this.listFilterDisplay;
      this.filterTimetable(columnSortFilter, true);
    });
  }

  /**
   * Save sort filter state action
   */
  private saveSortFilterStateAction(): void {
    this.store.dispatch(
      new SaveSortFilterStateAction({
        sortFilterTimetable: this.sortFilterObject
      })
    );
  }

  /**
   * clear filter
   * @param property name of column clear filter
   */
  public clearFilter(property: string): void {
    this.headerColumns.find(data => data.property == property).isFilterBy = Constant.EMPTY;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.isFilter = false;
    this.sortFilterObject.isFilter = this.isFilter;
    // set all option in list is true
    this.listCurrentFilter[property]?.forEach(element => {
      element.isChecked = true;
    });
    this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
    // if is last column filter
    if (property === this.lastColumnFilter) {
      this.isClear = true;
      this.sortFilterObject.isClear = this.isClear;
      this.listFilterDisplayOrigin.forEach(data => (data.isChecked = true));
      this.filterTimetable(property, false);
      // if is not last column filter
    } else {
      let keys = Object.keys(this.listCurrentFilter);
      let nextProperTyFilter = keys[keys.indexOf(property) + 1];
      // set lastFilter is next column filter
      this.timetables?.forEach(element => {
        if (element[this.LAST_FILTER] === property) {
          element[this.IS_FILTER] = false;
          element[this.LAST_FILTER] = nextProperTyFilter;
        }
      });
      // get new list option filter for next property filter in listCurrentFilter
      let listTimetableGetOptionFilter = this.timetables?.filter(
        data => data[this.LAST_FILTER] === nextProperTyFilter || !data[this.LAST_FILTER]
      );
      let listTimetableOptionFilter = this.getUniqueOption(listTimetableGetOptionFilter, nextProperTyFilter);
      let listOptionFilterNew: Array<OptionFilter> = new Array<OptionFilter>();
      for (let i = 0; i < listTimetableOptionFilter.length; i++) {
        listOptionFilterNew[i] = {
          isChecked: !listTimetableOptionFilter[i].lastFilter,
          name: listTimetableOptionFilter[i][nextProperTyFilter]
        };
      }
      listOptionFilterNew.forEach(element => {
        for (let j = 0; j < this.listCurrentFilter[nextProperTyFilter]?.length; j++) {
          if (element.name === this.listCurrentFilter[nextProperTyFilter][j].name) {
            element.isChecked = this.listCurrentFilter[nextProperTyFilter][j].isChecked;
          }
        }
      });
      // set new list option filter for next property filter in listCurrentFilter
      this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
      this.isShowPopUpSortFilter = false;
      delete this.listCurrentFilter[property];
      this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
      this.saveSortFilterStateAction();
      this.controlCheckBoxCheckAllFilter();
      this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
    }
  }

  /**
   * control checkBox check all filter when uncheck and checked
   */
  private controlCheckBoxCheckAllFilter(): void {
    this.isCheckAllOptionFilter = this.listFilterDisplayOrigin.every(filter => filter.isChecked);
    this.sortFilterObject.isCheckAllOptionFilter = this.isCheckAllOptionFilter;
  }

  /**
   * show custom sort dialog and sort
   */
  public showCustomSort(): void {
    this.isShowPopUpSortFilter = false;
    this.saveSortFilterStateAction();
    // replace template with display 1, display 2
    let propertySorts = _.cloneDeep(this.headerColumns);
    propertySorts[propertySorts.length - 2].headerName = this.translateService.instant('timetable-editor.display-1');
    if (this.isDisplay2) {
      propertySorts[propertySorts.length - 1].headerName = this.translateService.instant('timetable-editor.display-2');
    } else {
      propertySorts.pop();
    }
    // show dialog custom sort
    this.dialogService.showDialog(DialogCustomSortComponent, { data: { list: [this.listSorted, propertySorts] } }, result => {
      if (result) {
        this.listSorted = result;
        for (let i = 0; i < this.headerColumns.length; i++) {
          let index = this.listSorted[0].findIndex(column => column === this.headerColumns[i]?.property);
          if (index === -1) {
            this.headerColumns[i].isSortBy = Constant.EMPTY;
          } else {
            this.headerColumns[i].isSortBy = this.listSorted[1][index];
          }
        }
        this.sortFilterObject.listSorted = this.listSorted;
        this.sortFilterObject.headerColumns = this.headerColumns;
        this.saveSortFilterStateAction();
        // sort
        this.timetablesDisplay = this.timetables?.filter(data => !data[this.LAST_FILTER]);
        this.timetablesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
        this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
        this.updateColumnCustomSort(this.headerColumns, propertySorts);
      }
    });
  }

  /**
   * sort multiple
   * @param dataSort list properties and sort type sorted
   */
  public dynamicSortMultiple(dataSort: any): any {
    return function(object1, object2) {
      let output = 0,
        i = 0;
      while (output == 0 && i < dataSort[0]?.length) {
        let value1 = object1[dataSort[0][i]] ?? Constant.EMPTY; // dataSort[0] is list column sorted
        let value2 = object2[dataSort[0][i]] ?? Constant.EMPTY;
        if (dataSort[1][i] === SortTypeEnum.DESC) {
          // dataSort[1] is list sort type corresponding to column
          output = value1 > value2 ? -1 : value1 < value2 ? 1 : 0;
        } else {
          output = value1 > value2 ? 1 : value1 < value2 ? -1 : 0;
        }
        i++;
      }
      return output;
    };
  }

  /**
   * reset sort filter action
   */
  private resetSortFilter(): void {
    this.getAllTimetables();
    this.listSorted = [];
    this.listCurrentFilter = {};
    this.lastColumnFilter = undefined;
    this.isFilter = undefined;
    this.columnSortFiltering = undefined;
    this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);
    // store data sort filter
    this.sortFilterObject.isFilter = this.isFilter;
    this.sortFilterObject.listSorted = this.listSorted;
    this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
    this.sortFilterObject.columnSortFiltering = this.columnSortFiltering;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.saveSortFilterStateAction();
    this.multiLanguageHeader();
  }

  /**
   * set up for disable option in custom sort
   *
   * @param columnsBeforeSort
   * @param columnsAfterSort
   */
  private updateColumnCustomSort(columnsBeforeSort: any, columnsAfterSort: any): void {
    columnsAfterSort?.forEach((columnAfter, index) => {
      columnsBeforeSort[index][Constant.IS_CHOSEN] = columnAfter[Constant.IS_CHOSEN];
    });
  }

  /**
   * reset column sort disable in list
   */
  private resetColumnsSort(): void {
    this.headerColumns.forEach(column => {
      column[Constant.IS_CHOSEN] = false;
      column.isSortBy = Constant.EMPTY;
    });
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.saveSortFilterStateAction();
  }

  /**
   * reference setting
   * @returns
   */
  private referenceSetting(): void {
    // return if choose tab schedule
    if (this.isSchedule) {
      return;
    }
    // check choose device
    if (!this.selectedDeviceCalendar || !this.groupDevices?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-device')
        }
      });
      return;
    }
    // validate exist data calendar for device
    this.timetableContentDayService.checkExistDataForDevices([this.selectedDeviceCalendar.id]).subscribe(data => {
      if (data?.some(item => !item)) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('timetable-editor.no-data-and-continue'),
              button1: this.translateService.instant('timetable-editor.yes'),
              button2: this.translateService.instant('timetable-editor.btn-no')
            }
          },
          result => {
            if (!result) {
              return;
            }
            this.openDialogReferenceSetting();
          }
        );
      } else {
        this.openDialogReferenceSetting();
      }
    });
  }

  /**
   * open dialog reference setting
   */
  private openDialogReferenceSetting(): void {
    // open popup
    let deviceCalendars = [];
    this.groupDevices.forEach(group => {
      let devices = group.deviceCalendars.filter(device => +device.id !== this.selectedDeviceCalendar.id);
      if (devices.length > 0) {
        deviceCalendars = _.concat(deviceCalendars, devices);
      }
    });
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogReferenceSettingComponent,
      { data: { deviceCalendar: this.selectedDeviceCalendar, deviceCalendars: deviceCalendars } },
      result => {
        this.isChangedData = false;
      }
    );
  }

  /**
   * export calendar
   */
  private exportCalendar(): void {
    // return if choose tab schedule
    if (this.isSchedule) {
      return;
    }
    if (!this.selectedDeviceCalendar || !this.groupDevices?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-device')
        }
      });
      return;
    }
    // check exist data of device
    this.timetableContentDayService.checkExistDataForDevices([this.selectedDeviceCalendar.id]).subscribe(
      data => {
        if (!data[0]) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('timetable-editor.no-data-calendar')
            }
          });
          return;
        }
        // open popup
        this.isChangedData = true;
        this.dialogService.showDialog(
          DialogExportCalendarComponent,
          {
            data: {
              deviceId: this.selectedDeviceCalendar.id,
              deviceName: this.selectedDeviceCalendar.name,
              screen: ScreenNameEnum.TIME_TABLE
            }
          },
          result => {
            this.isChangedData = false;
          }
        );
      },
      error => this.handleErrorSaveTimetable(error)
    );
  }

  /**
   * setting update timing
   */
  public updateTiming(): void {
    if (this.isPlay || this.timetableSelected?.isEdit || !this.isSchedule) {
      return;
    }
    // getTimetableUpdateTiming && getTimetableUpdateTiming
    forkJoin({
      commonTable: this.commonTableService.getValueCommonTableByKey(Constant.KEY_UPDATE_TIMING_TIMETABLE),
      commonTableIsBack: this.commonTableService.getValueCommonTableByKey(Constant.KEY_UPDATE_TIMING_TIMETABLE_IS_BACK)
    }).subscribe(
      data => {
        this.isChangedData = true;
        this.dialogService.showDialog(
          DialogTimetableUpdateTimingComponent,
          {
            data: {
              commonTable: data.commonTable ?? new CommonTable(Constant.KEY_UPDATE_TIMING_TIMETABLE, `${Constant.UPDATE_TIMING_DEFAULT}`),
              commonTableIsBack: data.commonTableIsBack ?? new CommonTable(Constant.KEY_UPDATE_TIMING_TIMETABLE_IS_BACK, `false`)
            }
          },
          result => {
            this.isChangedData = false;
          }
        );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * open change date line
   */
  private changeDateLine(): void {
    if (
      this.isPlay ||
      this.timetableSelected?.isEdit ||
      !this.isSchedule ||
      this.isShowFreeArea ||
      this.commonObject.userIdString != Constant.ROOT
    ) {
      return;
    }
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_TIMETABLE_CHANGE_DATE_LINE_TIMETABLE).subscribe(
      data => {
        const commonTable = data ?? new CommonTable(Constant.KEY_TIMETABLE_CHANGE_DATE_LINE_TIMETABLE);
        this.openDialogChangeDateLine(commonTable);
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * open dialog change date line
   * @param commonTable
   */
  private openDialogChangeDateLine(commonTable: CommonTable): void {
    this.dialogService.showDialog(
      DialogChangeDateLineComponent,
      {
        data: { commonTable: commonTable }
      },
      result => {
        if (result) {
          this.timeDateLine = JSON.parse(result);
          this.getInformationSchedule();
        }
      }
    );
  }

  /**
   * change PageSwitchingTiming
   */
  changePageSwitchingTiming(): void {
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_PAGE_SWITCHING_TIMING).subscribe(data => {
      this.dialogService.showDialog(
        DialogPageSwitchingTimingComponent,
        {
          data: { commonTable: data ?? new CommonTable(Constant.KEY_PAGE_SWITCHING_TIMING, `${Constant.TIME_SWITCH_PAGE_DEFAULT}`) }
        },
        result => {}
      );
    });
  }

  /**
   * get information change date line
   */
  private getInformationChangeDateLine(): void {
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_TIMETABLE_CHANGE_DATE_LINE_TIMETABLE).subscribe(
      data => {
        this.timeDateLine = data ? JSON.parse(data.value) : Constant.TIME_DATE_LINE_DEFAULT;
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * open popup confirm when import timetable
   *
   * @param timetablesData
   */
  private openPopupConfirm(timetablesData: Array<Timetable>): void {
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('timetable-editor.duplicate-no-and-suffix'),
          button1: this.translateService.instant('timetable-editor.overwrite-all'),
          button2: this.translateService.instant('timetable-editor.timetable-detail.cancel'),
          button3: this.translateService.instant('timetable-editor.add')
        }
      },
      result => {
        if (result) {
          if (result == true) {
            this.dialogService.showDialog(
              DialogConfirmComponent,
              {
                data: {
                  text: this.translateService.instant('timetable-editor.confirm-overwrite-all'),
                  button1: this.translateService.instant('timetable-editor.yes'),
                  button2: this.translateService.instant('timetable-editor.timetable-detail.cancel')
                }
              },
              result => {
                if (result) {
                  this.overwriteAll(timetablesData);
                } else {
                  this.openPopupConfirm(timetablesData);
                }
              }
            );
          } else if (result == Constant.BUTTON_ADD_IMPORT_TIMETABLE) {
            this.addTimetables(timetablesData);
          }
        }
      }
    );
  }

  /**
   * get data timetables in excel file
   *
   * @param timetablesData
   * @returns
   */
  private getDataTimetablesInExcelFile(timetablesData: Array<Timetable>): any {
    let timetables = [];
    timetablesData.forEach(timetableData => {
      const index = timetables.findIndex(timetable => timetable.no == timetableData.no && timetable.suffix == timetableData.suffix);
      let timetable = Helper.convertDataTimetableImport(timetableData);
      if (index == -1) {
        timetables.push(timetable);
      } else {
        timetables[index] = timetable;
      }
    });
    return timetables;
  }

  /**
   * overwrite all timetable duplicate
   *
   * @param timetablesData
   * @returns
   */
  private overwriteAll(timetablesData: Array<Timetable>): void {
    let timetables = _.cloneDeep(this.timetables);
    let timetablesSave = [];
    let timetablesCMP = [];
    timetables.forEach(timetable => {
      let index = timetablesData.findIndex(data => data.no == timetable.no && data.suffix == timetable.suffix);
      if (index != -1) {
        this.setDataTimetableDuplicate(timetable, timetablesData[index]);
        timetablesCMP.push(timetable);
      }
      timetablesSave.push(timetable);
    });

    let timetablesExcel = timetablesData.filter(data => !timetables.some(tt => tt.no == data.no && tt.suffix == data.suffix));
    // validate duplicate name
    if (!this.validateDuplicateNameWhenImport(timetablesSave.concat(timetablesExcel))) {
      this.handleErrorNameDuplicated();
      return;
    }
    // save data
    this.saveTimetablesImport(this.convertListTimetableBackward(timetablesCMP.concat(timetablesExcel)));
  }

  /**
   * fill data after import timetable
   * @param timetablesCMP
   * @param timetableDuplicate
   * @param isOverwriteSortFilter
   * @returns
   */
  private fillDataAfterImportTimetable(
    timetablesCMP: Array<Timetable>,
    timetableDuplicate: Timetable,
    isOverwriteSortFilter?: boolean
  ): void {
    let index = timetablesCMP.findIndex(timetable => timetable.id === timetableDuplicate.id);
    if (index == -1) {
      index = this.timetables.findIndex(data => data.id === timetableDuplicate.id);
      if (isOverwriteSortFilter && this.timetables.findIndex(data => data.id === timetableDuplicate.id) != -1) {
        return;
      }
      timetablesCMP.push(timetableDuplicate);
      return;
    }
    Helper.setDataTimetableAfterOverwriteAll(timetablesCMP[index], timetableDuplicate);
  }

  /**
   * convert list timetable backward
   * @param timetablesData
   * @returns
   */
  private convertListTimetableBackward(timetablesData: Array<Timetable>): Array<Timetable> {
    return timetablesData.map(timetable => Helper.convertTimetableBackward(timetable));
  }

  /**
   * save timetables when import list timetable
   *
   * @param timetables
   */
  private saveTimetablesImport(timetables: Array<Timetable>): void {
    this.timetableService.saveTimetables(timetables).subscribe(
      data => {
        if (data) {
          let timetablesNew = Helper.convertDataTimetables(data);
          let timetableEnd = null;
          timetablesNew.forEach(timetableNew => {
            this.fillDataAfterImportTimetable(this.timetablesDisplay, timetableNew, true);
            this.fillDataAfterImportTimetable(this.timetables, timetableNew);
            if (this.timetablesDisplay.findIndex(data => data.no == timetableNew.no && data.suffix == timetableNew.suffix) != -1) {
              timetableEnd = timetableNew;
            }
          });

          if (timetableEnd) {
            this.selectTimetable(timetableEnd, null, undefined, true, true);
          }
          this.dataService.sendData([this.IS_DISABLE_BUTTON_SORT, !(this.timetables.length > 0)]);
          this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
        }
      },
      error => {
        this.handleErrorSaveTimetable(error);
      }
    );
  }

  /**
   * set data timetable duplicate
   *
   * @param timetable
   * @param timetableDuplicate
   * @returns
   */
  private setDataTimetableDuplicate(timetable: Timetable, timetableDuplicate: Timetable): Timetable {
    timetable.name = timetableDuplicate.name;
    if (timetableDuplicate.displayTemplate1) {
      if (timetableDuplicate.displayTemplate1.idMainPage) {
        timetable.displayTemplate1 = timetableDuplicate.displayTemplate1;
        timetable.timetableSchedule = timetableDuplicate.timetableSchedule;
      }
    } else if (timetableDuplicate.timetableSchedule) {
      timetable.timetableSchedule = timetableDuplicate.timetableSchedule;
    } else {
      timetable.displayTemplate1 = undefined;
      timetable.timetableSchedule = undefined;
    }
    return timetable;
  }

  /**
   * add timetables not duplicate No. and Suffix
   *
   * @param timetablesData
   * @returns
   */
  private addTimetables(timetablesData: Array<Timetable>): void {
    let timetables = _.cloneDeep(this.timetables);
    let timetablesAdd = timetablesData.filter(
      data => !timetables.some(timetable => timetable.no == data.no && timetable.suffix == data.suffix)
    );
    if (!timetablesAdd.length) {
      return;
    }
    // validate duplicate name
    if (!this.validateDuplicateNameWhenImport(timetables.concat(timetablesAdd))) {
      this.handleErrorNameDuplicated();
      return;
    }
    this.saveTimetablesImport(this.convertListTimetableBackward(timetablesAdd));
  }

  /**
   * validate name of timetable when import
   *
   * @param timetablesData
   * @returns
   */
  private validateDuplicateNameWhenImport(timetablesData: Array<Timetable>): boolean {
    let timetableNames = timetablesData.map(item => item.name).filter((v, i, a) => a.indexOf(v) === i);
    return timetableNames.length == timetablesData.length;
  }

  /**
   * handle error name duplicated
   */
  private handleErrorNameDuplicated(): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant('timetable-editor.duplicate-name')
      }
    });
  }

  /**
   * multi language header
   */
  private multiLanguageHeader(): void {
    this.headerColumns.forEach((element, index) => {
      switch (index) {
        case 0:
          element.headerName = this.translateService.instant('timetable-editor.label');
          break;
        case 1:
          element.headerName = this.translateService.instant('timetable-editor.no');
          break;
        case 2:
          element.headerName = this.translateService.instant('timetable-editor.suffix');
          break;
        case 3:
          element.headerName = this.translateService.instant('timetable-editor.name');
          break;
        case 4:
        case 5:
          element.headerName = this.translateService.instant('timetable-editor.template');
          break;
        default:
          break;
      }
    });
  }

  /**
   * open group
   * @param groupDevice
   */
  public openGroup(groupDevice: GroupDevice): void {
    groupDevice.isExpand = !groupDevice.isExpand;
  }

  /**
   * open dialog switch timing area
   */
  private switchTimingArea(): void {
    if (this.isPlay || this.timetableSelected?.isEdit) {
      return;
    }
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_AREA_SWITCHING_TIMING).subscribe(
      data => {
        this.dialogService.showDialog(
          DialogTimetableSwitchTimingAreaComponent,
          {
            data: { commonTable: data ?? new CommonTable(Constant.KEY_AREA_SWITCHING_TIMING, `${Constant.VALUE_ONE}`) }
          },
          result => {
            if (result == undefined) {
              return;
            }
            if (this.areaSwitchingTiming && result) {
              this.areaSwitchingTiming = result;
              this.drawTimetableService.setAreaSwitchingTiming(this.areaSwitchingTiming);
              return;
            }
            this.areaSwitchingTiming = result;
            let display1 = _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined);
            const areasDisplay1 = Helper.getAllAreaTemplate(display1);
            let display2 = _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined);
            const areasDisplay2 = Helper.getAllAreaTemplate(display2);
            this.reDrawPreview(areasDisplay1, areasDisplay2);
          }
        );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * get information switch timing area
   */
  private getInformationSwitchTimingArea(): void {
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_AREA_SWITCHING_TIMING).subscribe(
      data => {
        this.areaSwitchingTiming = data ? +JSON.parse(data.value) : Constant.TIME_SWITCH_AREA_DEFAULT;
        this.drawTimetableService.setAreaSwitchingTiming(this.areaSwitchingTiming);
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * convertHeader
   * @param header
   * @returns
   */
  public convertHeader(header: string): string {
    if (header.toUpperCase() == Constant.TIME_ITEM || header == Constant.TIME_ITEM_JP) {
      return this.translateService.instant('timetable-editor.time');
    } else {
      return header;
    }
  }

  /**
   * Verify action during preview
   *
   * @returns true: monitor mode and play preview is inactive
   */
  validateActionPreview(): boolean {
    if (this.isPlay) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING, null, null, this.translateService)
        }
      });
      return false;
    }
    return true;
  }

  /**
   * find Area Display 2
   * @param areaIdDisplay1
   * @returns
   */
  findAreaDisplay2(areaIdDisplay1: number): void {
    this.areaDisplay2Active = null;
    const areaIdDisplays1 = this.urlAreasDisplay1?.map(area => area.id);
    this.areaDisplay2Active = areaIdDisplay1
      ? this.urlAreasDisplay2?.filter(area => area.id == areaIdDisplay1)?.map(e => e.id)
      : this.urlAreasDisplay2?.filter(area => !areaIdDisplays1?.includes(area.id))?.map(e => e.id);
  }

  /**
   * reset Area Selected
   *
   */
  resetAreaSelected(): void {
    this.areaSelected.nativeElement.reset();
  }

  /**
   * send Timezone To Backend
   */
  private sendTimezoneToBack(): void {
    let timeZone = this.commonService
      .getCommonObject()
      .setting.timezone.name.split(' ')[0]
      .replace(')', '')
      .replace('(', '');
    this.timetableService.transferTimezoneToBack(timeZone).subscribe();
  }
}

/**
 * class RegexTime
 */
export class RegexTime {
  /**
   * regexTimeMinute1
   */
  regexTimeMinute1: string;
  /**
   * regexTimeMinute2
   */
  regexTimeMinute2: string;
  /**
   * regexTimeSecond
   */
  regexTimeSecond: string;

  constructor(regexTimeMinute1?: string, regexTimeMinute2?: string, regexTimeSecond?: string) {
    this.regexTimeMinute1 = regexTimeMinute1;
    this.regexTimeMinute2 = regexTimeMinute2;
    this.regexTimeSecond = regexTimeSecond;
  }
}
